.bd, .media {
    overflow: hidden;
    zoom: 1
}

h1, h2, h3, h4, li, p {
    font-family: "Optimist Thin", Arial, Helvetica, sans-serif
}

.media {
    margin: 10px
}

.media .img {
    float: left;
    margin-right: 20px
}

.media .img img {
    display: block
}

.media .imgExt {
    float: right;
    margin-left: 10px
}

h1, h2, h3, h4 {
    font-weight: 100;
    color: #004977
}

.footer .copyright, .footer .footer-nav li {
    font-family: Optimist, Arial, Helvetica, sans-serif;
    font-size: 12.5px
}

#capitalone_main_book {
    padding-bottom: 0
}

.row.main-content {
    display: none
}

.header {
    background: #004977;
    height: 80px;
    position: relative
}

.header .logo {
    background: url(../img/capitalone-logo.png) no-repeat;
    height: 50px;
    width: 140px;
    position: absolute;
    margin-top: 15px;
    text-indent: -9999px
}

.footer {
    background: #004977;
    color: #fff;
    margin-bottom: -160px
}

.footer .container .columns {
    margin: 0
}

.footer .footer-nav li.footersubs {
    background-image: none
}

.footer .footer-nav li.footersubs>a {
    padding-left: 0;
    font-weight: 400
}

.footer .footer-nav li a {
    padding-left: 0;
    color: #fff;
    text-transform: none
}

.aside ul li a, .main h3 {
    color: #004977
}

.footer .social-media {
    margin-top: 20px
}

.footer .copyright {
    padding-top: 50px
}

.hero {
    width: 100%;
    background: url(../img/hero-bg.jpg) no-repeat;
    background-size: cover;
    text-align: center;
    padding: 80px 0
}

.hero h2 {
    line-height: 1;
    font-size: 3.1em;
    margin-bottom: 0
}

@media (min-width:48em) {
    .footer .social-media {
        right: 0;
        position: absolute
    }
    .hero h2 {
        font-size: 4.1em
    }
}

.hero h2 .larger {
    font-size: 1.2em;
    margin-top: 0;
    font-weight: 700
}

.main {
    padding: 50px 0
}

.main h3 {
    font-size: 25px;
    letter-spacing: -1px;
    margin-bottom: 20px
}

.main hr {
    margin: 2em 0;
    background-color: #336D92;
    border-color: #336D92/*r leon 10.30.17*/
}

.aside h4 {
    margin-bottom: 0
}

.aside .small {
    font-size: 14px
}

.aside ul {
    margin-top: 0
}

.aside ul li {
    list-style-image: url(../img/icon-link.png);
    line-height: 1.5
}

.aside ul li.no-icon {
    list-style-image: none;
    list-style-type: none
}

.aside ul li.no-icon a {
    color: #018bbb
}

.footer-display {
    display: n;
}
.main .container .row h3 {
    font-size: 25px;
    letter-spacing: -1px;
    margin-bottom: 20px;
}
