.suggestedSearchContainer .media .media, .suggestedSearchContainer .media .bd, .callout-block-left, .callout-block-right, table[class*="callout-block"], .callout-block-left .content-block, .callout-block-right .content-block, table[class*="callout-block"] .content-block, .callout-bg-icon.image-left .content-wrapper, .callout-bg-icon.image-right .content-wrapper {
    overflow: hidden
}

#skip-lobnav, #end-lobnav, #skip-quicklinks, #end-quicklinks, #skip-lobnav:hover, #end-lobnav:hover, #skip-quicklinks:hover, #end-quicklinks:hover, #skip-lobnav:visited, #end-lobnav:visited, #skip-quicklinks:visited, #end-quicklinks:visited {
    position: absolute;
    left: -999em;
    display: block;
    overflow: hidden;
    width: 1px;
    height: 1px;
    background-color: #333;
    color: #fff
}

#skip-lobnav:active, #end-lobnav:active, #skip-quicklinks:active, #end-quicklinks:active, #skip-lobnav:focus, #end-lobnav:focus, #skip-quicklinks:focus, #end-quicklinks:focus {
    left: 0;
    width: auto;
    padding: 5px;
    height: auto;
    position: relative
}

* {
    margin: 0;
    padding: 0;
    vertical-align: baseline
}

fieldset, img, a img {
    border: 0
}

@font-face {
    font-family: "Optimist";
    font-weight: 400;
    src: url("../../fonts/optimist/Optimist_W_Rg.eot@");
    src: url("../../fonts/optimist/Optimist_W_Rg.eot@") format("eot"), url("../../fonts/optimist/Optimist_W_Rg.woff2") format("woff2"), url("../../fonts/optimist/Optimist_W_Rg.woff") format("woff")
}

@font-face {
    font-family: "Optimist Bold";
    font-weight: 500;
    src: url("../../fonts/optimist/Optimist_W_SBd.eot@");
    src: url("../../fonts/optimist/Optimist_W_SBd.eot@") format("eot"), url("../../fonts/optimist/Optimist_W_SBd.woff2") format("woff2"), url("../../fonts/optimist/Optimist_W_SBd.woff") format("woff")
}

@font-face {
    font-family: "Optimist Thin";
    font-weight: normal;
    font-style: normal;
    src: url("../../fonts/optimist/Optimist_W_Lt.eot");
    src: url("../../fonts/optimist/Optimist_W_Lt.eot@") format("embedded-opentype"), url("../../fonts/optimist/Optimist_W_Lt.woff2") format("woff2"), url("../../fonts/optimist/Optimist_W_Lt.woff") format("woff")
}

@font-face {
    font-weight: normal;
    font-style: normal;
    font-family: "icomoon";
    src: url("../../fonts/icomoon.eot@-gv2xqr");
    src: url("../../fonts/icomoon.eot@") format("embedded-opentype"), url("../../fonts/icomoon.woff2@-gv2xqr") format("woff2"), url("../../fonts/icomoon.woff@-gv2xqr") format("woff"), url("../../fonts/icomoon.ttf@-gv2xqr") format("truetype"), url("../../fonts/icomoon.svg@-gv2xqr") format("svg")
}

@font-face {
    font-family: "Streamline";
    font-weight: normal;
    src: url("../../fonts/streamline.eot@");
    src: url("../../fonts/streamline.eot@") format("eot"), url("../../fonts/streamline.woff2") format("woff2"), url("../../fonts/streamline.woff") format("woff"), url("../../fonts/streamline.ttf") format("truetype"), url("../../fonts/streamline.svg") format("svg")
}

.main-content {
    padding-top: 1em
}

.sectioned .main-content {
    margin: 0 10px 1em
}

body {
    background: #fff;
    color: #021829;
    font: 100%/1.4 "Optimist", Arial, Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%
}

pre {
    padding: 0.625em;
    font-size: 0.9375em;
    white-space: pre-wrap
}

.banner-multi {
    overflow: hidden
}

.banner-multi a {
    display: block
}

.banner-multi img {
    float: left;
    display: block
}

.banner img {
    width: 100%;
    height: auto;
    vertical-align: bottom
}

.ten .banner img, .six .banner img {
    margin-bottom: 1em
}

img.imageLeft {
    float: left;
    margin-right: 0.625em
}

img.imageRight {
    float: right;
    margin-left: 0.625em
}

.loading-image {
    display: block;
    margin: 6.25em auto 18.75em
}

.center {
    text-align: center
}

.align-right {
    text-align: right
}

#cookie_msg, .cookie-message {
    display: none;
    padding: 3em;
    text-align: center;
    background-color: #d22f2f;
    color: #fff;
    z-index: 2000;
    width: 21.875em;
    position: fixed;
    top: 2em;
    left: 50%;
    margin-left: -13.9375em;
    border-radius: 10px;
    box-shadow: 0 0 5px #000
}

#waitingDiv {
    position: absolute;
    z-index: 25;
    background-color: #fff;
    text-align: center;
    top: 90px;
    width: 100% !important
}

#waitingDiv img {
    padding-top: 75px
}

#waitingDiv p {
    color: #767676;
    text-align: center;
    font-size: 1em
}

@media (min-width: 48em) {
    #waitingDiv {
        top: 25px;
        width: 100% !important
    }
}

.mobile-only {
    display: inherit
}

.rates .mobile-only {
    display: inline
}

.non-mobile, .rates .non-mobile {
    display: none
}

.media-row {
    margin: 0.625em 0
}

.media-row, .media-row .summary, .media-row .bd {
    overflow: hidden;
    _overflow: visible;
    zoom: 1
}

.media-row .img, .media-row .left-box {
    float: left;
    margin-right: 0.625em
}

.media-row .img img, .media-row .left-box img {
    display: block
}

.media-row .imgExt {
    float: right;
    margin-left: 0.625em
}

.clearBoth, table.glance .ratings-glance-holder {
    clear: both
}

.clear {
    display: block;
    visibility: hidden;
    clear: both;
    height: 0;
    content: "";
    width: 0;
    overflow: hidden
}

.row, .clearfix {
    zoom: 1
}

.row:after, .clearfix:after {
    clear: both
}

.row:before, .row:after, .clearfix:before, .clearfix:after {
    display: block;
    visibility: hidden;
    clear: both;
    height: 0;
    content: " ";
    width: 0;
    overflow: hidden
}

.vh {
    position: absolute !important;
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0 !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden
}

.vh.skip-block:focus, .vh.skip-block:active {
    display: block;
    position: relative !important;
    clip: auto;
    padding: 0 !important;
    height: auto !important;
    width: auto !important
}

a.vh {
    color: #fff
}

@media (min-width: 48em) {
    html {
        height: 100%
    }
    body {
        background-image: none;
        height: 100%
    }
    .wlp-bighorn-book {
        padding-bottom: 180px
    }
    .component {
        margin-bottom: 1em
    }
    .media img {
        width: 100%;
        height: auto
    }
    .mobile-only, .rates .mobile-only {
        display: none
    }
    .non-mobile {
        display: inherit
    }
    .rates .non-mobile {
        display: inline
    }
}

.freeFormLeft {
    float: none !important
}

.freeFormHref {
    float: right;
    clear: both
}

#socialMediaBar .fb_iframe_widget {
    vertical-align: super
}

h1, h2, h3, h4, h5, h6 {
    font-family: "Optimist Thin", Arial, Helvetica, sans-serif;
}

h1 sup, h2 sup, h3 sup, h4 sup, h5 sup, h6 sup {
    font-size: 0.5em;
    font-family: Arial, Helvetica, sans-serif !important;
    vertical-align: super
}

.thin h1, .thin h2, .thin h3 {
    font-family: "Optimist Thin", Arial, Helvetica;
    font-weight: 100;
    line-height: 1.1em;
    letter-spacing: -0.025em
}

h1 {
    color: #004977;
    margin-bottom: 0;
    font-size: 1.125em;
    line-height: 1.4;
    letter-spacing: -0.03em
}

@media (min-width: 48em) {
    h1 {
        font-size: 1.25em
    }
    h1#page-heading {
        float: left;
        margin-bottom: 0.3125em;
        max-width: 20.9em;
        line-height: 1
    }
}

@media (min-width: 60em) {
    h1 {
        font-size: 1.5em
    }
    h1#page-heading {
        max-width: 22.5em
    }
}

h1.heading-large, h1 .heading-large {
    font-size: 175%
}

.thin h1 {
    font-size: 1.75em
}

h2 {
    color: #004977;
    line-height: 1.3;
    font-size: 1.0625em
}

@media (min-width: 60em) {
    h2 {
        font-size: 1.25em
    }
}

h2.heading-large, h2 .heading-large {
    font-size: 140%;
    line-height: 1.4
}

.thin h2 {
    font-size: 1.5em
}

h3 {
    color: #2e3d4e;
    line-height: 1.2;
    font-size: 1em
}

@media (min-width: 60em) {
    h3 {
        font-size: 1.125em
    }
}

h3.heading-large, h3 .heading-large {
    font-size: 120%;
    line-height: 1.4
}

.thin h3 {
    font-size: 1.25em
}

h4 {
    color: #021829;
    line-height: 1.15;
    font-size: 1em;
    font-weight: bold
}

h5 {
    color: #2e3d4e;
    font-size: 1em;
    font-weight: normal;
    line-height: 1.15
}

h6 {
    color: #021829;
    font-size: 1em;
    line-height: 1
}

.hero h2, .hero h3 {
    margin-bottom: 0.25em;
    line-height: 1.1em
}

.hero h1#page-heading, .hero h2 {
    font-weight: 500;
    font-size: 1.625em;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

@media (min-width: 48em) {
    .hero h1#page-heading, .hero h2 {
        font-size: 2em
    }
}

@media (min-width: 60em) {
    .hero h1#page-heading, .hero h2 {
        font-size: 4.1em
    }
}

.hero h3 {
    margin: 0.5em 0 0;
    font-size: 1.25em
}

@media (min-width: 48em) {
    .hero h3 {
        font-size: 1.75em
    }
}

@media (min-width: 60em) {
    .hero h3 {
        font-size: 2.0625em
    }
}

p {
    margin: 0 0 1em
}

@media (min-width: 48em) {
    p {
        font-size: 0.8125em
    }
}

@media (min-width: 60em) {
    p {
        font-size: 1em
    }
}

p img {
    margin: 0
}

.hero p {
    font-size: 1.125em
}

em {
    font-style: italic
}

strong {
    font-weight: bold
}

small {
    font-size: 80%
}

blockquote {
    border-left: 2px solid #ceccd0;
    font-size: 1em;
    padding-left: 1em
}

hr {
    clear: both;
    margin: 1em 0;
    height: 1px;
    border: 0;
    background-color: #dad8db;
    color: #dad8db
}

sub {
    vertical-align: text-bottom;
    font-size: 0.5625em
}

sup {
    vertical-align: super;
    font-size: 0.5625em;
    font-family: Arial, Helvetica, sans-serif !important
}

.highlight {
    color: #d12e32
}

.highlight-blue {
    color: #004977
}

.highlight-yellow {
    color: #f6e533
}

.underlined, h2 .underlined {
    border-bottom: 2px solid #004977;
    display: block;
    margin-bottom: 1em
}

.reverse-highlight {
    color: #fff
}

abbr, acronym {
    border-bottom: 1px dashed #ccc;
    cursor: help
}

.rate-display {
    display: block;
    font-size: 2.8125em;
    font-weight: bold;
    line-height: 1;
    position: relative
}

.rate-display .percent-symbol {
    font-size: 50%;
    font-weight: bold;
    margin-left: -5px;
    position: absolute;
    top: 3px
}

.rate-display .apr-text {
    font-size: 30%;
    font-weight: bold;
    margin-left: -5px
}

.currency-display {
    display: block;
    font-size: 2.8125em;
    font-weight: bold;
    line-height: 1;
    position: relative;
    padding-left: 15px
}

.currency-display .dollar-symbol {
    font-size: 50%;
    font-weight: bold;
    margin-left: -15px;
    position: absolute;
    top: 3px
}

@media (min-width: 48em) {
    .prod-hype {
        text-align: right
    }
    .prod-hype .stars {
        float: right;
        clear: both;
        text-align: left
    }
    .prod-hype .ratings-stars a {
        display: block;
        clear: both
    }
}

.prod-hype h2 {
    color: #004977;
    font-size: 2em
}

.prod-hype ul {
    font-family: "Optimist Bold", Arial, Helvetica, sans-serif;
    font-size: 1.25em;
    list-style: none
}

.prod-hype ul li {
    margin: 15px 0;
    line-height: 1
}

*:focus {
    outline-width: 1px;
    outline-style: dotted
}

button::-moz-focus-inner {
    border: 0
}

a:link, a:visited {
    color: #037fb0;
    text-decoration: none
}

p a:link, p a:visited {
    line-height: inherit
}

a:hover, a:focus {
    color: #336c94
}

a:focus {
    text-decoration: underline
}

a:focus img {
    outline: 1px dotted #000
}

a.tooltip {
    border-bottom: 1px dashed #bbb;
    color: #444
}

a.tooltip:after {
    font-family: "Streamline";
    font-weight: normal;
    font-style: normal;
    text-decoration: inherit;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -moz-osx-font-smoothing: auto;
    -webkit-font-smoothing: auto;
    content: "\e335";
    font-size: 13px;
    padding-left: 5px;
    vertical-align: -1px;
    color: #037fb0
}

a.tooltip:focus {
    text-decoration: none
}

a.no-tt-icon {
    border-bottom: 0;
    color: #037fb0
}

a.no-tt-icon:after {
    content: "";
    padding-left: 0
}

.c1-button {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: block;
    position: relative;
    cursor: pointer;
    background: #128020;
    border: 0;
    border-radius: 3px;
    padding: 0.75em 1em;
    width: 100%;
    color: #fff !important;
    text-align: center;
    text-decoration: none;
    text-transform: capitalize;
    text-shadow: none;
    font-size: 1em;
    font-family: "Optimist", Arial, Helvetica, sans-serif
}

@media (min-width: 48em) {
    .c1-button {
        display: inline-block;
        margin: 0 0 1em;
        width: auto
    }
}

.c1-button::-moz-focus-inner {
    border: 0
}

.c1-button:hover, .c1-button:focus {
    background: #004d00;
    text-decoration: none
}

.c1-button:focus {
    outline: 1px dotted #888
}

.c1-button:disabled {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    filter: alpha(opacity=50);
    opacity: 0.5;
    cursor: no-drop
}

.c1-button span {
    display: inline;
    width: auto;
    height: auto;
    line-height: 1;
    vertical-align: baseline;
    background-image: none;
    background-position: 0 0;
    background-repeat: repeat;
    color: #fff;
    position: absolute;
    top: 50%;
    margin: -8px 0 0
}

.c1-button span:before {
    font-family: "icomoon";
    font-weight: normal;
    font-style: normal;
    text-decoration: inherit;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #fff
}

@media (max-width: 47.9375em) {
    .c1-button {
        margin-bottom: 1em
    }
}

button.blue, a.blue, input.blue {
    background: #0d74af
}

button.blue:hover, button.blue:focus, a.blue:hover, a.blue:focus, input.blue:hover, input.blue:focus {
    background: #063550
}

button.gray, a.gray, input.gray {
    background: #c3c0c2
}

button.gray:hover, button.gray:focus, a.gray:hover, a.gray:focus, input.gray:hover, input.gray:focus {
    background: #918c8f
}

button.red, a.red, input.red {
    background: #d03027
}

button.red:hover, button.red:focus, a.red:hover, a.red:focus, input.red:hover, input.red:focus {
    background: #7a1c17
}

button.ghost, a.ghost, input.ghost {
    background: #fff
}

button.ghost:hover, button.ghost:focus, a.ghost:hover, a.ghost:focus, input.ghost:hover, input.ghost:focus {
    background: #ccc
}

button.ghost, a.ghost, input.ghost {
    box-shadow: 0 0 0 2px #0d74af inset;
    color: #0d74af !important
}

button.ghost:hover, button.ghost:focus, a.ghost:hover, a.ghost:focus, input.ghost:hover, input.ghost:focus {
    background: #fff !important;
    box-shadow: 0 0 0 2px #063550 inset;
    color: #063550 !important
}

button.small, a.small, input.small {
    padding: 8px;
    font-size: 0.875em
}

button.xsmall, a.xsmall, input.xsmall {
    padding: 4px 5px;
    font-size: 0.75em
}

button.icon-right, button.right-icon, a.icon-right, a.right-icon, input.icon-right, input.right-icon {
    padding-right: 30px
}

button.icon-right span, button.right-icon span, a.icon-right span, a.right-icon span, input.icon-right span, input.right-icon span {
    right: 10px
}

button.left-icon, button.icon-left, a.left-icon, a.icon-left, input.left-icon, input.icon-left {
    padding-left: 30px
}

button.left-icon span, button.icon-left span, a.left-icon span, a.icon-left span, input.left-icon span, input.icon-left span {
    left: 10px
}

.reversed button.ghost, .reversed a.ghost, .reversed input.ghost {
    box-shadow: 0 0 0 2px #fff inset;
    color: #fff !important;
    background: transparent
}

.reversed button.ghost:hover, .reversed button.ghost:focus, .reversed a.ghost:hover, .reversed a.ghost:focus, .reversed input.ghost:hover, .reversed input.ghost:focus {
    background-color: rgba(0, 0, 0, 0.15) !important
}

button.small span {
    margin: -7px 0 0
}

@media (min-width: 48em) {
    .c1-button+.c1-button {
        margin-left: 1em
    }
}

@media only screen and (max-width: 47.9375em) {
    .c1-button {
        margin-bottom: 1em
    }
}

#utility-links {
    display: none;
    float: right;
    margin: 5px 0 0;
    font-size: 0.75em;
    list-style-type: none;
    line-height: 14px
}

@media (min-width: 48em) {
    #utility-links {
        display: block
    }
}

#utility-links a {
    padding: 1px 0 0 16px;
    background-image: url(../../img/icon/utility-links.png);
    background-repeat: no-repeat;
    background-scroll: scroll;
    background-position: 0 0;
    background-color: transparent;
    color: #767676
}

#utility-links a:hover, #utility-links a:focus {
    color: #246fc8
}

#utility-links li {
    float: left;
    margin: 0;
    padding: 0 8px;
    border-right: 1px solid #e5e5e5;
    background-image: none
}

#utility-links li.last {
    padding-right: 0;
    border-right: medium none
}

#utility-links li.print a {
    background-position: 0 0
}

#utility-links li.print a:hover, #utility-links li.print a:focus {
    background-position: 0 -20px
}

#utility-links li.contact a {
    background-position: 0 -40px
}

#utility-links li.contact a:hover, #utility-links li.contact a:focus {
    background-position: 0 -60px
}

#utility-links li.chat a {
    background-position: 0 -80px
}

#utility-links li.chat a:hover, #utility-links li.chat a:focus {
    background-position: 0 -100px
}

#utility-links li.access a {
    background-position: 0 -120px
}

#utility-links li.access a:hover, #utility-links li.access a:focus {
    background-position: 0 -140px
}

#utility-links li.share a {
    background-position: 0 -160px
}

#utility-links li.share a:hover, #utility-links li.share a:focus {
    background-position: 0 -180px
}

#utility-links li.application a {
    background-position: 0 -200px
}

#utility-links li.application a:hover, #utility-links li.application a:focus {
    background-position: 0 -220px
}

#utility-links li.contact a {
    padding-left: 13px
}

#utility-links li.print {
    display: none
}

@media (min-width: 60em) {
    #utility-links li.print {
        display: block
    }
}

.left-icon .icon-chevron-single:before {
    content: "\f104"
}

.left-icon .icon-chevron:before {
    content: "\f100"
}

span[class^="icon"]:before {
    font-family: "icomoon";
    font-weight: normal;
    font-style: normal;
    text-decoration: inherit;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

span.icon-settings:before {
    content: "\f013"
}

span.icon-checkmark:before {
    content: "\f00c"
}

span.icon-remove:before {
    content: "\f057"
}

span.icon-tools:before {
    content: "\f0ad"
}

span.icon-error:before {
    content: "\f071"
}

span.icon-refresh:before {
    content: "\f021"
}

span.icon-location:before {
    content: "\f041"
}

span.icon-chevron:before {
    content: "\f101"
}

span.icon-chevron-up:before {
    content: "\f102"
}

span.icon-chevron-down:before {
    content: "\f103"
}

span.icon-search:before {
    content: "\f002"
}

span.icon-heart:before {
    content: "\f004"
}

span.icon-star:before {
    content: "\f005"
}

span.icon-star-o:before {
    content: "\f006"
}

span.icon-user:before {
    content: "\f007"
}

span.icon-th-large:before {
    content: "\f009"
}

span.icon-th:before {
    content: "\f00a"
}

span.icon-th-list:before {
    content: "\f00b"
}

span.icon-check:before {
    content: "\f00c"
}

span.icon-times:before {
    content: "\f00d"
}

span.icon-search-plus:before {
    content: "\f00e"
}

span.icon-search-minus:before {
    content: "\f010"
}

span.icon-signal:before {
    content: "\f012"
}

span.icon-gear:before {
    content: "\f013"
}

span.icon-trash-o:before {
    content: "\f014"
}

span.icon-home:before {
    content: "\f015"
}

span.icon-clock-o:before {
    content: "\f017"
}

span.icon-rotate-right:before {
    content: "\f01e"
}

span.icon-refresh:before {
    content: "\f021"
}

span.icon-lock:before {
    content: "\f023"
}

span.icon-flag:before {
    content: "\f024"
}

span.icon-volume-off:before {
    content: "\f026"
}

span.icon-volume-down:before {
    content: "\f027"
}

span.icon-volume-up:before {
    content: "\f028"
}

span.icon-camera:before {
    content: "\f030"
}

span.icon-align-justify:before {
    content: "\f039"
}

span.icon-list:before {
    content: "\f03a"
}

span.icon-video-camera:before {
    content: "\f03d"
}

span.icon-pencil:before {
    content: "\f040"
}

span.icon-check-square-o:before {
    content: "\f046"
}

span.icon-step-backward:before {
    content: "\f048"
}

span.icon-fast-backward:before {
    content: "\f049"
}

span.icon-backward:before {
    content: "\f04a"
}

span.icon-play:before {
    content: "\f04b"
}

span.icon-pause:before {
    content: "\f04c"
}

span.icon-stop:before {
    content: "\f04d"
}

span.icon-forward:before {
    content: "\f04e"
}

span.icon-fast-forward:before {
    content: "\f050"
}

span.icon-step-forward:before {
    content: "\f051"
}

span.icon-eject:before {
    content: "\f052"
}

span.icon-chevron-left:before {
    content: "\f053"
}

span.icon-chevron-right:before {
    content: "\f054"
}

span.icon-plus:before {
    content: "\f055"
}

span.icon-minus:before {
    content: "\f056"
}

span.icon-times-circle:before {
    content: "\f057"
}

span.icon-check-circle:before {
    content: "\f058"
}

span.icon-question-circle:before {
    content: "\f059"
}

span.icon-info-circle:before {
    content: "\f05a"
}

span.icon-times-circle-o:before {
    content: "\f05c"
}

span.icon-check-circle-o:before {
    content: "\f05d"
}

span.icon-ban:before {
    content: "\f05e"
}

span.icon-arrow-left:before {
    content: "\f060"
}

span.icon-arrow-right:before {
    content: "\f061"
}

span.icon-arrow-up:before {
    content: "\f062"
}

span.icon-arrow-down:before {
    content: "\f063"
}

span.icon-exclamation-circle:before {
    content: "\f06a"
}

span.icon-warning:before {
    content: "\f071"
}

span.icon-comment:before {
    content: "\f075"
}

span.icon-chevron-up:before {
    content: "\f077"
}

span.icon-chevron-down:before {
    content: "\f078"
}

span.icon-comments:before {
    content: "\f086"
}

span.icon-thumb-tack:before {
    content: "\f08d"
}

span.icon-external-link:before {
    content: "\f08e"
}

span.icon-phone:before {
    content: "\f095"
}

span.icon-wrench:before {
    content: "\f0ad"
}

span.icon-bars:before {
    content: "\f0c9"
}

span.icon-caret-down:before {
    content: "\f0d7"
}

span.icon-caret-up:before {
    content: "\f0d8"
}

span.icon-caret-left:before {
    content: "\f0d9"
}

span.icon-caret-right:before {
    content: "\f0da"
}

span.icon-unsorted:before {
    content: "\f0dc"
}

span.icon-envelope:before {
    content: "\f0e0"
}

span.icon-rotate-left:before {
    content: "\f0e2"
}

span.icon-flash:before {
    content: "\f0e7"
}

span.icon-exchange:before {
    content: "\f0ec"
}

span.icon-angle-double-left:before {
    content: "\f100"
}

span.icon-angle-double-right:before {
    content: "\f101"
}

span.icon-angle-double-up:before {
    content: "\f102"
}

span.icon-angle-double-down:before {
    content: "\f103"
}

span.icon-angle-left:before {
    content: "\f104"
}

span.icon-angle-right:before {
    content: "\f105"
}

span.icon-angle-up:before {
    content: "\f106"
}

span.icon-angle-down:before {
    content: "\f107"
}

span.icon-location-arrow:before {
    content: "\f124"
}

span.icon-question:before {
    content: "\f128"
}

span.icon-info:before {
    content: "\f129"
}

span.icon-exclamation:before {
    content: "\f12a"
}

span.icon-calendar-o:before {
    content: "\f133"
}

span.icon-chevron-circle-left:before {
    content: "\f137"
}

span.icon-chevron-circle-right:before {
    content: "\f138"
}

span.icon-chevron-circle-up:before {
    content: "\f139"
}

span.icon-chevron-circle-down:before {
    content: "\f13a"
}

span.icon-unlock-alt:before {
    content: "\f13e"
}

span.icon-ellipsis-h:before {
    content: "\f141"
}

span.icon-ellipsis-v:before {
    content: "\f142"
}

span.icon-wheelchair:before {
    content: "\f193"
}

span.icon-share:before {
    content: "\e600"
}

ul {
    list-style-type: disc
}

ol {
    list-style: decimal
}

ul ul, ul ol, ul p, ol ul, ol ol, ol p {
    margin: 0.5em 0 0.5em 30px
}

ul p, ol p {
    margin-left: 0
}

@media (min-width: 48em) {
    .component ul, .component ol {
        font-size: 0.8125em
    }
}

@media (min-width: 60em) {
    .component ul, .component ol {
        font-size: 1em
    }
}

.list ul, ul.list {
    margin: 0;
    list-style-type: none
}

.split-2 ul, .split-3 ul {
    margin: 1.5em 0
}

.split-2 h2, .split-3 h2 {
    margin: 1.5em 0
}

@media (min-width: 48em) {
    .split-2 h2, .split-3 h2 {
        text-align: center
    }
}

.list li {
    background: none repeat scroll 0 0 transparent;
    border-bottom: 1px solid #ccd8e2;
    padding: 5px 0
}

.list li.last {
    border: 0
}

.no-border-bottom li {
    border: 0
}

.list-style-checkmark li:before {
    font-family: "Streamline";
    font-weight: normal;
    font-style: normal;
    text-decoration: inherit;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\e2fe";
    color: #008a00;
    margin-right: 1em;
    font-weight: bold;
    font-size: 16;
    display: table-cell;
    width: 2em
}

.split-2 li, .split-3 li {
    display: table;
    margin-bottom: 0.5em;
    text-align: left
}

@media (min-width: 48em) {
    .split-2 li, .split-3 li {
        float: left;
        width: 50%;
        box-sizing: border-box;
        padding-right: 3em
    }
    .split-2 li:nth-child(2n+1), .split-3 li:nth-child(2n+1) {
        clear: both
    }
}

@media (min-width: 60em) {
    .split-3 li {
        width: 33.333%
    }
    .split-3 li:nth-child(2n+1) {
        clear: none
    }
    .split-3 li:nth-child(3n+1) {
        clear: both
    }
}

input[type="text"].error, input[type="password"].error, input[type="datetime"].error, input[type="date"].error, input[type="time"].error, input[type="number"].error, input[type="email"].error, input[type="url"].error, input[type="search"].error, input[type="tel"].error, textarea.error, select.error, .hasError input[type="text"], .hasError input[type="password"], .hasError input[type="datetime"], .hasError input[type="date"], .hasError input[type="time"], .hasError input[type="number"], .hasError input[type="email"], .hasError input[type="url"], .hasError input[type="search"], .hasError input[type="tel"], .hasError textarea, .hasError select {
    border-color: #d22f2f
}

legend {
    padding: 0;
    color: #021829;
    margin-bottom: 5px;
    font-size: 1em;
    font-family: "Optimist Bold", Arial, Helvetica, sans-serif
}

label {
    font-size: 1em;
    font-family: "Optimist Bold", Arial, Helvetica, sans-serif;
    margin-bottom: 5px;
    display: block;
    vertical-align: middle
}

label span.fldTooltip {
    position: absolute;
    bottom: 30px;
    left: -999em;
    background-color: #666;
    color: #fff;
    padding: 10px;
    margin: 0;
    box-shadow: 0 0 5px rgba(50, 50, 50, 0.8);
    border-radius: 5px;
    z-index: 45;
    width: 280px
}

label span.fldTooltip h2, label span.fldTooltip h3, label span.fldTooltip h4, label span.fldTooltip h5 {
    color: #fff
}

label span.fldTooltip:after {
    background-image: url("../../img/icon/global-icons.png");
    background-repeat: no-repeat;
    background-position: 0 -400px;
    position: absolute;
    bottom: -13px;
    left: 17px;
    display: block;
    width: 20px;
    height: 13px;
    content: ""
}

label span.visible {
    left: -10px
}

input[type="text"], input[type="password"], input[type="datetime"], input[type="date"], input[type="time"], input[type="number"], input[type="email"], input[type="url"], input[type="search"], input[type="tel"], textarea, select {
    border-radius: 3px;
    background-color: #fff;
    border: 2px solid #c2c0c6;
    width: 100%;
    margin-bottom: 5px;
    font-size: 1em;
    padding: 8px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -moz-text-size-adjust: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

input[type="text"]:active, input[type="text"]:focus, input[type="password"]:active, input[type="password"]:focus, input[type="datetime"]:active, input[type="datetime"]:focus, input[type="date"]:active, input[type="date"]:focus, input[type="time"]:active, input[type="time"]:focus, input[type="number"]:active, input[type="number"]:focus, input[type="email"]:active, input[type="email"]:focus, input[type="url"]:active, input[type="url"]:focus, input[type="search"]:active, input[type="search"]:focus, input[type="tel"]:active, input[type="tel"]:focus, textarea:active, textarea:focus, select:active, select:focus {
    border-color: #019ed5
}

input[type="text"], input[type="password"], input[type="datetime"], input[type="date"], input[type="time"], input[type="number"], input[type="email"], input[type="url"], input[type="search"], input[type="tel"] {
    -webkit-appearance: none;
    display: block
}

@media (min-width: 48em) {
    input[type="text"], input[type="password"], input[type="datetime"], input[type="date"], input[type="time"], input[type="number"], input[type="email"], input[type="url"], input[type="search"], input[type="tel"] {
        width: auto
    }
}

textarea {
    font-family: "Optimist", Arial, Helvetica, sans-serif
}

.select-style {
    position: relative;
    display: block
}

@media (min-width: 48em) {
    .select-style {
        display: inline-block
    }
}

.select-style select {
    padding: 8px 40px 8px 8px;
    font-size: 1em;
    width: 100%;
    display: block;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

@media (min-width: 48em) {
    .select-style select {
        width: auto
    }
}

.select-style select option {
    vertical-align: middle;
    line-height: 1
}

.select-style select::-ms-expand {
    display: none
}

.select-style:after {
    display: block;
    font-family: "Streamline";
    font-weight: normal;
    font-style: normal;
    text-decoration: inherit;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\e628";
    position: absolute;
    line-height: 1;
    top: 12px;
    right: 10px;
    pointer-events: none
}

optgroup {
    color: #444;
    font-style: normal
}

.input-helper {
    background-color: #fff;
    border: 2px solid #c2c0c6;
    border-radius: 3px;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    width: 24px;
    height: 24px;
    margin: 0 4px 0 0;
    padding: 0;
    position: relative;
    top: -1px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.form-framework.default fieldset div input {
    opacity: 1;
    position: relative;
    top: 1px;
    width: 16px;
    height: 16px
}

.form-framework.default .input-helper {
    display: none !important
}

.form-framework input[type="radio"]:checked+.input-helper, .form-framework input[type="checkbox"]:checked+.input-helper, .styled-input input[type="radio"]:checked+.input-helper, .styled-input input[type="checkbox"]:checked+.input-helper {
    border-color: #10bd1b
}

.form-framework input[type="radio"]:focus+.input-helper, .form-framework input[type="checkbox"]:focus+.input-helper, .styled-input input[type="radio"]:focus+.input-helper, .styled-input input[type="checkbox"]:focus+.input-helper {
    border-color: #019ed5
}

.form-framework input[type="radio"]+.input-helper, .styled-input input[type="radio"]+.input-helper {
    border-radius: 100%;
    -webkit-transition: border 0.23s ease-out 0s;
    -ms-transition: border 0.23s ease-out 0s;
    transition: border 0.23s ease-out 0s
}

.form-framework input[type="radio"]:checked+.input-helper, .form-framework input[type="radio"]:focus+.input-helper, .styled-input input[type="radio"]:checked+.input-helper, .styled-input input[type="radio"]:focus+.input-helper {
    border-width: 6px
}

.form-framework input[type="checkbox"]+.input-helper, .styled-input input[type="checkbox"]+.input-helper {
    text-indent: 0;
    line-height: 20px;
    -webkit-transition: background-color 0.23s ease-out 0s;
    -ms-transition: background-color 0.23s ease-out 0s;
    transition: background-color 0.23s ease-out 0s
}

.form-framework input[type="checkbox"]:checked+.input-helper, .styled-input input[type="checkbox"]:checked+.input-helper {
    background-color: #10bd1b
}

.form-framework input[type="checkbox"]:checked+.input-helper:after, .styled-input input[type="checkbox"]:checked+.input-helper:after {
    font-family: "Streamline";
    font-weight: normal;
    font-style: normal;
    text-decoration: inherit;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -moz-osx-font-smoothing: auto;
    -webkit-font-smoothing: auto;
    content: "\e2fe";
    color: #fff;
    padding-left: 4px;
    font-size: 10px;
    font-weight: bold
}

.form-framework input[type="checkbox"]:focus+.input-helper, .styled-input input[type="checkbox"]:focus+.input-helper {
    background-color: #019ed5
}

.form-framework fieldset, .styled-input fieldset {
    margin-bottom: 1em
}

.form-framework fieldset div, .styled-input fieldset div {
    border-radius: 5px;
    clear: both;
    background-color: #eff0f1;
    margin-bottom: 5px;
    padding: 10px
}

.form-framework fieldset div:after, .styled-input fieldset div:after {
    display: block;
    visibility: hidden;
    clear: both;
    height: 0;
    content: "."
}

@media (min-width: 48em) {
    .form-framework fieldset div, .styled-input fieldset div {
        background-color: transparent;
        padding: 0;
        margin: 0
    }
}

.form-framework fieldset div input, .styled-input fieldset div input {
    cursor: pointer;
    opacity: 0;
    position: absolute;
    width: 24px;
    height: 24px
}

.form-framework fieldset div label, .styled-input fieldset div label {
    line-height: 24px;
    vertical-align: middle;
    padding: 2px 0 2px 32px;
    text-indent: -32px;
    margin-bottom: 0;
    font-weight: normal;
    font-family: "Optimist", Arial, Helvetica, sans-serif;
    display: block;
    cursor: pointer
}

@media (min-width: 48em) {
    .form-framework fieldset div label, .styled-input fieldset div label {
        margin-bottom: 5px
    }
}

.form-framework>div, .styled-input>div {
    margin-bottom: 1em
}

.form-framework label, .styled-input label {
    position: relative
}

.form-framework .fldError, .styled-input .fldError {
    color: #d22f2f;
    display: none
}

.form-framework .fldError:before, .styled-input .fldError:before {
    display: block;
    visibility: hidden;
    clear: both;
    height: 0;
    content: "."
}

.form-framework .fldError:after, .styled-input .fldError:after {
    content: ""
}

.form-framework .hasError .fldError, .styled-input .hasError .fldError {
    display: block
}

.form-framework .error, .styled-input .error {
    font-weight: normal;
    color: #021829
}

span.required, span.error {
    color: #d22f2f;
    font-weight: bold
}

.isRequired label:after, .isRequired legend:after {
    color: #d22f2f;
    content: "*";
    font-weight: bold
}

fieldset.isRequired label:after {
    content: normal !important
}

.hasInfo {
    position: relative
}

#frmReset {
    margin: 1em 0 0
}

@media (min-width: 48em) {
    #frmReset {
        margin: 0 0 0 10px
    }
}

.hasDesc input {
    display: inline !important;
    width: auto
}

.info-tip-icon {
    background-image: url("../../img/icon/info-tip.png");
    background-repeat: no-repeat;
    display: block;
    height: 22px;
    overflow: hidden;
    position: absolute;
    right: 8px;
    top: 35px;
    width: 22px;
    cursor: pointer
}

.hp-text {
    left: -999em;
    position: absolute
}

.before {
    margin-right: 3px
}

.after {
    margin-left: 3px
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 47.9375em) {
    select, textarea, input[type="text"], input[type="password"], input[type="datetime"], input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], input[type="email"], input[type="url"], input[type="search"], input[type="tel"], input[type="color"] {
        font-size: 16px !important
    }
}

#wrapper {
    width: auto;
    height: auto;
    position: relative;
    z-index: 30
}

#wrapper:after {
    display: block;
    visibility: hidden;
    clear: both;
    height: 0;
    content: " "
}

.no-header-body #wrapper {
    background-image: none
}

@media (min-width: 48em) {
    #wrapper {
        min-height: 100%
    }
}

.row {
    background-repeat: repeat-y;
    margin-bottom: 1em
}

.row-stripe {
    background-color: #e9e9e9;
    padding: 1em 1em 0
}

@media (min-width: 48em) {
    .row-stripe {
        padding: 1em 0 0
    }
}

.overlay-bg {
    background-color: rgba(255, 255, 255, 0.9);
    padding: 1em
}

@media (min-width: 48em) {
    .overlay-bg {
        padding: 2em 1em;
        margin: 1em -em(16)
    }
}

.container {
    position: relative;
    margin: 0 10px;
    padding: 0;
    width: auto
}

.container .columns, .container .column {
    display: inline;
    float: left;
    margin: 0;
    width: 100%
}

@media (min-width: 48em) {
    .container .columns, .container .column {
        margin-right: 0.625em;
        margin-left: 0.625em
    }
}

@media (min-width: 60em) {
    .container .columns, .container .column {
        margin-right: 1.25em;
        margin-left: 1.25em
    }
}

.container .columns:after, .container .column:after {
    display: block;
    visibility: hidden;
    clear: both;
    height: 0;
    content: "."
}

@media (min-width: 48em) {
    .container {
        margin: 0 auto;
        width: 48em
    }
    .container .alpha {
        margin-left: 0;
        margin-right: 0.625em
    }
    .container .omega {
        margin-left: 0.625em;
        margin-right: 0
    }
    .container .alpha.omega {
        margin-left: 0;
        margin-right: 0
    }
}

@media (min-width: 60em) {
    .container {
        width: 60em
    }
    .container .alpha {
        margin-right: 1.25em;
        margin-left: 0
    }
    .container .omega {
        margin-left: 1.25em;
        margin-right: 0
    }
    .container .alpha.omega {
        margin-left: 0;
        margin-right: 0
    }
}

.container:after {
    display: block;
    visibility: hidden;
    clear: both;
    height: 0;
    content: " "
}

@media (min-width: 48em) {
    .container .one {
        width: 1.75em
    }
    .container .offset-by-one {
        padding-left: 3em
    }
    .container .two {
        width: 4.75em
    }
    .container .offset-by-two {
        padding-left: 6em
    }
    .container .three {
        width: 7.75em
    }
    .container .offset-by-three {
        padding-left: 9em
    }
    .container .four {
        width: 10.75em
    }
    .container .offset-by-four {
        padding-left: 12em
    }
    .container .five {
        width: 13.75em
    }
    .container .offset-by-five {
        padding-left: 15em
    }
    .container .six {
        width: 16.75em
    }
    .container .offset-by-six {
        padding-left: 18em
    }
    .container .seven {
        width: 19.75em
    }
    .container .offset-by-seven {
        padding-left: 21em
    }
    .container .eight {
        width: 22.75em
    }
    .container .offset-by-eight {
        padding-left: 24em
    }
    .container .nine {
        width: 25.75em
    }
    .container .offset-by-nine {
        padding-left: 27em
    }
    .container .ten {
        width: 28.75em
    }
    .container .offset-by-ten {
        padding-left: 30em
    }
    .container .eleven {
        width: 31.75em
    }
    .container .offset-by-eleven {
        padding-left: 33em
    }
    .container .twelve {
        width: 34.75em
    }
    .container .offset-by-twelve {
        padding-left: 36em
    }
    .container .thirteen {
        width: 37.75em
    }
    .container .offset-by-thirteen {
        padding-left: 39em
    }
    .container .fourteen {
        width: 40.75em
    }
    .container .offset-by-fourteen {
        padding-left: 42em
    }
    .container .fifteen {
        width: 43.75em
    }
    .container .offset-by-fifteen {
        padding-left: 45em
    }
    .container .sixteen {
        width: 46.75em
    }
    .container .offset-by-sixteen {
        padding-left: 48em
    }
}

@media (min-width: 60em) {
    .container .one {
        width: 1.25em
    }
    .container .offset-by-one {
        padding-left: 3.75em
    }
    .container .two {
        width: 5em
    }
    .container .offset-by-two {
        padding-left: 7.5em
    }
    .container .three {
        width: 8.75em
    }
    .container .offset-by-three {
        padding-left: 11.25em
    }
    .container .four {
        width: 12.5em
    }
    .container .offset-by-four {
        padding-left: 15em
    }
    .container .five {
        width: 16.25em
    }
    .container .offset-by-five {
        padding-left: 18.75em
    }
    .container .six {
        width: 20em
    }
    .container .offset-by-six {
        padding-left: 22.5em
    }
    .container .seven {
        width: 23.75em
    }
    .container .offset-by-seven {
        padding-left: 26.25em
    }
    .container .eight {
        width: 27.5em
    }
    .container .offset-by-eight {
        padding-left: 30em
    }
    .container .nine {
        width: 31.25em
    }
    .container .offset-by-nine {
        padding-left: 33.75em
    }
    .container .ten {
        width: 35em
    }
    .container .offset-by-ten {
        padding-left: 37.5em
    }
    .container .eleven {
        width: 38.75em
    }
    .container .offset-by-eleven {
        padding-left: 41.25em
    }
    .container .twelve {
        width: 42.5em
    }
    .container .offset-by-twelve {
        padding-left: 45em
    }
    .container .thirteen {
        width: 46.25em
    }
    .container .offset-by-thirteen {
        padding-left: 48.75em
    }
    .container .fourteen {
        width: 50em
    }
    .container .offset-by-fourteen {
        padding-left: 52.5em
    }
    .container .fifteen {
        width: 53.75em
    }
    .container .offset-by-fifteen {
        padding-left: 56.25em
    }
    .container .sixteen {
        width: 57.5em
    }
    .container .offset-by-sixteen {
        padding-left: 60em
    }
}

@media (min-width: 48em) {
    .container .one-third {
        width: 14.75em
    }
    .container .two-thirds {
        width: 30.75em
    }
    .container .ten .one-third, .container .ten .one-thirds {
        width: 8.75em;
        overflow: hidden
    }
    .container .eight .one-third, .container .eight .one-thirds {
        width: 6.75em;
        overflow: hidden
    }
    .container .badge-carousel .twelve {
        width: 46.75em
    }
    .container .divide-16-4-4-4-4 {
        background-image: url(../../img/grid/16/4-4-4-4.tablet.gif)
    }
    .container .divide-16-4-6-6 {
        background-image: url(../../img/grid/16/4-6-6.tablet.gif)
    }
    .container .divide-16-5-6-5 {
        background-image: url(../../img/grid/16/5-6-5.tablet.gif)
    }
    .container .divide-16-thirds {
        background-image: url(../../img/grid/16/thirds.tablet.gif)
    }
    .container .divide-16-4-8-4, .container .divide-16-8-8, .container .divide-16-10-6, .container .divide-12-3-3-3-3, .container .divide-12-4-8, .container .divide-12-6-6, .container .divide-12-8-4, .container .divide-10-5-5, .container .divide-8-4-4, .container .divide-8-2-2-2-2 {
        background-image: url(../../img/grid/single.gif)
    }
    .container .divide-16-4-8-4 {
        background-position: 36em 0
    }
    .container .divide-16-8-8 {
        background-position: 24em 0
    }
    .container .divide-16-10-6 {
        background-position: 30em 0
    }
    .container .divide-12-3-3-3-3 {
        background-position: 17.375em 0
    }
    .container .divide-12-4-4-4 {
        background-image: url(../../img/grid/12/4-4-4.tablet.gif)
    }
    .container .divide-12-4-8 {
        background-position: 11.375em 0
    }
    .container .divide-12-6-6 {
        background-position: 17.375em 0
    }
    .container .divide-12-8-4 {
        background-position: 23.375em 0
    }
    .container .divide-10-thirds {
        background-image: url(../../img/grid/10/thirds.tablet.gif)
    }
    .container .divide-10-5-5 {
        background-position: 14.375em 0
    }
    .container .divide-8-4-4, .container .divide-8-2-2-2-2 {
        background-position: 11.375em 0
    }
    .container .no-divide {
        background-image: none !important
    }
    .container .twelve .three {
        width: 16.75em
    }
    .container .eight .two {
        width: 10.75em
    }
    .container .twelve .three:nth-child(odd), .container .eight .two:nth-child(odd) {
        margin-left: 0
    }
    .container .twelve .three:nth-child(even), .container .eight .two:nth-child(even) {
        margin-right: 0
    }
}

@media (min-width: 60em) {
    .container .one-third {
        width: 17.5em
    }
    .container .two-thirds {
        width: 37.5em
    }
    .container .ten .one-third, .container .ten .one-thirds {
        width: 10em;
        overflow: hidden
    }
    .container .eight .one-third, .container .eight .one-thirds {
        width: 7.5em;
        overflow: hidden
    }
    .container .badge-carousel .twelve {
        width: 43.75em
    }
    .container .divide-16-4-8-4, .container .divide-16-8-8, .container .divide-16-10-6, .container .divide-12-3-3-3-3, .container .divide-12-4-8, .container .divide-12-6-6, .container .divide-12-8-4, .container .divide-10-5-5, .container .divide-8-4-4, .container .divide-8-2-2-2-2 {
        background-image: url(../../img/grid/single.gif)
    }
    .container .divide-16-4-4-4-4 {
        background-image: url(../../img/grid/16/4-4-4-4.gif)
    }
    .container .divide-16-4-6-6 {
        background-image: url(../../img/grid/16/4-6-6.gif)
    }
    .container .divide-16-4-8-4 {
        background-position: 45em 0
    }
    .container .divide-16-5-6-5 {
        background-image: url(../../img/grid/16/5-6-5.gif)
    }
    .container .divide-16-thirds {
        background-image: url(../../img/grid/16/thirds.gif)
    }
    .container .divide-16-8-8 {
        background-position: 30em 0
    }
    .container .divide-16-10-6 {
        background-position: 37.5em 0
    }
    .container .divide-12-3-3-3-3 {
        background-image: url(../../img/grid/12/3-3-3-3.gif);
        background-position: -0.75em 0
    }
    .container .divide-12-4-4-4 {
        background-image: url(../../img/grid/12/4-4-4.gif);
        background-position: -0.75em 0
    }
    .container .divide-12-4-8 {
        background-position: 13.625em 0
    }
    .container .divide-12-6-6 {
        background-position: 21.125em 0
    }
    .container .divide-12-8-4 {
        background-position: 28.625em 0
    }
    .container .divide-10-thirds {
        background-image: url(../../img/grid/10/thirds.gif)
    }
    .container .divide-10-5-5 {
        background-position: 17.5em 0
    }
    .container .divide-8-4-4 {
        background-position: 13.625em 0
    }
    .container .divide-8-2-2-2-2 {
        background-image: url(../../img/grid/8/2-2-2-2.gif);
        background-position: -0.5em 0
    }
    .container .twelve .three {
        width: 8.75em
    }
    .container .eight .two {
        width: 5em
    }
    .container .twelve .three:nth-child(odd), .container .eight .two:nth-child(odd) {
        margin-left: 1.25em
    }
    .container .twelve .three:nth-child(even), .container .eight .two:nth-child(even) {
        margin-right: 1.25em
    }
    .container .twelve .three.alpha, .container .eight .two.alpha {
        margin-left: 0
    }
    .container .twelve .three.omega, .container .eight .two.omega {
        margin-right: 0
    }
}

.columns+.columns {
    margin-top: 1em
}

@media (min-width: 48em) {
    .columns+.columns {
        margin-top: 0
    }
}

@media (min-width: 60em) {
    .columns+.columns {
        margin-top: 0
    }
}

@media (max-width: 47.9375em) {
    .mob-50 {
        margin-bottom: 0
    }
    .mob-50 .columns {
        float: left;
        width: 48%;
        padding: 0 1%;
        margin-bottom: 1em
    }
    .mob-50 .columns:nth-child(3n+3) {
        clear: left
    }
    .mob-50 .columns+.columns {
        margin-top: 0
    }
}

body.sectioned #page-body-wrapper {
    width: auto;
    margin: 0
}

@media (min-width: 48em) {
    body.sectioned .breadcrumb-wrapper {
        background-color: #efefef
    }
    body.sectioned .breadcrumbs, body.sectioned #nav-breadcrumb {
        width: 46.75em;
        margin: 0 auto
    }
    body.sectioned .main-content {
        width: 48em;
        margin: 0 auto 1em
    }
}

@media (min-width: 60em) {
    body.sectioned .breadcrumbs, body.sectioned #nav-breadcrumb {
        width: 58.125em;
        margin: 0 auto
    }
    body.sectioned .main-content {
        width: 60em
    }
}

.section {
    padding: 1em 0
}

.section .section .container {
    width: auto
}

.section .callout-basic-top ul, .section .component ul {
    text-align: left !important
}

.section:focus {
    outline: none
}

.bg-image {
    background-repeat: no-repeat;
    background-scroll: scroll;
    background-position: top center
}

.bg {
    background-color: #e9e9e9
}

.bg-alt {
    background-color: #e6f5fb
}

.bg-alt a {
    color: #0375a3
}

.bg-alt2 {
    background-color: #303e4e;
    color: #fff;
    text-shadow: 0 2px 3px rgba(0, 0, 0, 0.75)
}

.bg-alt2 a, .bg-alt2 a:link, .bg-alt2 a:visited {
    color: #94dfff
}

.bg-alt2 .c1-button {
    text-decoration: none
}

.bg-right {
    background-position: top right
}

.repeatX {
    background-repeat: repeat-x
}

.bottom {
    background-position: bottom center
}

.middle {
    background-position: center center
}

.full-width {
    background-size: 100% auto
}

.full-height {
    background-size: auto 100%
}

.bg-cover {
    background-size: cover
}

.bg-restrained {
    background-size: auto auto
}

.padded, .padded-alt {
    padding: 3.75em 0 3em
}

@media (min-width: 60em) {
    .padded {
        padding: 5.25em 0 4.5em
    }
    .padded-alt {
        padding: 7.5em 0 6.75em
    }
}

.double-padded {
    padding: 5.625em 0 4.875em
}

@media (min-width: 48em) {
    .double-padded {
        padding: 7.8125em 0 7.0625em
    }
}

@media (min-width: 60em) {
    .double-padded {
        padding: 10em 0 9.25em
    }
}

.reversed, .reversed h1, .reversed h2, .reversed h3, .reversed h4, .reversed h5, .accordion-content .reversed h1, .accordion-content .reversed h2, .accordion-content .reversed h3, .accordion-content .reversed h4, .accordion-content .reversed h5 {
    color: #fff;
    text-shadow: 0 2px 3px rgba(0, 0, 0, 0.75)
}

.reversed a, .accordion-content .reversed h1 a, .accordion-content .reversed h2 a, .accordion-content .reversed h3 a, .accordion-content .reversed h4 a, .accordion-content .reversed h5 a, .reversed a:link, .reversed a:visited {
    color: #94dfff
}

.reversed .c1-button, .accordion-content .reversed h1 .c1-button, .accordion-content .reversed h2 .c1-button, .accordion-content .reversed h3 .c1-button, .accordion-content .reversed h4 .c1-button, .accordion-content .reversed h5 .c1-button {
    text-decoration: none
}

.feature {
    margin-top: -2em
}

@media (min-width: 48em) {
    .feature {
        margin-top: -24px
    }
}

@media (min-width: 60em) {
    .feature {
        margin-top: -32px
    }
}

.feature h1#page-heading {
    float: none;
    max-width: 100%
}

.feature, .feature h2, .feature h3, .feature h4, .feature h5 {
    text-shadow: none
}

.overlap-top {
    padding-top: 2.25em
}

.overlap-bottom {
    padding-bottom: 2.25em
}

.push-up {
    margin-top: -3em
}

.push-down {
    margin-bottom: -3.75em
}

@media (max-width: 47.9375em) {
    .section .push-up {
        margin-top: 0
    }
    .section .push-down {
        margin-bottom: 0
    }
}

.over {
    position: relative;
    z-index: 2
}

.under {
    position: relative;
    z-index: 1
}

.bg-white {
    background-color: rgba(255, 255, 255, 0.8);
    padding: 1em 0
}

.bg-black {
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    padding: 1em 0
}

.bg-black h2, .bg-black h3, .bg-black h4, .bg-black h5, .bg-black h6 {
    color: #fff
}

.padding {
    padding: 1em
}

@media (min-width: 48em) {
    .extra-padding {
        padding: 3em 1em
    }
}

@media (min-width: 60em) {
    .extra-padding {
        padding: 5.25em 1em
    }
}

.rounded {
    border-radius: 8px
}

.shadow {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4)
}

#Headline-sec-row h3 {
    text-align: center
}

@media (min-width: 48em) {
    #Headline-sec-row h3 {
        text-align: left
    }
}

@media (min-width: 60em) {
    #Headline-sec-row h3 {
        text-align: left
    }
}

#mobile-section {
    padding: 1em 0 8em !important
}

#mobile-section h2 {
    text-align: center
}

#disclosures .sixteen {
    height: 530px;
    overflow: scroll;
    width: 90%;
    padding: 0 5%
}

@media (min-width: 48em) {
    #disclosures .sixteen {
        width: 100%;
        padding: 0
    }
}

#disclosures .sixteen>div {
    margin-right: 15px
}

.usip h2 {
    border-bottom: 2px solid #021829;
    margin-bottom: 1em
}

.usip .c1-button+.c1-button {
    margin-left: 0
}

.trim h2 {
    margin-bottom: 0
}

.trim .component, .trim .component p:last-child, .trim .component ul:last-child, .trim .component img {
    margin-bottom: 0
}

.a11y-header {
    height: 64px;
    width: 100%;
    z-index: 0;
    position: absolute
}

@media (min-width: 48em) {
    .a11y-header {
        height: 120px
    }
}

.header {
    position: relative;
    margin: 0;
    z-index: 90;
    height: 75px
}

@media (min-width: 48em) {
    .header {
        height: 100px;
        position: relative;
    }
}

.header .affiliate-logo {
    top: 3px;
    bottom: auto !important
}

.header>.container {
    position: static
}

@media (min-width: 48em) {
    .nav-off {
        height: 90px
    }
}

#skip-nav {
    display: none
}

#skip-nav, #skip-nav:hover, #skip-nav:visited {
    position: absolute;
    left: -999em;
    display: block;
    overflow: hidden;
    width: 1px;
    height: 1px;
    background-color: #fff
}

#skip-nav:active, #skip-nav:focus {
    left: 0;
    padding: 5px 10px;
    width: auto;
    z-index: 100;
    height: auto
}

@media (min-width: 48em) {
    #skip-nav:active, #skip-nav:focus {
        left: 200px
    }
}

.capitalone-logo {
    position: relative;
    overflow: hidden;
    margin-top: 1em;
    padding-bottom: 1em;
    width: 152px;
    height: 40px;
    float: left;
    left: 50%;
    margin-left: -56px
}

@media (min-width: 48em) {
    .capitalone-logo {
        left: auto;
        margin: 11px 0 11px 0.625em;
        padding-bottom: 0;
        height: 50px;
        width: 155px;
        top: 10px
    }
}

@media (min-width: 60em) {
    .capitalone-logo {
        width: 195px;
        top: 0;
        left: 1em
    }
}

.capitalone-logo h1, .capitalone-logo a {
    width: 150px;
    height: 42px;
    margin: 1px
}

@media (min-width: 48em) {
    .capitalone-logo h1, .capitalone-logo a {
        width: 193px;
        height: 48px
    }
}

.capitalone-logo h1 {
    color: #004977
}

.capitalone-logo a {
    overflow: hidden;
    display: block
}

.capitalone-logo a:focus {
    outline: 1px dotted #004977
}

.capitalone-logo img {
    position: absolute;
    width: 100%;
    -ms-interpolation-mode: bicubic
}

.capitalone-logo span {
    color: #fff;
    position: absolute;
    top: -999em;
    overflow: hidden
}

.capitalone-logo .default-logo, .capitalone-logo .bank-logo {
    top: 0
}

@media (min-width: 48em) {
    .capitalone-logo .bank-logo {
        top: -60px
    }
}

@media (min-width: 60em) {
    .capitalone-logo .bank-logo {
        top: -75px
    }
}

#acf-custom-header .capitalone-logo {
    height: 65px
}

.mobile-toggle {
    font-family: "Optimist", Arial, Helvetica, sans-serif;
    font-weight: 400;
    display: block;
    border: 0;
    background: none;
    position: absolute;
    top: 0;
    font-size: 12px;
    cursor: pointer;
    background-color: transparent;
    padding: 1em;
    width: auto;
    height: 66px
}

.ent-decom-bar {
    background: #d22f2f;
    top: 0;
    left: 0;
    width: 100%;
    color: #fff
}

.ent-decom-bar p {
    margin-top: 1em
}

.ent-decom-bar.safariLogin p, .ent-decom-bar.warnDismiss p {
    font-size: 0.875em
}

.ent-decom-bar a {
    color: #fff !important;
    text-decoration: underline
}

.ent-decom-bar a:hover {
    text-decoration: none
}

.ent-decom-bar button#decomDismiss {
    padding: 3px;
    float: right;
    background: none;
    border: 0;
    color: #fff;
    position: relative;
    right: 0;
    top: -3px;
    font-size: 1.25em;
    text-decoration: none
}

.ent-decom-bar button#decomDismiss:hover {
    padding: 2px;
    cursor: pointer;
    background: #bd848f;
    border: 1px solid #efefef
}

.overlay {
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.97);
    background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.97) 50%, rgba(255, 255, 255, 0.75) 100%);
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.97) 50%, rgba(255, 255, 255, 0.75) 100%);
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    transform: translateY(-100%);
    -webkit-transition: -webkit-transform 1s ease-in-out;
    -moz-transition: -moz-transform 1s ease-in-out;
    transition: transform 1s ease-in-out;
    display: none;
    z-index: 9999;
    overflow: scroll
}

@media (min-width: 48em) {
    .overlay {
        -webkit-transition: -webkit-transform 0.75s ease-in-out;
        -moz-transition: -moz-transform 0.75s ease-in-out;
        transition: transform 0.75s ease-in-out
    }
}

.overlay .overlay-close {
    position: absolute;
    top: 1em;
    right: 1em;
    background: none;
    border: 0;
    cursor: pointer;
    font-size: 14px;
    font-family: "Optimist", Arial, Helvetica, sans-serif;
    font-weight: 400;
    z-index: 100
}

@media (min-width: 48em) {
    .overlay .overlay-close {
        top: 2em;
        right: 2em
    }
}

.overlay .overlay-close:hover, .overlay .overlay-close:focus {
    outline: 1px dotted #021829
}

.overlay .overlay-close:before {
    font-family: "Streamline";
    font-weight: normal;
    font-style: normal;
    text-decoration: inherit;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -moz-osx-font-smoothing: auto;
    -webkit-font-smoothing: auto;
    color: #19a0d4;
    content: "\e306";
    padding-right: 6px;
    vertical-align: -2px
}

.overlay .overlay-header {
    background-color: #f8f7f8;
    padding: 1em 0 0;
    position: relative
}

@media (min-width: 48em) {
    .overlay .overlay-header {
        padding: 5em 0 1px;
        margin-bottom: 1em
    }
}

.overlay .overlay-header p {
    font-size: 16px
}

.overlay .overlay-header .overlay-close {
    top: 0;
    right: 0
}

@media (min-width: 48em) {
    .overlay .overlay-header .overlay-close {
        top: -2em
    }
}

.overlay.open {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    transform: translateY(0)
}

#nav-global {
    list-style-type: none;
    float: right;
    margin: 0
}

#nav-global a, #nav-global button {
    color: #001729;
    line-height: 1.2;
    text-align: center;
    font-family: "Optimist", Arial, Helvetica, sans-serif;
    font-weight: 400;
    display: block;
    border: 0;
    background: none;
    font-size: 12px;
    cursor: pointer;
    background-color: transparent;
    padding: 1em;
    width: 66px;
    height: 66px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

#nav-global a:before, #nav-global button:before {
    font-family: "Streamline";
    font-weight: normal;
    font-style: normal;
    text-decoration: inherit;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: block;
    color: #528dab;
    font-size: 2em
}

#nav-global a.sign-in-toggle:before, #nav-global a.sign-in-link:before, #nav-global button.sign-in-toggle:before, #nav-global button.sign-in-link:before {
    content: "";
    overflow: hidden;
    background-image: url(../../img/icon/filled-nav-icons.png);
    background-size: 36px;
    background-position: -6px -78px;
    width: 1em;
    height: 1em;
    margin: 0 auto 0.16667em;
    box-sizing: border-box;
    line-height: 32px;
    text-align: center
}

@media (min-width: 48em) {
    #nav-global {
        margin: 0 1em 0 0;
        height: 5.1em
    }
    #nav-global li {
        display: block;
        float: left
    }
    #nav-global li a, #nav-global li button {
        height: 5.15em;
        line-height: 5.15em;
        font-size: 14px;
        padding: 0 0.75em;
        width: auto;
        font-weight: 400;
        -webkit-font-smoothing: subpixel-antialiased
    }
    #nav-global li a:before, #nav-global li button:before {
        display: inline-block;
        color: #99a1a9;
        vertical-align: middle;
        margin-right: 0.4em;
        font-size: 24px;
        font-weight: 400;
        line-height: 24px;
        text-decoration: none;
        width: auto
    }
    #nav-global li a:hover, #nav-global li a:focus, #nav-global li button:hover, #nav-global li button:focus {
        text-decoration: underline
    }
    #nav-global li a.search-toggle:before, #nav-global li button.search-toggle:before {
        content: "\e01f"
    }
    #nav-global li a.locations:before, #nav-global li button.locations:before {
        content: "\e3a0"
    }
    #nav-global li a.sign-in-toggle:before, #nav-global li a.sign-in-link:before, #nav-global li button.sign-in-toggle:before, #nav-global li button.sign-in-link:before {
        font-size: 1.25em;
        border-radius: 0;
        border-width: 0;
        margin-right: 0.4em;
        width: 24px;
        height: 24px;
        content: " ";
        line-height: 1.8em;
        text-align: center
    }
}

@media (min-width: 60em) {
    #nav-global {
        height: 4.5em
    }
    #nav-global li a, #nav-global li button {
        height: 4.5em;
        line-height: 4.5em;
        font-size: 16px
    }
}

#search-form .overlay-header {
    text-align: center
}

#search-form .overlay-header .search-icon {
    margin: 0 auto;
    content: " ";
    display: block;
    width: 3em;
    height: 3em;
    background-size: 48px;
    background-image: url(../../img/icon/panel-icons.png);
    background-position: 0 -60px
}

#search-form .overlay-header h2 {
    font-size: 1.5em;
    line-height: 1;
    margin: 4px 0
}

@media (min-width: 48em) {
    #search-form .overlay-header {
        text-align: left
    }
    #search-form .overlay-header .search-icon {
        float: left;
        margin: 0 16px 24px 0;
        width: 4.5em;
        height: 5.25em;
        background-position: 0 -82px;
        background-size: 4.5em
    }
    #search-form .overlay-header h2 {
        font-size: 3em;
        font-weight: 200;
        margin: 4px
    }
}

#search-form label {
    position: absolute;
    top: 29px;
    padding-left: 13px;
    z-index: 20;
    color: #767676;
    font-size: 1em
}

#search-form label.off {
    top: -999em
}

#search-form #search {
    position: relative;
    clear: both;
    margin-bottom: 20px
}

#search-form #search .search-term::-ms-clear {
    display: none
}

#search-form #search .search-term {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    margin: 16px 0 0;
    padding: 10px;
    border: 2px solid #c2c0c6;
    border-radius: 3px;
    background-color: #fff;
    font-size: 1em;
    line-height: 1.5;
    width: 100%;
    font-family: "Optimist", Arial, Helvetica, sans-serif;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

#search-form #search .search-term:focus {
    border-color: #19a0d4;
    outline: none;
    box-shadow: none
}

#search-form #search .search-btn {
    position: absolute;
    top: 18px;
    right: 2px;
    margin: 0;
    padding: 0;
    border: 0;
    background: none;
    cursor: pointer;
    text-indent: -999em;
    border-radius: 0 2px 2px 0;
    display: block;
    line-height: 44px;
    height: 44px;
    width: 44px;
    font-size: 24px;
    overflow: hidden
}

#search-form #search .search-btn:hover, #search-form #search .search-btn:focus {
    background-color: #19a0d4;
    color: #fff;
    outline: 0;
    box-shadow: none
}

#search-form #search .search-btn:focus {
    box-shadow: 0 0 4px #fff
}

#search-form #search .search-btn:before {
    position: absolute;
    top: 0;
    left: 12px;
    font-family: "Streamline";
    font-weight: normal;
    font-style: normal;
    text-decoration: inherit;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\e01f";
    text-indent: 0
}

.suggestedSearchContainer {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border: 1px solid #c2c0c6;
    position: relative;
    margin-top: 10px;
    padding: 10px;
    width: 100%;
    left: 0;
    right: 0;
    display: none;
    clear: both;
    background-color: #fff
}

.suggestedSearchContainer ul {
    margin: 0 0 5px;
    padding: 0
}

.suggestedSearchContainer.serp-suggested {
    top: -5px;
    z-index: 50
}

.suggestedSearchContainer .media {
    margin: 10px;
    margin: 5px 0
}

.suggestedSearchContainer .media .img {
    float: left;
    margin-right: 10px
}

.suggestedSearchContainer .media .img img {
    display: block
}

.suggestedSearchContainer .media img {
    max-width: 40px;
    height: auto
}

.suggestedSearchContainer strong {
    padding: 3px 0 3px 3px;
    color: #333;
    display: block;
    font-size: 0.9375em;
    font-weight: bold
}

.suggestedSearchContainer .commonSearchItem, .suggestedSearchContainer .suggestedResult {
    list-style-type: none;
    padding: 4px 10px 4px 5px
}

.suggestedSearchContainer .commonSearchItem:hover, .suggestedSearchContainer .commonSearchItem.selected, .suggestedSearchContainer .suggestedResult:hover, .suggestedSearchContainer .suggestedResult.selected {
    background-color: #558dad;
    color: #fff;
    font-weight: bold
}

.suggestedSearchContainer .commonSearchItem:hover, .suggestedSearchContainer .suggestedResult:hover {
    cursor: pointer
}

.suggestedSearchContainer .commonSearchItem.hide, .suggestedSearchContainer .suggestedResult.hide {
    height: 0;
    padding: 0
}

.suggestedSearchContainer .commonSearchItem.hide.selected, .suggestedSearchContainer .suggestedResult.hide.selected {
    background-color: transparent
}

.suggestedSearchContainer .commonSearchItem {
    color: #037fb0
}

.suggestedSearchContainer .suggestedResult a:hover, .suggestedSearchContainer .suggestedResult a:focus {
    text-decoration: none
}

.suggestedSearchContainer .suggestedResult h2 {
    font-size: 1em;
    color: #004977
}

.suggestedSearchContainer .suggestedResult p.suggestedDesc {
    font-size: 0.6875em;
    margin-top: 0;
    color: #021829 !important
}

.suggestedSearchContainer .suggestedResult:hover h2, .suggestedSearchContainer .suggestedResult:hover p.suggestedDesc, .suggestedSearchContainer .suggestedResult.selected h2, .suggestedSearchContainer .suggestedResult.selected p.suggestedDesc {
    color: #fff !important
}

.suggestedSearchContainer .suggestedResult:hover p.suggestedDesc, .suggestedSearchContainer .suggestedResult.selected p.suggestedDesc {
    font-weight: normal
}

.search-box, .close-icon, .search-wrapper {
    position: relative
}

.close-icon.show, .close-icon.show:after, .close-icon:after {
    transition: opacity 0.4s ease-in-out;
    -webkit-transition: opacity 0.4s ease-in-out
}

.close-icon {
    border: 0;
    background-color: transparent;
    outline: 0;
    text-indent: -999em;
    opacity: 0;
    visibility: hidden;
    margin-right: -30px;
    position: relative;
    top: -42px;
    right: 40px;
    cursor: pointer;
    padding: 10px;
    float: right;
    width: 30px
}

@media (min-width: 60em) {
    .close-icon {
        top: -2px;
        border: 0;
        right: 55px;
        float: none
    }
}

@media (min-width: 48em) {
    .close-icon {
        top: -2px;
        border: 0;
        right: 55px;
        float: none
    }
}

.close-icon:focus:after {
    box-shadow: 0 0 4px #1291f3
}

.close-icon.show {
    opacity: 1;
    visibility: visible
}

.close-icon.show:after {
    opacity: 1;
    visibility: visible
}

.close-icon:after {
    content: "\e306";
    font-family: "Streamline";
    text-indent: 0;
    opacity: 0;
    visibility: hidden;
    font-size: 12px;
    position: absolute;
    top: 12px;
    right: 6px
}

.search-term {
    display: inline-block !important;
    width: 50%
}

.macontainer {
    background-color: #e6f5fb;
    padding: 20px 20px 10px;
    margin-bottom: 0.9375em
}

#capital-one-results ul {
    list-style-type: none;
    margin-left: 0
}

#ga-faq {
    margin-bottom: 1.25em;
    padding-top: 1.25em;
    border-width: 1px 0;
    border-style: solid;
    border-color: #e5e5e5
}

.url-link a {
    font-size: 0.875em
}

#search-term.question {
    display: inline-block;
    margin-right: 0.625em;
    width: 100%
}

@media (min-width: 60em) {
    #search-term.question {
        max-width: 62%
    }
}

@media (min-width: 48em) {
    #search-term.question {
        max-width: 62%
    }
}

#ga-feedback label {
    display: inline;
    padding-left: 5px
}

#ga-feedback #submit {
    margin-top: 10px
}

.error {
    color: #c00;
    font-weight: bold
}

#nav-audience {
    position: absolute;
    top: 0;
    width: 100%;
    padding: 0
}

@media (min-width: 48em) {
    #nav-audience {
        background-color: #303e4e;
        height: 48px;
        position: relative;
        left: 0
    }
}

#nav-audience .container {
    margin: 0
}

@media (min-width: 48em) {
    #nav-audience .container {
        margin: 0 auto
    }
}

#nav-audience .audience-menu {
    left: 0;
    right: auto;
    background: #fff;
    line-height: 1.2;
    transition: all 0.6s ease-in-out;
    width: 66px
}

#nav-audience .audience-menu:before {
    content: "";
    background-image: url(../../img/icon/filled-nav-icons.png);
    background-size: 36px;
    background-position: -6px -42px;
    display: block;
    width: 2em;
    height: 2em;
    margin: 0 auto 0.33333em
}

@media (min-width: 48em) {
    #nav-audience .audience-menu {
        display: none
    }
}

#nav-audience a {
    color: #fff
}

#nav-audience #audience {
    list-style-type: none;
    display: none
}

@media (min-width: 48em) {
    #nav-audience #audience {
        display: block;
        position: absolute;
        z-index: 60;
        list-style-type: none;
        margin: 0;
        height: 4.5em;
        top: -72px;
        left: 175px;
        font-size: 14px
    }
    #nav-audience #audience li {
        display: block;
        float: left;
        padding: 0 14px
    }
    #nav-audience #audience li.active {
        background-image: url("data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0D%0A%3C%21--%20Generator%3A%20Adobe%20Illustrator%2016.0.4%2C%20SVG%20Export%20Plug-In%20.%20SVG%20Version%3A%206.00%20Build%200%29%20%20--%3E%0D%0A%3C%21DOCTYPE%20svg%20PUBLIC%20%22-//W3C//DTD%20SVG%201.1//EN%22%20%22http%3A//www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd%22%3E%0D%0A%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20x%3D%220px%22%20y%3D%220px%22%0D%0A%09%20width%3D%2250px%22%20height%3D%2225px%22%20viewBox%3D%220%200%2050%2025%22%20enable-background%3D%22new%200%200%2050%2025%22%20xml%3Aspace%3D%22preserve%22%3E%0D%0A%3Cpolygon%20fill%3D%22%23313F4F%22%20points%3D%220%2C25%2025%2C0%2050%2C25%20%22/%3E%0D%0A%3C/svg%3E%0D%0A");
        background-repeat: no-repeat;
        background-position: 50% 55px
    }
    #nav-audience #audience li a {
        display: block;
        line-height: 5.15em;
        position: relative;
        color: #727780
    }
    #nav-audience #audience li a:hover, #nav-audience #audience li a:focus {
        text-decoration: underline
    }
    #nav-audience #audience li a span {
        display: none
    }
    #nav-audience #audience li.personal a:before {
        content: "";
        background-position: -6px 138px
    }
    #nav-audience #audience li.business a:before {
        content: "";
        background-position: -6px 101px
    }
    #nav-audience #audience li.commercial a:before {
        content: "";
        background-position: -6px 64px
    }
    #nav-audience #audience li.active a {
        text-decoration: none
    }
    #nav-audience #audience li.active a:after {
        content: "";
        background-position: -6px 30px;
        position: absolute;
        left: auto;
        font-weight: 600
    }
    #nav-audience #audience li.active a:hover:after {
        opacity: 0.6
    }
}

@media (min-width: 60em) {
    #nav-audience #audience {
        top: -72px;
        left: 220px;
        font-size: 16px
    }
    #nav-audience #audience li {
        display: block;
        float: left;
        padding: 0 18px
    }
    #nav-audience #audience li a {
        line-height: 4.5em
    }
    #nav-audience #audience li.active {
        background-position: 50% 55px
    }
}

#nav-audience .primary {
    margin: 0;
    background-image: url(../../img/logo/capitalone-logo-white-2x-oasis.png);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center 1em;
    background-size: 112px;
    background-color: rgba(48, 62, 78, 0.975);
    list-style-type: none;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transform: translateY(-140%);
    -moz-transform: translateY(-140%);
    transform: translateY(-140%);
    -webkit-transition: -webkit-transform 1s ease-in-out;
    -moz-transition: -moz-transform 1s ease-in-out;
    transition: transform 1s ease-in-out;
    padding-top: 66px;
    width: 100%
}

@media (min-width: 48em) {
    #nav-audience .primary {
        padding-top: 0;
        background-image: none;
        -webkit-transition: none;
        -moz-transition: none;
        transition: none
    }
}

#nav-audience .primary .expand-super {
    display: none
}

#nav-audience .primary li {
    position: relative;
    margin-bottom: 1px
}

#nav-audience .primary li a {
    padding-left: 45px;
    display: block;
    background-color: rgba(38, 49, 62, 0.5)
}

@media (min-width: 48em) {
    #nav-audience .primary li a {
        background: none
    }
}

#nav-audience .primary li a:hover, #nav-audience .primary li a:focus {
    text-decoration: none
}

@media (min-width: 48em) {
    #nav-audience .primary li a:focus {
        text-decoration: underline
    }
    #nav-audience .primary li a:hover {
        text-decoration: none
    }
}

@media (min-width: 48em) {
    #nav-audience .primary li:focus a {
        text-decoration: underline
    }
}

#nav-audience .primary li ul {
    margin: 0;
    list-style-type: none
}

@media (min-width: 48em) {
    #nav-audience .primary li ul {
        display: block
    }
}

#nav-audience .primary li ul li a {
    line-height: 35px;
    text-transform: uppercase;
    font-size: 0.75em
}

#nav-audience .primary li ul li.hasChildren>a:before {
    font-family: "Streamline";
    font-weight: normal;
    font-style: normal;
    text-decoration: inherit;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    right: 16px;
    top: 16px;
    font-size: 0.8125em;
    content: "\e628";
    text-decoration: none !important
}

#nav-audience .primary li ul li.open>a:before {
    content: "\e629"
}

#nav-audience .primary li ul li ul {
    margin-bottom: 0.5em
}

#nav-audience .primary li ul li ul li a {
    text-transform: none;
    font-size: 0.875em
}

#nav-audience .primary li ul li ul li a:after {
    font-family: "Streamline";
    font-weight: normal;
    font-style: normal;
    text-decoration: inherit;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding-left: 4px;
    font-size: 0.5em;
    color: rgba(255, 255, 255, 0.5);
    content: "\e61d";
    text-decoration: none !important
}

#nav-audience .primary>li>a {
    line-height: 50px;
    font-size: 18px;
    text-transform: none;
    font-size: 20px;
    padding: 16px
}

#nav-audience .primary>li>a:after {
    font-family: "Streamline";
    font-weight: normal;
    font-style: normal;
    text-decoration: inherit;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: static;
    right: 0;
    top: 0;
    font-size: 0.75em;
    color: rgba(255, 255, 255, 0.5);
    content: "\e62b";
    text-decoration: none !important;
    display: inline;
    float: right
}

#nav-audience .primary>li ul li a {
    font-size: 16px;
    padding: 16px
}

#nav-audience .primary .column strong a {
    padding: 0;
    font-size: 1em
}

@media (min-width: 48em) {
    #nav-audience .primary .column strong {
        color: #fff;
        text-transform: uppercase;
        font-size: 1.0625em;
        display: block
    }
    #nav-audience .primary .column strong a:hover {
        text-decoration: underline
    }
}

@media (min-width: 48em) {
    #nav-audience .primary .column {
        float: left;
        margin: 0 8px;
        padding: 0px 8px 12px 8px;
        width: 18.5em
    }
    #nav-audience .primary .column a {
        padding: 0
    }
    #nav-audience .primary .column img {
        width: 100%
    }
    #nav-audience .primary .column strong, #nav-audience .primary .column h2 {
        font-weight: normal;
        font-size: 1.125em;
        font-family: "Optimist Bold", Arial, Helvetica, sans-serif;
        line-height: 1.3;
        letter-spacing: 0;
        color: #fff;
        text-transform: uppercase
    }
    #nav-audience .primary .column strong a, #nav-audience .primary .column h2 a {
        color: #fff;
        background: none;
        float: none
    }
    #nav-audience .primary .column strong a:hover, #nav-audience .primary .column h2 a:hover {
        text-decoration: underline
    }
}

@media (min-width: 60em) {
    #nav-audience .primary .column {
        width: 18.75em
    }
}

@media (min-width: 48em) {
    #nav-audience .primary .alpha {
        margin-left: 0
    }
    #nav-audience .primary .omega {
        margin-right: 0
    }
}

#nav-audience .primary .row {
    margin-bottom: 0
}

#nav-audience .primary .row img {
    display: none
}

@media (min-width: 48em) {
    #nav-audience .primary .row {
        display: none;
        padding: 20px;
        position: absolute;
        top: 0;
        background-color: #606a77;
        top: 48px;
        width: 41.25em;
        left: 0.625em
    }
    #nav-audience .primary .row a {
        background-image: none
    }
    #nav-audience .primary .row img {
        display: block
    }
}

@media (min-width: 60em) {
    #nav-audience .primary .row {
        max-width: 57.5em;
        left: 1.25em
    }
}

@media (min-width: 48em) {
    #nav-audience .primary {
        position: static;
        -webkit-transform: none;
        -moz-transform: none;
        transform: none;
        margin: 0;
        background-color: transparent;
        float: left;
        z-index: 55;
        left: auto
    }
    #nav-audience .primary .expand-super {
        position: absolute;
        top: 48px;
        background-color: #606a77;
        padding: 10px 25px
    }
    #nav-audience .primary .expand-super button {
        font-size: 13px;
        border: none;
        background-color: transparent;
        color: #fff
    }
    #nav-audience .primary .expand-super button:focus {
        outline: 1px dotted #fff;
        outline-offset: 3px
    }
    #nav-audience .primary>li {
        display: none;
        position: static
    }
    #nav-audience .primary>li.active {
        display: block
    }
    #nav-audience .primary>li>a {
        display: none
    }
    #nav-audience .primary>li ul {
        margin: 0
    }
    #nav-audience .primary>li ul li {
        display: block;
        position: static
    }
    #nav-audience .primary>li ul li.mobile-only {
        display: none
    }
    #nav-audience .primary>li ul li a {
        display: block;
        height: 48px;
        line-height: 48px;
        padding: 0 1.14286em;
        font-size: 14px;
        text-transform: none
    }
    #nav-audience .primary>li ul li a:hover, #nav-audience .primary>li ul li a:focus {
        color: #fff
    }
    #nav-audience .primary>li ul li.hasChildren>a {
        padding-right: 2.0625em;
        position: relative
    }
    #nav-audience .primary>li ul li.hasChildren>a:after {
        font-family: "Streamline";
        font-weight: normal;
        font-style: normal;
        text-decoration: inherit;
        speak: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "\e628";
        position: absolute;
        line-height: 1;
        top: 28px;
        right: 0;
        width: 2.22222em;
        margin-top: -1em;
        font-size: 1ex;
        font-weight: 400;
        text-decoration: none !important;
        opacity: 0.6
    }
    #nav-audience .primary>li ul li.hasChildren>a:before {
        content: ""
    }
    #nav-audience .primary>li ul li.hasChildren>a:hover:after {
        opacity: 0.8
    }
    #nav-audience .primary>li ul li.activeHover a {
        color: #fff
    }
    #nav-audience .primary>li ul li.activeHover a:after {
        opacity: 0.8
    }
    #nav-audience .primary>li ul li.activeHover>a {
        background-color: #606a77
    }
    #nav-audience .primary>li ul li ul {
        display: block;
        list-style-type: none;
        margin: 0
    }
    #nav-audience .primary>li ul li ul li {
        margin-right: 1px
    }
    #nav-audience .primary>li ul li ul li a {
        color: #fff;
        font-size: 16px
    }
    #nav-audience .primary>li ul li ul li a:hover, #nav-audience .primary>li ul li ul li a:focus {
        background-color: none;
        text-decoration: underline !important
    }
    #nav-audience .primary>li ul li ul li a:after {
        content: ""
    }
    #nav-audience .primary>li>ul>li {
        float: left
    }
}

@media (min-width: 60em) {
    #nav-audience .primary {
        margin: 0
    }
    #nav-audience .primary li ul li a {
        font-size: 1em
    }
    #nav-audience .primary li ul li a:after {
        width: 2.77778em !important
    }
}

#nav-audience .primary.on .audience-open {
    background-color: rgba(57, 74, 94, 0.5)
}

#nav-audience .primary.on .audience-open>a:after {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    margin-right: 8px
}

#nav-audience .primary.on>li>ul {
    position: relative;
    width: 100%;
    max-height: 0;
    transition: max-height 0.6s ease-out;
    overflow: hidden;
    visibility: hidden
}

#nav-audience .primary.on>li>ul.primary-open {
    visibility: visible;
    max-height: 1000px;
    transition: max-height 0.6s ease-in
}

#nav-audience .primary.on>li>ul.primary-open li a {
    padding-left: 45px
}

#nav-audience .primary.on>li>ul.primary-open li.non-mobile {
    display: none !important
}

#nav-audience .primary.on>li.exit>ul {
    display: none
}

#nav-audience .primary.on h2 {
    display: none
}

#nav-audience .primary.on [role="menubar"] {
    margin-bottom: 0
}

#nav-audience .primary.on .location a:after, #nav-audience .primary.on .contact a:after, #nav-audience .primary.on .search a:after {
    font-family: "Streamline";
    font-weight: normal;
    font-style: normal;
    text-decoration: inherit;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding-left: 10px;
    font-size: 0.66667em;
    color: rgba(255, 255, 255, 0.5);
    content: "\e61d";
    text-decoration: none !important;
    right: auto;
    float: none
}

#nav-audience .primary *, #nav-audience .primary *:after, #nav-audience .primary *:before {
    -webkit-transition: all 0.6s linear;
    transition: all 0.6s linear;
    opacity: 1
}

@media (min-width: 48em) {
    #nav-audience .primary *, #nav-audience .primary *:after, #nav-audience .primary *:before {
        -webkit-transition: none;
        transition: none
    }
}

#nav-audience .exit {
    display: none
}

#nav-audience .primary {
    display: none
}

@media (min-width: 48em) {
    #nav-audience .primary {
        display: block
    }
}

#nav-audience.open .audience-menu {
    z-index: 10000;
    background: transparent;
    color: #fff;
    transition: all 0.2s ease-in-out
}

#nav-audience.open .audience-menu:before {
    background-position: -6px -6px
}

#nav-audience.open .primary {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    transform: translateY(0);
    overflow-x: hidden
}

#nav-audience.open .primary>li {
    right: 0
}

#nav-audience.open .primary>li.is-hidden {
    right: 100%
}

#nav-audience.open .primary>li ul.is-hidden {
    display: none
}

#nav-audience.open .primary .primary-open .is-hidden {
    max-height: 0;
    margin-bottom: 0;
    padding: 0
}

#nav-audience.open .primary .primary-open .is-hidden a {
    max-height: 0;
    padding: 0;
    opacity: 0
}

#nav-audience.open .primary .primary-open .is-hidden ul li {
    max-height: 0;
    margin-bottom: 0
}

#nav-audience.open .primary .primary-open .is-hidden ul li a {
    max-height: 0;
    padding: 0;
    opacity: 0
}

#nav-audience.open .primary .hasChildren ul {
    margin-bottom: 0
}

#nav-audience.open .primary .hasChildren ul li {
    max-height: 0;
    margin-bottom: 0
}

#nav-audience.open .primary .hasChildren ul li a {
    max-height: 0;
    padding: 0;
    opacity: 0
}

#nav-audience.open .primary .hasChildren.open>a {
    padding-top: 1em;
    padding-bottom: 1em;
    font-size: 28px
}

#nav-audience.open .primary .hasChildren.open>a:before {
    top: 30px;
    transition-delay: 0;
    font-size: 20px
}

#nav-audience.open .primary .hasChildren.open ul li {
    max-height: 200px;
    margin-bottom: 1px
}

#nav-audience.open .primary .hasChildren.open ul li a {
    max-height: 200px;
    padding: 16px 16px 16px 45px;
    opacity: 1
}

.breadcrumbs, #nav-breadcrumb {
    display: none;
    margin: 0;
    padding: 0 10px
}

@media (min-width: 48em) {
    .breadcrumbs, #nav-breadcrumb {
        display: block
    }
}

@media (min-width: 60em) {
    .breadcrumbs, #nav-breadcrumb {
        padding: 10px 10px 0 20px
    }
}

.breadcrumbs li, #nav-breadcrumb li {
    display: inline;
    font-size: 0.75em
}

.breadcrumbs a, #nav-breadcrumb a {
    text-decoration: none
}

.breadcrumbs a:hover, #nav-breadcrumb a:hover {
    text-decoration: underline
}

.footer {
    background-color: #004977;
    color: #666;
    border-top: 1px solid #ddd;
    padding-bottom: 1.125em;
    margin-top: 1em
}

@media (min-width: 48em) {
    .footer {
        position: absolute;
        bottom: 0;
        height: 140px;
        width: 100%
    }
}

.footer:after {
    display: block;
    visibility: hidden;
    clear: both;
    height: 0;
    content: "."
}

.footer img {
    vertical-align: middle
}

.footer a {
    color: #1c67c0
}

.footer a+a {
    margin-left: 0.5em
}

.footer .social-media span {
    display: block
}

@media (min-width: 48em) {
    .footer .social-media span {
        padding-right: 10px;
        display: inline
    }
}

.footer .social-media a {
    display: block;
    width: 24px;
    height: 24px;
    background-image: url(../../img/icon/footer-logos.png@v=15.10.00);
    background-repeat: no-repeat;
    background-position: 0 0;
    text-indent: -999em;
    float: left;
    overflow: hidden;
    text-align: left
}

.footer .social-media a.twitter {
    background-position: 0 0
}

.footer .social-media a.facebook {
    background-position: 0 -30px
}

.footer .social-media a.youtube {
    background-position: 0 -60px
}

.footer .social-media a.linkedin {
    background-position: 0 -90px
}

.footer .social-media a.instagram {
    background-position: 0 -120px
}

@media (min-width: 48em) {
    .footer .social-media {
        margin-top: 0.875em;
        float: left
    }
}

@media (min-width: 60em) {
    .footer .social-media {
        float: right
    }
}

.footer .right {
    text-align: right
}

.footer .eight {
    float: left;
    margin-top: 1em;
    width: 50%
}

@media (min-width: 48em) {
    .footer .eight {
        width: 22.75em
    }
}

.footer .logo, .footer #fdic, .footer #ehl {
    background-image: url(../../img/icon/footer-logos.png@v=15.10.00);
    background-repeat: no-repeat;
    display: block;
    text-indent: -999em;
    overflow: hidden
}

.footer .logo {
    width: 95px;
    height: 35px;
    background-position: 0 -150px
}

@media (min-width: 48em) {
    .footer .logo {
        float: left;
        padding-right: 20px
    }
}

.footer .copyright {
    display: block;
    color: #ffff;
    font-size: 0.75em
}

@media (min-width: 48em) {
    .footer .copyright {
        float: left;
        padding-top: 25px
    }
}

.footer #fdic {
    width: 73px;
    height: 10px;
    background-position: 0 -200px;
    text-align: left;
    float: right
}

.footer #ehl {
    padding-right: 25px;
    height: 15px;
    background-position: right -220px;
    margin-top: 5px;
    font-size: 0.75em
}

@media (min-width: 48em) {
    .footer .container .three, .footer .container .thirteen {
        width: 46.75em
    }
}

@media (min-width: 60em) {
    .footer .row {
        padding: 0 0.625em
    }
     .footer .container .three {
        width: 9.75em
    }
    .footer .container .eight {
        width: 28.125em
    }
    .footer .container .thirteen {
        width: 46.5em
    }
}

.footer-nav li a {
    color: #444;
    display: block;
    padding: 10px 0;
    border-bottom: 1px solid #bbb
}

@media (min-width: 48em) {
    .footer-nav li a {
        border: 0;
        padding: 0;
        float: left
    }
}

@media (min-width: 48em) {
    .footer-nav li {
        display: block;
        float: left;
        padding-right: 1.0625em;
        position: relative;
        font-size: 0.6875em
    }
    .footer-nav li.nosubs {
        border-left: 1px solid #ccc;
        padding-left: 1.0625em
    }
}

.footer-nav li.footersubs {
    background-image: url(../../img/icon/global-icons.png);
    background-position: -293px -386px;
    background-repeat: no-repeat
}

@media (min-width: 48em) {
    .footer-nav li.footersubs {
        padding-right: 1em;
        background-position: -293px -396px
    }
}

.footer-nav li.footersubs a {
    color: #ffff;
    font-weight: bold
}

.footer-nav li.footersubs>a {
    padding-left: 0.75em
}

.footer-nav li.footersubs ul a {
    color: #fff;
    padding: 10px 1em;
    font-weight: normal;
    text-transform: none
}

@media (min-width: 48em) {
    .footer-nav li.footersubs ul a {
        white-space: nowrap;
        float: none;
        padding: 5px 0
    }
}

.footer-nav li.footersubs li {
    font-size: 1em
}

.footer-nav li .expand-footersubs {
    display: none;
    background-color: #ccc
}

@media (min-width: 48em) {
    .footer-nav li .expand-footersubs {
        border-radius: 5px;
        box-shadow: 0 0 5px rgba(50, 50, 50, 0.8);
        position: absolute;
        bottom: 22px;
        left: 10px;
        padding: 5px;
        background-color: #666
    }
    .footer-nav li .expand-footersubs a {
        white-space: nowrap;
        color: #fff;
        text-decoration: none
    }
}

.footer-nav li.sfHover {
    background-position: -293px -446px
}

@media (min-width: 48em) {
    .footer-nav li.sfHover {
        background-position: -293px -426px
    }
    .footer-nav li.sfHover ul {
        display: block
    }
}

.footer-nav ul {
    list-style-type: none;
    display: none;
    background-color: #333;
    margin: 0
}

@media (min-width: 48em) {
    .footer-nav ul {
        box-shadow: 0 0 5px rgba(50, 50, 50, 0.8);
        border-radius: 5px;
        position: absolute;
        bottom: 30px;
        left: -10px;
        background-color: #666;
        padding: 10px;
        z-index: 100
    }
    .footer-nav ul:after {
        background-image: url(../../img/icon/global-icons.png);
        background-position: 0 -400px;
        background-repeat: no-repeat;
        position: absolute;
        bottom: -13px;
        left: 17px;
        display: block;
        width: 20px;
        height: 13px;
        content: ""
    }
    .footer-nav ul li {
        float: none;
        padding: 0
    }
    .footer-nav ul li.supp {
        border-top: 1px solid #fff;
        margin-top: 5px
    }
}

#nav-lob {
    margin: 0;
    border: 1px solid #aaa;
    border-radius: 5px;
    box-shadow: 0 1px 2px #999
}

@media (min-width: 48em) {
    #nav-lob {
        position: relative;
        box-shadow: none;
        border: 0;
        padding: 0
    }
}

#nav-lob .lob-root {
    font-size: 0.9375em;
    font-family: "Optimist Bold", Arial, Helvetica, sans-serif;
    line-height: 1.3;
    letter-spacing: 0
}

@media (min-width: 48em) {
    #nav-lob .lob-root {
        padding: 4px 0 4px 20px;
        background-color: transparent;
        margin: 0;
        font-size: 1em
    }
}

#nav-lob .lob-root a {
    display: block;
    padding: 7px 10px;
    color: #333 !important;
    font-weight: bold;
    position: relative;
    vertical-align: middle
}

#nav-lob .lob-root a:after {
    font-family: "icomoon";
    font-weight: normal;
    font-style: normal;
    text-decoration: inherit;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\f078";
    position: absolute;
    color: #037fb0;
    top: 10px;
    right: 15px;
    text-decoration: none
}

@media (min-width: 48em) {
    #nav-lob .lob-root a {
        color: #555 !important;
        border: 0;
        padding: 0
    }
    #nav-lob .lob-root a:after {
        content: ""
    }
}

#nav-lob .lob-root a.open:after {
    content: ""
}

@media (min-width: 48em) {
    #nav-lob h2.on {
        position: relative
    }
    #nav-lob h2.on:before {
        font-family: "icomoon";
        font-weight: normal;
        font-style: normal;
        text-decoration: inherit;
        speak: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "\f054";
        position: absolute;
        top: 4px;
        left: 9px;
        color: #037fb0;
        font-size: 12px
    }
    #nav-lob h2.on a {
        color: #037fb0 !important
    }
}

#nav-lob ul {
    display: none;
    margin: 0;
    padding: 0;
    list-style-type: none
}

@media (min-width: 48em) {
    #nav-lob ul {
        display: block
    }
}

#nav-lob li {
    padding: 6px 0 6px 20px;
    border-top: 1px solid #efefef;
    font-size: 0.875em
}

#nav-lob li a {
    color: #767676
}

#nav-lob li a:hover {
    text-decoration: underline
}

#nav-lob li ul {
    display: none
}

#nav-lob li li {
    font-size: 1em
}

@media (min-width: 48em) {
    #nav-lob li {
        padding: 4px 0 4px 20px;
        border: 0
    }
}

#nav-lob li.opened>a {
    padding-bottom: 6px;
    display: inline-block
}

@media (min-width: 48em) {
    #nav-lob li.supp {
        border-top: 1px solid #ececec
    }
    #nav-lob li.opened a {
        font-weight: bold
    }
    #nav-lob li.opened ul a {
        font-weight: normal
    }
    #nav-lob li.opened ul li.on a {
        color: #037fb0;
        font-weight: bold
    }
    #nav-lob li.opened:before {
        content: "" !important
    }
    #nav-lob li.opened>a {
        color: #767676 !important
    }
    #nav-lob li.on {
        position: relative
    }
    #nav-lob li.on:before {
        font-family: "icomoon";
        font-weight: normal;
        font-style: normal;
        text-decoration: inherit;
        speak: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "\f054";
        position: absolute;
        top: 0.4em;
        left: 9px;
        color: #037fb0;
        font-size: 11px
    }
    #nav-lob li.on a {
        color: #037fb0;
        font-weight: bold
    }
    #nav-lob li.on ul, #nav-lob li.opened ul {
        display: block
    }
    #nav-lob li.on li a, #nav-lob li.opened li a {
        font-weight: normal;
        color: #767676
    }
    #nav-lob li.on li.product a, #nav-lob li.opened.default-opened a {
        font-weight: normal;
        color: #767676
    }
}

#skip-lobnav, #end-lobnav {
    display: none
}

@media (min-width: 48em) {
    #skip-lobnav, #end-lobnav {
        display: block
    }
}

.horizontal-nav {
    background-color: #004977;
    vertical-align: middle;
    position: relative;
    margin-bottom: 1em
}

@media (max-width: 47.9375em) {
    .horizontal-nav .container {
        margin-left: 0
    }
}

.horizontal-nav h2 {
    display: none;
    float: left;
    vertical-align: middle;
    line-height: 1;
    margin: 0;
    background-color: #f4f0f3;
    padding: 1.25em 0.9375em 1.25em 0.625em
}

.horizontal-nav h2.has-icon {
    display: block
}

@media (min-width: 48em) {
    .horizontal-nav h2 {
        display: block;
        padding: 1.41176em 1.17647em 1.41176em 0
    }
}

@media (min-width: 60em) {
    .horizontal-nav h2 {
        padding: 1.25em 1em 1.25em 0
    }
}

@media (min-width: 48em) {
    .horizontal-nav h2.img-logo {
        padding: 0.5em 1.25em 0.5em 0
    }
}

.horizontal-nav h2.img-logo img {
    height: 1.4375em
}

@media (min-width: 48em) {
    .horizontal-nav h2.img-logo img {
        height: 2.875em
    }
}

.horizontal-nav h2 .img-icon {
    vertical-align: top;
    height: 1.4375em
}

@media (min-width: 48em) {
    .horizontal-nav h2 .img-icon {
        height: 16px
    }
}

@media (max-width: 47.9375em) {
    .horizontal-nav h2 .vh-mobile {
        position: absolute !important;
        clip: rect(1px, 1px, 1px, 1px);
        padding: 0 !important;
        border: 0 !important;
        height: 1px !important;
        width: 1px !important;
        overflow: hidden
    }
}

.horizontal-nav h2 .icon:before {
    font-family: "Streamline";
    font-weight: normal;
    font-style: normal;
    text-decoration: inherit;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: inherit;
    vertical-align: baseline;
    font-size: 23px
}

@media (min-width: 48em) {
    .horizontal-nav h2 .icon:before {
        font-size: 16px
    }
}

@media (min-width: 48em) {
    .horizontal-nav h2:before {
        display: block;
        height: 100%;
        width: 100%;
        position: absolute;
        background-color: #f4f0f3;
        content: " ";
        top: 0;
        font-size: 16px;
        left: -47.375em
    }
}

@media (min-width: 60em) {
    .horizontal-nav h2:before {
        left: -58.75em
    }
}

.horizontal-nav .horizontal-nav-wrapper {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap
}

.horizontal-nav .horizontal-nav-wrapper:after {
    content: " ";
    display: block;
    width: 35px;
    height: 100%;
    position: absolute;
    top: 0;
    right: -10px
}

@media (min-width: 48em) {
    .horizontal-nav .horizontal-nav-wrapper:after {
        background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #004977 100%);
        right: 10px
    }
}

.horizontal-nav .horizontal-nav-wrapper>ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    font-size: 1.125em;
    line-height: 1
}

.horizontal-nav .horizontal-nav-wrapper>ul>li {
    display: inline-block
}

.horizontal-nav .horizontal-nav-wrapper>ul>li>a {
    display: block;
    color: #fff;
    padding: 1.375em 1em
}

@media (min-width: 48em) {
    .horizontal-nav .horizontal-nav-wrapper>ul>li>a {
        padding: 1.375em 1em 1.3125em
    }
}

@media (min-width: 60em) {
    .horizontal-nav .horizontal-nav-wrapper>ul>li>a {
        padding: 1.4375em 1em
    }
}

.horizontal-nav .horizontal-nav-wrapper>ul>li>a:focus, .horizontal-nav .horizontal-nav-wrapper>ul>li>a:hover {
    text-decoration: underline
}

@media (max-width: 47.9375em) {
    .horizontal-nav .horizontal-nav-wrapper>ul>li>a.on {
        border-bottom: 3px solid #fff;
        padding: 0.0125em 0;
        margin-left: 1em;
        margin-right: 1em
    }
}

@media (min-width: 48em) {
    .horizontal-nav .horizontal-nav-wrapper>ul>li>a.on:after {
        content: " ";
        display: block;
        position: absolute;
        bottom: -15px;
        width: 30px;
        height: 30px;
        transform: rotate(45deg);
        background-color: #004977;
        box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.25);
        z-index: -1
    }
}

.horizontal-nav .horizontal-nav-wrapper>ul>li.hasChildren>a {
    position: relative;
    padding-right: 2em
}

.horizontal-nav .horizontal-nav-wrapper>ul>li.hasChildren>a:before {
    font-family: "Streamline";
    font-weight: normal;
    font-style: normal;
    text-decoration: inherit;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\e628";
    font-size: 0.72222em;
    margin-left: 0.625em;
    padding-top: 2px;
    text-decoration: none !important;
    position: absolute;
    right: 0.75em
}

.horizontal-nav .horizontal-nav-wrapper>ul>li.hasChildren .row {
    display: none;
    padding: 1.5em 0 2em;
    width: 100%;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.25);
    background-color: #fff;
    position: absolute;
    left: 0;
    z-index: 999;
    font-size: 0.88889em
}

.horizontal-nav .horizontal-nav-wrapper>ul>li.hasChildren .row .hasImage {
    text-align: center
}

.horizontal-nav .horizontal-nav-wrapper>ul>li.hasChildren .row .hasImage img {
    display: block;
    margin: 0 auto 1em
}

.horizontal-nav .horizontal-nav-wrapper>ul>li.hasChildren .row a {
    color: #037fb0;
    padding: 0
}

.horizontal-nav .horizontal-nav-wrapper>ul>li.hasChildren .row ul {
    list-style-type: none;
    margin: 0 0 1em
}

.horizontal-nav .horizontal-nav-wrapper>ul>li.hasChildren .row li+li {
    margin-top: 10px
}

.horizontal-nav .horizontal-nav-wrapper>ul>li.hasChildren.activeHover>a {
    background-color: #fff;
    color: #037fb0
}

@media (min-width: 48em) {
    .horizontal-nav.stuck {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 999;
        box-shadow: 0 2px 3px rgba(0, 0, 0, 0.25)
    }
}

.horizontal-nav.deep-linked ul a.active:after {
    content: " ";
    display: block;
    position: absolute;
    bottom: -15px;
    width: 30px;
    height: 30px;
    transform: rotate(45deg);
    background-color: #004977;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.25);
    z-index: -1
}

.horizontal-nav.light {
    background-color: #f4f0f3
}

.horizontal-nav.light h2 {
    background-color: #004977;
    color: #fff
}

.horizontal-nav.light h2:before {
    background-color: #004977
}

.horizontal-nav.light ul a {
    color: #037fb0 !important
}

.horizontal-nav.light.deep-linked ul a.active:after {
    background-color: #f4f0f3
}

.horizontal-nav-placeholder {
    display: none
}

.horizontal-nav-placeholder.stuck {
    display: block;
    margin-bottom: 1em
}

.tab-nav {
    border: 1px solid #aaa;
    border-radius: 5px;
    box-shadow: 0 1px 2px #999
}

@media (min-width: 48em) {
    .tab-nav {
        border: 0;
        box-shadow: none;
        position: relative
    }
    .tab-nav.hasScroll:after {
        background-image: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, #fff 100%);
        background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #fff 100%);
        content: "";
        display: block;
        height: 100%;
        width: 30px;
        position: absolute;
        right: 0;
        top: 0
    }
}

.tab-nav .tab-container {
    display: none
}

@media (min-width: 48em) {
    .tab-nav .tab-container {
        display: block;
        height: 75px;
        overflow: auto;
        -webkit-overflow-scrolling: touch
    }
}

.tab-nav button {
    font-size: 15px;
    font-weight: bold;
    color: #333 !important;
    display: block;
    width: 100%;
    padding: 7px 10px;
    vertical-align: middle;
    text-align: left;
    background: transparent;
    border: 0;
    position: relative;
    cursor: pointer
}

.tab-nav button:after {
    font-family: "icomoon";
    font-weight: normal;
    font-style: normal;
    text-decoration: inherit;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\f078";
    position: absolute;
    color: #037fb0;
    top: 8px;
    right: 15px;
    text-decoration: none
}

.tab-nav button.open:after {
    content: "\f077"
}

@media (min-width: 48em) {
    .tab-nav button {
        display: none
    }
    .tab-nav button:after {
        content: ""
    }
}

.nav-product, #nav-product {
    margin: 0;
    list-style-type: none
}

@media (min-width: 48em) {
    .nav-product, #nav-product {
        padding-left: 1em;
        border-bottom: 1px solid #004977
    }
    .nav-product:after, #nav-product:after {
        display: block;
        visibility: hidden;
        clear: both;
        height: 0;
        content: " "
    }
}

.nav-product li, #nav-product li {
    border-bottom: 1px solid #efefef;
    font-weight: normal;
    font-size: 14px
}

.nav-product li a, #nav-product li a {
    padding: 5px 10px;
    display: block
}

@media (min-width: 48em) {
    .nav-product li, #nav-product li {
        line-height: 1;
        display: inline-block;
        background-color: #007cb2;
        color: #fff;
        cursor: pointer;
        border-radius: 5px 5px 0 0;
        border-bottom: 0;
        margin-top: 4px;
        font-size: 0.8125em
    }
    .nav-product li a, #nav-product li a {
        padding: 14px 10px 13px
    }
}

@media (min-width: 60em) {
    .nav-product li, #nav-product li {
        margin: 4px 1px 0;
        font-size: 1em
    }
    .nav-product li a, #nav-product li a {
        padding: 14px 20px 13px
    }
}

.nav-product li.last, #nav-product li.last {
    border: 0
}

.nav-product li.last a, #nav-product li.last a {
    padding-bottom: 20px
}

@media (min-width: 48em) {
    .nav-product li.last a, #nav-product li.last a {
        padding-bottom: 13px
    }
}

@media (min-width: 48em) {
    .nav-product li:hover, .nav-product li:focus, #nav-product li:hover, #nav-product li:focus {
        margin-top: 0
    }
    .nav-product li:hover a, .nav-product li:focus a, #nav-product li:hover a, #nav-product li:focus a {
        padding-top: 18px;
        transition: padding 0.1s ease-in-out
    }
    .nav-product li a, #nav-product li a {
        color: #fff
    }
}

@media (min-width: 48em) {
    .nav-product .on, #nav-product .on {
        background-color: #004977;
        color: #fff;
        position: relative
    }
    .nav-product .on:after, #nav-product .on:after {
        position: absolute;
        display: block;
        content: "";
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid #004977;
        bottom: -10px;
        left: 50%;
        margin-left: -10px
    }
}

.nav-product .focus, #nav-product .focus {
    text-decoration: underline
}

#specialty-links {
    padding: 5px 10px 20px;
    margin-top: -15px;
    border-top: 1px solid #efefef;
    font-size: 14px;
    font-weight: bold
}

@media (min-width: 48em) {
    #specialty-links {
        border: 0;
        margin: 0;
        float: right;
        margin-top: -27px;
        font-size: 12px
    }
}

.audience-tab {
    margin-bottom: 1em
}

.audience-tab:after {
    background-image: none;
    content: none
}

.audience-tab .tab-container {
    height: auto;
    overflow: none
}

@media (min-width: 48em) {
    .audience-tab .nav-product {
        background-image: none !important;
        border: 0;
        margin-top: 25px;
        padding-left: 0
    }
}

.audience-tab .nav-product li {
    font-size: 1em;
    font-weight: normal;
    border-bottom: 1px solid #ccc;
    border-radius: 3px;
    display: block;
    padding: 0
}

@media (min-width: 48em) {
    .audience-tab .nav-product li {
        border: 1px solid #ccc;
        float: left;
        width: 32.5%;
        margin-right: 0.5%;
        background-color: #fff;
        font-size: 1.375em;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box
    }
    .audience-tab .nav-product li.first {
        margin-left: 0.5%
    }
    .audience-tab .nav-product li:hover, .audience-tab .nav-product li:focus {
        transition: none;
        padding: 0;
        margin-top: 4px
    }
}

.audience-tab .nav-product li a {
    border-left: 0 solid #f8f9fb;
    border-right: 0 solid #a5b3c2;
    line-height: 1.125em;
    color: #4e4e4e;
    padding: 10px 16px;
    display: block
}

@media (min-width: 48em) {
    .audience-tab .nav-product li a {
        padding: 5px 16px
    }
}

.audience-tab .nav-product li a:hover {
    text-decoration: none;
    background-color: #e6eff6
}

.audience-tab .nav-product li.personal a:before {
    content: "\e3c0"
}

.audience-tab .nav-product li.business a:before {
    content: "\e37a"
}

.audience-tab .nav-product li.commercial a:before {
    content: "\e3b1"
}

.audience-tab .nav-product li a:before {
    font-family: "Streamline";
    font-weight: normal;
    font-style: normal;
    text-decoration: inherit;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.audience-tab .nav-product li.on {
    background-image: none
}

.audience-tab .nav-product li.on:after {
    content: none
}

.audience-tab .nav-product li.on a {
    border: 0;
    color: #4e4e4e;
    font-size: 1em;
    background-color: #e6eff6
}

.audience-tab .nav-product li.on a:hover {
    text-decoration: none;
    background-color: #008bc1;
    color: #fff
}

.verticalSlider {
    position: relative
}

.verticalSlider ul {
    margin: 0;
    padding: 0;
    max-height: 225px;
    overflow-y: hidden;
    transition: max-height 0.5s
}

.verticalSlider ul:hover, .verticalSlider ul.display-all {
    max-height: 1000px;
    transition: max-height 0.7s
}

.verticalSlider .vertical-slider-arrow {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    position: absolute;
    bottom: -2em;
    right: 0;
    background-image: -webkit-linear-gradient(to bottom, #efefef 10%, #f8f8f8 20%);
    background-image: linear-gradient(to bottom, #efefef 10%, #f8f8f8 20%);
    display: block;
    cursor: pointer;
    height: 2em;
    width: 100%;
    padding-top: 0.25em
}

.verticalSlider .vertical-slider-arrow .acc-expand-nav {
    width: 1.25em;
    font-size: 1.25em;
    margin: 0 auto;
    display: block;
    background: none;
    border: 0
}

.verticalSlider li {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    list-style-type: none;
    position: relative;
    padding-bottom: 5px;
    opacity: 1;
    height: 45px
}

.verticalSlider li.selected {
    transition: opacity 0.1s ease-in, height 0.2s ease-in 0.1s;
    opacity: 0;
    height: 0
}

.verticalSlider li img {
    height: 35px;
    width: auto;
    position: relative;
    top: 5px
}

.verticalSlider li a {
    color: #262626;
    display: table-row
}

.verticalSlider li a:hover {
    cursor: pointer
}

.verticalSlider li a, .verticalSlider li.active-product {
    width: 260px
}

.verticalSlider li a .bd, .verticalSlider li a .img, .verticalSlider li.active-product .bd, .verticalSlider li.active-product .img {
    display: table-cell;
    vertical-align: middle
}

.verticalSlider li a .img, .verticalSlider li.active-product .img {
    width: 15%;
    max-width: 55px;
    position: relative;
    top: -2px
}

.verticalSlider li a .bd, .verticalSlider li.active-product .bd {
    width: 85%;
    max-width: 210px;
    padding-left: 0.5em;
    font-size: 0.75em
}

.verticalSlider li.active-product {
    display: block;
    padding: 2px 0 5px;
    margin: 10px 0;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    opacity: 1
}

.rate, .rate-2, .table-rate {
    font-weight: bold;
    font-size: 1.125em
}

.rate {
    color: #018901
}

.rate-2, .table-rate {
    color: #004977
}

.rate-xlarge {
    color: #018901;
    font-size: 2.25em
}

.no-style-rates .rate, .no-style-rates .rate-2 {
    color: inherit;
    font-size: inherit;
    font-weight: inherit
}

#socialMediaBar .fb_iframe_widget {
    vertical-align: super
}

#footnotes {
    margin-top: 1em;
    width: 100%;
    color: #767676
}

#footnotes .columns {
    border-top: 1px solid #dad8db;
    padding-top: 10px
}

#footnotes .footnote_content_wrapper {
    font-size: 0.75em;
    overflow: hidden
}

.sectioned #footnotes {
    margin: 1em 10px 0;
    width: auto
}

@media (min-width: 48em) {
    .sectioned #footnotes {
        width: 48em;
        margin: 1em auto 0
    }
}

@media (min-width: 60em) {
    .sectioned #footnotes {
        width: 60em
    }
}

.fn-dis {
    float: left;
    font-weight: bold;
    margin-right: 0.5em
}

.fn-dis sup {
    font-size: 0.625em
}

.ratings-stars {
    margin-bottom: 1em;
    padding-left: 10px
}

@media (min-width: 48em) {
    .ratings-stars {
        line-height: 10px
    }
}

.product-details-banner .ratings-stars, .ratings-stars.no-stars {
    line-height: 24px
}

.ratings-stars.no-stars {
    position: relative;
    top: -3px
}

.ratings-stars.no-stars.center .stars {
    position: relative;
    top: 2px;
    margin: 0 auto 5px
}

.no-reviews {
    color: #767676;
    position: relative;
    bottom: 6px;
    left: 5px
}

.stars {
    background-position: 0 -16px;
    margin-bottom: 5px;
    overflow: hidden;
    width: 80px
}

.stars, .stars span {
    background-image: url("../../img/icon/stars_lg.png");
    background-size: 16px 48px;
    display: block;
    height: 16px;
    text-indent: -999em
}

.stars span {
    float: left
}

.st-medium .stars, .st-medium .stars span {
    background-size: 20px 60px;
    height: 20px
}

.st-large .stars, .st-large .stars span {
    background-size: 24px 72px;
    height: 24px
}

.st-medium .stars {
    background-position: 0 -20px;
    width: 100px
}

.st-large .stars {
    background-position: 0 -24px;
    width: 120px
}

.no-stars .stars, .no-stars .stars span {
    background-position: 0 bottom
}

.st-inline:after {
    display: block;
    visibility: hidden;
    clear: both;
    height: 0;
    content: "."
}

.st-inline .stars {
    float: left;
    margin-right: 10px
}

.st-medium a, .st-medium .no-reviews {
    line-height: 20px
}

.st-large a, .st-large .no-reviews {
    line-height: 24px
}

@media screen and (-webkit-device-pixel-ratio: 2.2), screen and (-webkit-device-pixel-ratio: 1.8), screen and (-webkit-device-pixel-ratio: 1.1), screen and (-webkit-device-pixel-ratio: 0.9) {
    .stars {
        width: 78px !important
    }
    .st-medium .stars {
        width: 100px !important
    }
    .st-large .stars {
        width: 116px !important
    }
}

@media (min-width: 48em) {
    .BVRRCount, .BVRRCount span {
        font-weight: normal;
        display: inline;
        font-size: 12px;
        border: 0;
        background-image: none;
        background-color: transparent
    }
}

.BVRRQuickTakeSummary .BVRRPrimaryRatingSummary {
    height: auto !important
}

@media (min-width: 48em) {
    .BVRRQuickTakeSummary .BVRRPrimaryRatingSummary {
        height: 165px !important
    }
}

.BVRRRatingSummary .BVRROverallRatingContainer .BVRRRatingNormalImage {
    margin-bottom: 0 !important
}

@media (min-width: 48em) {
    .BVRRRatingSummary .BVRROverallRatingContainer .BVRRRatingNormalImage {
        margin-bottom: 20px !important
    }
}

.BVRRRatingsHistogramButton {
    padding-left: 0 !important
}

@media (min-width: 48em) {
    .BVRRRatingsHistogramButton {
        padding-left: 8px !important
    }
}

.BVRRRatingsHistogramButtonPopin {
    z-index: 40 !important;
    position: inherit !important
}

@media (min-width: 48em) {
    .BVRRRatingsHistogramButtonPopin {
        position: absolute !important
    }
}

.BVRRContainer .BVDI_QTSummaryBox .BVRRBuyAgainContainer {
    position: inherit !important;
    margin-bottom: 10px
}

@media (min-width: 48em) {
    .BVRRContainer .BVDI_QTSummaryBox .BVRRBuyAgainContainer {
        position: absolute !important;
        margin-bottom: 0;
        width: 160px !important
    }
}

.BVRRReviewDisplayStyle3Summary {
    padding: 10px 10px 10px 0 !important;
    float: none !important
}

@media (min-width: 48em) {
    .BVRRReviewDisplayStyle3Summary {
        padding-left: 10px !important;
        float: left !important
    }
}

.BVRRReviewDisplayStyle3Summary .BVRRRecommendedContainer {
    float: none !important
}

@media (min-width: 48em) {
    .BVRRReviewDisplayStyle3Summary .BVRRRecommendedContainer {
        float: left !important
    }
}

.BVRRRootElement select {
    clear: both
}

.BVRRReviewDisplayStyle3Main {
    margin-left: 0 !important
}

@media (min-width: 48em) {
    .BVRRReviewDisplayStyle3Main {
        margin-left: 200px !important
    }
}

@media (min-width: 48em) {
    .BVRRPrimarySummary .BVRRRatingOverall .BVRRRatingNormalImage {
        margin-bottom: 0 !important
    }
}

.cchp-rr {
    text-align: center
}

.cchp-rr .stars {
    margin-left: auto;
    margin-right: auto
}

.quick-links {
    position: relative
}

.quick-links ul {
    margin: 5px -10px 1em 0;
    padding: 10px 0 0
}

.quick-links li {
    padding: 0 0 10px 25px;
    background-image: url(../../img/icon/quick-links.gif);
    background-repeat: no-repeat;
    list-style: none;
    font-size: 0.9375em;
    line-height: 1.2
}

.quick-links .icon-alert {
    margin-top: -5px;
    margin-left: -5px;
    padding: 5px 0;
    background-color: #edf9fc;
    background-position: 5px 5px;
    font-weight: bold
}

.quick-links .icon-alert a {
    padding-left: 30px !important
}

.quick-links .icon-news {
    background-position: 0 -110px
}

.quick-links .icon-venture-card {
    background-position: 0 -160px
}

.quick-links .icon-marketing-card {
    background-position: 0 -240px
}

.quick-links .icon-marketing-faq {
    background-position: 0 -290px
}

.quick-links .icon-marketing-auto-loan {
    background-position: 0 -340px
}

.quick-links .icon-marketing-home-loan {
    background-position: 0 -390px
}

.quick-links .icon-marketing-accounts {
    background-position: 0 -440px
}

.quick-links .icon-marketing-compare-checking {
    background-position: 0 -490px
}

.quick-links .icon-marketing-pre-qualified {
    background-position: 0 -540px
}

.quick-links .icon-marketing-mail-offer {
    background-position: 0 -590px
}

.quick-links .icon-marketing-switch {
    background-position: 0 -640px
}

.quick-links .icon-marketing-cd {
    background-position: 0 -690px
}

.quick-links .icon-marketing-calculator {
    background-position: 0 -740px
}

.quick-links .icon-marketing-rates {
    background-position: 0 -790px
}

.quick-links .icon-marketing-search {
    background-position: 0 -840px
}

.quick-links .icon-marketing-check {
    background-position: 0 -890px
}

.quick-links .icon-servicing-rewards {
    background-position: 0 -970px
}

.quick-links .icon-servicing-transfer {
    background-position: 0 -1020px
}

.quick-links .icon-servicing-order-checks {
    background-position: 0 -1070px
}

.quick-links .icon-servicing-update-address {
    background-position: 0 -1120px
}

.quick-links .icon-servicing-alerts {
    background-position: 0 -1170px
}

.quick-links .icon-servicing-online-banking {
    background-position: 0 -1220px
}

.quick-links .icon-servicing-mobile {
    background-position: 0 -1270px
}

.quick-links .icon-servicing-pay-online {
    background-position: 0 -1320px
}

.quick-links .icon-servicing-paperless {
    background-position: 0 -1370px
}

.quick-links .icon-general-fdic {
    background-position: 0 -1510px
}

.quick-links .icon-general-security {
    background-position: 0 -1560px
}

.quick-links .icon-general-cap-one {
    background-position: 0 -1610px
}

.quick-links .icon-general-find {
    background-position: 0 -1660px
}

.quick-links .icon-general-contact {
    background-position: 0 -1710px
}

.quick-links .icon-general-search {
    background-position: 0 -1760px
}

.quick-links .icon-general-identity-theft {
    background-position: 0 -1810px
}

.quick-links .icon-general-support {
    background-position: 0 -1860px
}

.top-requests a {
    font-weight: bold
}

.faq {
    margin-left: 0;
    padding-left: 25px
}

.faq li {
    margin-bottom: 5px
}

.faq li dd {
    display: none
}

.faq li dt a {
    cursor: pointer;
    padding: 5px 0;
    display: block
}

@media (min-width: 48em) {
    .faq li dt a {
        padding: 0;
        display: inline
    }
}

.no-js .faq li dd {
    display: block
}

.ie7 .faq {
    margin-left: 15px
}

.ie7 .faq dl {
    display: inline-block
}

.accordion>h2 {
    background: #00497e;
    display: block;
    padding: 6px 5px;
    color: #fff;
    margin-top: 10px;
    clear: both;
    cursor: pointer
}

.accordion>h2 span {
    display: block;
    padding-left: 20px;
    background-image: url(../../img/icon/accordion-expand-collapse.gif);
    background-position: -5px 1px;
    background-repeat: no-repeat;
    color: #fff;
    text-decoration: none
}

.accordion>h2.open {
    background: #f6f6f6
}

.accordion>h2.open span {
    color: #246fc8;
    background-position: -5px -32px
}

.independent>h2.open span {
    background-position: -5px -248px !important
}

.centered-wide>h2 span {
    text-align: center;
    background-image: none;
    padding-left: 0;
    padding-right: 20px
}

.centered-wide>h2 span:before {
    background-image: url("../../img/icon/accordion-expand-collapse.gif");
    background-repeat: no-repeat;
    background-position: -5px -3px;
    content: "";
    display: inline-block;
    height: 15px;
    width: 20px
}

.centered-wide>h2.open span:before {
    background-position: -5px -252px
}

.centered-wide.icon-chevron>h2 span:before {
    font-family: "Streamline";
    font-weight: normal;
    font-style: normal;
    text-decoration: inherit;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: none;
    content: "\e628";
    height: auto;
    width: auto;
    position: relative;
    top: 1px;
    left: -5px;
    font-size: 0.9em
}

.centered-wide.icon-chevron>h2.open span:before {
    content: "\e629"
}

.accordion-content {
    display: none;
    padding: 0 5px 15px;
    overflow: hidden;
    clear: both
}

.no-js .accordion-content {
    display: block
}

.accordion-content h2 {
    background: none;
    color: #222;
    margin-bottom: 5px;
    padding: 0
}

.accordion-content h3 {
    margin-bottom: 0
}

.accordion-content p {
    margin: 0 0 0.5625em
}

.hasaccordion {
    position: relative
}

@media (min-width: 48em) {
    .hasaccordion .accordion {
        position: absolute;
        top: 0;
        right: 0.5em;
        overflow: hidden;
        padding: 4px;
        width: 155px;
        height: 260px;
        border: 1px solid #6689a9;
        background-color: #fff
    }
    .hasaccordion .accordion h2 {
        padding: 2px 5px;
        border: 1px solid #004977;
        background-image: url(../../img/bg/accordion-heading.gif);
        background-repeat: repeat-x;
        font-size: 1em;
        font-weight: normal
    }
    .hasaccordion .accordion h2 a {
        padding-left: 18px;
        background-position: -5px -5px
    }
    .hasaccordion .accordion h2.open {
        border-color: #555;
        background-position: bottom
    }
    .hasaccordion .accordion h2.open a {
        background-position: -5px -35px
    }
}

@media (min-width: 60em) {
    .hasaccordion .accordion {
        right: 1em;
        padding: 9px;
        width: 180px;
        height: 310px
    }
    .hasaccordion .accordion h2 {
        font-size: 0.875em
    }
    .hasaccordion .accordion h2 span {
        background-position: -5px -4px
    }
    .hasaccordion .accordion h2.open span {
        background-position: -5px -34px
    }
}

@media (min-width: 48em) {
    .hasaccordion .accordion-content {
        margin-bottom: 5px;
        height: 180px;
        padding: 0;
        background-image: url(../../img/bg/accordion-section-btm.gif);
        background-position: bottom;
        background-repeat: repeat-x
    }
    .hasaccordion .accordion-content h3, .hasaccordion .accordion-content p {
        font-size: 0.6875em
    }
}

@media (min-width: 60em) {
    .hasaccordion .accordion-content {
        margin-bottom: 5px;
        padding: 0 5px;
        height: 222px
    }
    .hasaccordion .accordion-content h3, .hasaccordion .accordion-content p {
        font-size: 1em
    }
}

.ie .centered-wide>h2 span:before {
    content: "x";
    overflow: hidden;
    text-indent: -9999px
}

.ie .centered-wide>h2.open span:before {
    content: "y"
}

.tab-panel {
    min-height: 300px;
    position: relative
}

.tab-panel.hasScroll:after {
    background-image: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, #fff 100%);
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #fff 100%);
    content: "";
    display: block;
    height: 65px;
    width: 30px;
    position: absolute;
    right: 0;
    top: 0
}

.tab-panel .tabs-container {
    height: 75px;
    overflow: auto;
    -webkit-overflow-scrolling: touch
}

.tab-panel .tabs {
    margin: 0;
    padding-left: 1em;
    list-style-type: none;
    border-bottom: 1px solid #004977
}

.tab-panel .tabs:after {
    display: block;
    visibility: hidden;
    clear: both;
    height: 0;
    content: " "
}

.tab-panel .tabs li {
    line-height: 1;
    padding: 14px 10px 13px;
    display: inline-block;
    background-color: #007cb2;
    color: #fff;
    cursor: pointer;
    border-radius: 5px 5px 0 0;
    margin-top: 4px
}

@media (min-width: 60em) {
    .tab-panel .tabs li {
        margin: 4px 1px 0;
        padding: 14px 20px 13px
    }
}

.tab-panel .tabs li:hover {
    padding-top: 18px;
    margin-top: 0;
    transition: padding 0.1s ease-in-out
}

.tab-panel .tabs .selected {
    background-color: #004977;
    color: #fff;
    position: relative
}

.tab-panel .tabs .selected:after {
    position: absolute;
    display: block;
    content: "";
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #004977;
    bottom: -10px;
    left: 50%;
    margin-left: -10px
}

.tab-panel .tabs .focus {
    text-decoration: underline
}

.tab-panel .panel {
    padding-top: 2em
}

.tab-panel.push-up {
    margin-top: -4em
}

.tab-panel.push-up:after {
    content: unset;
    background: transparent
}

.tab-panel.push-up .tabs {
    border: 0
}

.tab-panel.push-up .tabs li {
    padding: 14px 10px
}

@media (min-width: 60em) {
    .tab-panel.push-up .tabs li {
        padding: 14px 20px
    }
}

.tab-panel.push-up .tabs .selected {
    background-color: #303e4e;
    color: #fff
}

.tab-panel.push-up .tabs .selected:after {
    content: unset;
    border: 0
}

.centered {
    margin: 0 auto;
    padding-left: 0;
    text-align: center
}

.has-bg:after {
    content: unset;
    background: transparent
}

.has-bg .tabs {
    border: 0
}

.has-bg .tabs li {
    padding: 14px 10px
}

@media (min-width: 60em) {
    .has-bg .tabs li {
        padding: 14px 20px
    }
}

.has-bg .tabs .selected {
    background-color: #fff;
    color: #004977
}

.has-bg .tabs .selected:after {
    content: unset;
    border: 0
}

.bg-alt2 .has-bg .tabs .selected {
    background-color: #303e4e;
    color: #fff
}

.responsive:after {
    content: unset;
    background: transparent
}

.responsive h2.tab {
    background-color: #efefef;
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    font-size: 1em;
    font-family: "Optimist", Arial, Helvetica, sans-serif;
    font-weight: bold;
    color: #021829;
    cursor: pointer
}

.responsive h2.tab:before {
    content: "\e62b";
    font-family: "Streamline";
    font-weight: normal;
    font-style: normal;
    text-decoration: inherit;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-right: 10px;
    vertical-align: top
}

.responsive h2.selected {
    color: #fff;
    background-color: #004977
}

.responsive h2.selected:before {
    content: "\e628"
}

.push-up .tab-panel {
    margin-top: -1em
}

.social-feeds .tabs {
    padding-left: 0;
    border-bottom: 0
}

.social-feeds .tabs .selected {
    background-color: #fff;
    color: #004977
}

.social-feeds .tabs .selected:after {
    content: none
}

.social-feeds .tabs .selected a {
    opacity: 1
}

.social-feeds .tabs li {
    background-color: #fff
}

.social-feeds .tabs li:focus {
    outline: solid
}

.social-feeds .tabs li:hover {
    outline: none
}

.social-feeds .tabs li:active {
    outline: none
}

.social-feeds .tab {
    background-color: #fff;
    color: #004977;
    padding: 24px 15px 14px 0
}

.social-feeds .tab a {
    opacity: 0.3;
    display: block;
    text-indent: -9999px;
    background-image: url("../../img/icon/social-feeds.png");
    background-repeat: no-repeat
}

.social-feeds .tab a.twitter-icon {
    width: 37px;
    height: 30px;
    background-position: -44px -5px
}

.social-feeds .tab a.facebook-icon {
    width: 29px;
    height: 29px;
    background-position: -5px -5px
}

.social-feeds .tab a.youtube-icon {
    width: 43px;
    height: 30px;
    background-position: -91px -5px
}

.social-feeds .panel {
    padding-top: 1em
}

.social-feeds .social-feeds-youtube {
    width: 100%
}

.social-feeds .tabs-container {
    overflow: hidden;
    height: 55px
}

.section8 {
    border-radius: 3px;
    background-image: none;
    margin-bottom: 1em;
    padding: 10px;
    border: 1px solid #dad8db
}

@media (min-width: 48em) {
    .section8 {
        padding: 10px 0
    }
}

.section8 h2 {
    float: left;
    margin: 0 10px 0 0;
    padding: 0 10px 0 0;
    background-repeat: no-repeat;
    color: #004977;
    font-size: 1.125em;
    line-height: 1
}

@media (min-width: 48em) {
    .section8 h2 {
        border-right: 0
    }
}

@media (min-width: 60em) {
    .section8 h2 {
        border-right: 1px solid #dad8db
    }
}

.section8 a {
    color: #1c67c0 !important
}

.section8 p {
    float: left;
    margin: 0;
    font-size: 0.875em;
    line-height: 1.8
}

.icon-house:before {
    font-family: "icomoon";
    font-weight: normal;
    font-style: normal;
    text-decoration: inherit;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\f015";
    margin-right: 10px;
    vertical-align: sub;
    font-size: 22px
}

.oasis-contact {
    background-color: #e6f5fb !important;
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 0.7em;
    padding: 0.9em 0 0.1em 1.2em
}

.oasis-contact p {
    margin: 0
}

#section-1 {
    display: block
}

.alert-container {
    position: relative;
    margin: 0 auto;
    padding: 0.8125em 0.625em 0.75em 2.5em
}

@media (min-width: 48em) {
    .alert-container {
        width: 43.625em
    }
}

@media (min-width: 60em) {
    .alert-container {
        width: 54.375em
    }
}

.alert-container:before {
    font-family: "Streamline";
    font-weight: normal;
    font-style: normal;
    text-decoration: inherit;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\e333";
    position: absolute;
    color: #fff;
    left: 0.55556em;
    top: 0.66667em;
    font-weight: bold;
    font-size: 1.125em
}

.alert {
    background-color: rgba(210, 47, 47, 0.85)
}

.alert-blue {
    background-color: #004977
}

.alert, .alert-blue {
    color: #fff;
    position: relative;
    left: 0;
    right: 0;
    height: auto;
    margin-top: -2em
}

@media (min-width: 48em) {
    .alert, .alert-blue {
        margin-top: -1.5em;
        line-height: 1.5625em
    }
}

@media (min-width: 60em) {
    .alert, .alert-blue {
        margin-top: -2em
    }
}

.alert p, .alert-blue p {
    padding: 0;
    margin: 0
}

.alert a, .alert-blue a {
    color: #fff;
    text-decoration: underline
}

.alert a:hover, .alert a:focus, .alert-blue a:hover, .alert-blue a:focus {
    text-decoration: none
}

.sign-in-overlay .overlay-header {
    text-align: center
}

.sign-in-overlay .overlay-header .capitalone-logo {
    top: -60px
}

@media (max-width: 47.9375em) {
    .sign-in-overlay .overlay-header .capitalone-logo {
        top: -12px
    }
    .sign-in-overlay .overlay-header .capitalone-logo .default-logo, .sign-in-overlay .overlay-header .capitalone-logo .bank-logo {
        left: 0
    }
}

.sign-in-overlay .overlay-header h2 {
    font-size: 1.5em;
    line-height: 1
}

.sign-in-overlay .overlay-header h2 span {
    font-size: 0.66667em;
    display: block;
    line-height: 1.5
}

.sign-in-overlay .overlay-header p {
    line-height: 1.15;
    font-size: 14px
}

.sign-in-overlay .overlay-header p a {
    display: inline-block
}

@media (min-width: 48em) {
    .sign-in-overlay .overlay-header {
        text-align: left
    }
    .sign-in-overlay .overlay-header .sign-in-icon {
        float: left;
        margin: 0 1em 0 0;
        width: 72px;
        height: 84px;
        background-position: 0 0;
        background-size: 72px
    }
    .sign-in-overlay .overlay-header h2 {
        font-size: 2em;
        font-weight: 500
    }
    .sign-in-overlay .overlay-header h2 span {
        font-weight: 500;
        font-size: 0.5em
    }
}

.mobile-hp-login, .mobile-product-dropdown {
    background-image: url(../../img/icon/global-icons.png);
    background-position: right -742px;
    background-repeat: no-repeat;
    display: block;
    border-radius: 0;
    margin: 0 0 0.5em;
    padding: 10px;
    border: 1px solid #aaa;
    font-size: 1.0625em;
    font-weight: bold;
    color: #333 !important;
    background-color: #fff;
    vertical-align: middle
}

.mobile-hp-login img, .mobile-product-dropdown img {
    vertical-align: middle;
    margin-right: 10px
}

@media (min-width: 48em) {
    .mobile-hp-login, .mobile-product-dropdown {
        display: none
    }
}

.mobile-product-dropdown {
    background-image: none
}

.mobile-product-dropdown:hover {
    cursor: pointer
}

.mobile-product-dropdown ul {
    list-style-type: none;
    margin-left: 0
}

.mobile-product-dropdown li {
    padding: 5px 0 0 15px
}

.mobile-product-dropdown>a:after {
    font-family: "icomoon";
    font-weight: normal;
    font-style: normal;
    text-decoration: inherit;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\f078";
    position: absolute;
    right: 10px
}

.mobile-product-dropdown.open>a:after {
    content: "\f077"
}

.log-in-badge {
    display: none;
    position: relative;
    width: auto;
    z-index: 31;
    box-shadow: none;
    padding: 16px 12px;
    margin: 0 !important;
    border: 1px solid #ababab;
    height: 283px;
    background-color: #fff
}

@media (max-width: 47.9375em) {
    .log-in-badge {
        display: none !important
    }
}

.log-in-badge * {
    margin: 0;
    border: 0;
    vertical-align: baseline;
    font-size: 14px
}

.log-in-badge h2 {
    line-height: 1.25em;
    margin-bottom: 12px;
    font-size: 1.125em;
    padding-left: 30px
}

.log-in-badge h2:before {
    margin: 0 auto;
    content: " ";
    display: inline-block;
    width: 24px;
    height: 30px;
    margin-right: 5px;
    position: absolute;
    top: 14px;
    left: 12px;
    background-size: 24px;
    background-image: url("../../img/icon/panel-icons.png");
    background-position: 0 0
}

.log-in-badge .signup-online-access {
    display: block;
    margin-top: 10px;
    padding: 0 10px;
    min-height: 20px;
    border: 1px solid #999;
    background-position: right -833px;
    background-repeat: no-repeat;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
    font-weight: bold;
    font-size: 0.9em;
    line-height: 20px;
    text-align: center
}

.log-in-badge .signup-online-access a:link {
    text-decoration: none
}

.log-in-badge .signup-online-access a:before {
    font-family: "icomoon";
    font-weight: normal;
    font-style: normal;
    text-decoration: inherit;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\f007";
    font-size: 0.9em;
    margin-right: 5px
}

.log-in-badge .signup-online-access a:after {
    font-family: "icomoon";
    font-weight: normal;
    font-style: normal;
    text-decoration: inherit;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\f101";
    font-size: 1.05em;
    margin-left: 5px
}

.log-in-badge .aria-help {
    position: absolute !important;
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0 !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden
}

.log-in-badge .auth-error {
    display: none;
    border-width: 1px;
    border-style: solid;
    border-color: #d22f2f;
    border-radius: 3px;
    box-shadow: 0 0 4px #ab363f;
    padding: 5px 10px !important;
    position: absolute;
    z-index: 50;
    left: 110%;
    width: 190px;
    background: #fff;
    font-size: 0.75em;
    margin-left: -20px;
    top: -8px
}

.log-in-badge .auth-error:before {
    content: url(../../img/icon/error-tail-left.png);
    left: -17px;
    margin-top: -14px;
    position: absolute;
    top: 50%;
    z-index: 91
}

.log-in-badge .auth-error p {
    margin: 0.2em 0
}

.log-in-badge .auth-error strong {
    font-size: 1.2em
}

.log-in-badge .select-account {
    position: relative;
    display: block;
    border-radius: 3px;
    background-color: #fff;
    background-image: none;
    border: 2px solid #c2c0c6;
    line-height: 1.5em;
    height: auto;
    margin-bottom: 16px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.log-in-badge .select-account legend span {
    position: absolute !important;
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0 !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden
}

.log-in-badge .select-account .active-account {
    display: block;
    width: 100%;
    font-size: 1em;
    font-family: "Optimist", Arial, Helvetica, sans-serif;
    font-weight: 400;
    text-align: left;
    line-height: 18px;
    cursor: pointer;
    background-color: #fff;
    background-image: none;
    color: #021829;
    padding: 0.625em;
    height: auto;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.log-in-badge .select-account .active-account em {
    margin-left: -2px
}

.log-in-badge .select-account .active-account:after {
    font-family: "Streamline";
    font-weight: normal;
    font-style: normal;
    text-decoration: inherit;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -moz-osx-font-smoothing: auto;
    -webkit-font-smoothing: auto;
    content: "\e628";
    position: absolute;
    right: 15px;
    font-size: 0.875em
}

.log-in-badge .select-account button:focus {
    box-shadow: 0 0 4px #1291f3
}

.log-in-badge .select-account a:hover, .log-in-badge .select-account a:focus {
    text-decoration: none
}

.log-in-badge .select-account .auth-default {
    display: none
}

.log-in-badge .select-account ul.account-types {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    position: absolute;
    z-index: 10;
    display: block;
    margin: 0;
    width: 100%;
    list-style-type: none;
    background-color: #fff;
    border: 2px solid #c2c0c6;
    top: 40px
}

.log-in-badge .select-account li {
    display: block;
    background-image: none
}

.log-in-badge .select-account li.off {
    display: none
}

.log-in-badge .select-account li label, .log-in-badge .select-account li a {
    display: block;
    box-shadow: none !important;
    font-weight: normal;
    font-size: 1em;
    cursor: pointer;
    margin-bottom: 0 !important;
    color: #021829;
    padding: 10px
}

.log-in-badge .select-account li input:focus label, .log-in-badge .select-account li a:focus, .log-in-badge .select-account li.sfhover label, .log-in-badge .select-account li.sfhover a {
    text-decoration: none;
    background-color: #19a0d4;
    color: #fff
}

.log-in-badge .select-account .active-account em, .log-in-badge .select-account label em {
    color: #767676;
    font-size: 0.8em;
    font-style: italic;
    font-weight: normal
}

.log-in-badge .select-account label:hover em, .log-in-badge .select-account .sfhover label em {
    color: #fff
}

.log-in-badge .select-account input {
    position: absolute !important;
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0 !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden
}

.log-in-badge .select-account .auth-error {
    left: 105%
}

.log-in-badge .full .auth-error {
    left: 105%
}

.log-in-badge fieldset {
    margin-top: 0 !important
}

.log-in-badge .region-select {
    position: absolute;
    top: 8px;
    right: 10px;
    list-style-type: none;
    display: none !important;
    margin-left: 0 !important
}

.log-in-badge .region-select legend, .log-in-badge .region-select span input {
    position: absolute;
    left: -999em
}

.log-in-badge .region-select span {
    display: inline
}

.log-in-badge .region-select span label {
    display: block;
    float: left;
    margin-left: 5px;
    width: 25px;
    height: 14px;
    background-image: url("../../img/icon/log-in-flags.png");
    background-position: bottom left;
    background-repeat: no-repeat;
    text-indent: -999em;
    cursor: pointer
}

.log-in-badge .region-select span.hasFocus label {
    box-shadow: 0 0 4px #1291f3;
    outline: 1px dotted #000;
    overflow: hidden
}

.log-in-badge .region-select.hasFocus span input {
    position: relative;
    left: 0;
    float: left;
    margin-left: 3px
}

.log-in-badge .region-select span.usa label {
    background-position: -50px bottom
}

.log-in-badge .region-select span.usa label.active {
    background-position: -50px top
}

.log-in-badge .region-select span.can label {
    background-position: 0 bottom
}

.log-in-badge .region-select span.can label.active {
    background-position: 0 top
}

.log-in-badge .region-select span.uk label {
    background-position: -25px bottom
}

.log-in-badge .region-select span.uk label.active {
    background-position: -25px top
}

.log-in-badge label {
    cursor: text
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    .log-in-badge .region-select.hasFocus {
        width: 138px
    }
}

.log-in-badge .auth {
    margin-top: 0
}

.log-in-badge .auth fieldset legend {
    position: absolute !important;
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0 !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden
}

.log-in-badge .auth fieldset.hl-type legend {
    position: relative;
    left: auto;
    border-bottom: 1px solid #9c9c9c;
    color: #333;
    font-size: 13px;
    font-weight: bold;
    line-height: 18px;
    margin-bottom: 8px;
    padding-bottom: 1px;
    width: 100%
}

.log-in-badge .auth fieldset.hl-type legend span {
    margin-bottom: 0
}

.log-in-badge .auth .field, .log-in-badge .sub-auth .field {
    position: relative;
    float: left;
    padding: 0;
    margin-top: 0 !important;
    width: 48%
}

.log-in-badge .auth .field input, .log-in-badge .sub-auth .field input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 100% !important;
    border-radius: 3px !important;
    background: #fff;
    border: 2px solid #c2c0c6 !important;
    color: #021829;
    font-family: "Optimist", Arial, Helvetica, sans-serif;
    font-weight: 400;
    font-size: 1em !important;
    height: auto !important;
    padding: 0.625em !important;
    margin-bottom: 16px !important
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 47.9375em) {
    .log-in-badge .auth .field input, .log-in-badge .sub-auth .field input {
        font-size: 16px !important
    }
}

.log-in-badge .auth .field input:focus, .log-in-badge .sub-auth .field input:focus {
    outline: 1px dotted #000;
    border-color: #19a0d4 !important;
    box-shadow: none
}

.log-in-badge .auth .field input.error-shadow, .log-in-badge .sub-auth .field input.error-shadow {
    box-shadow: none !important;
    border-color: #d22f2f !important
}

.log-in-badge .auth .field label, .log-in-badge .sub-auth .field label {
    position: absolute;
    left: 12px;
    top: 10px;
    font-weight: normal !important;
    color: #767676 !important
}

.log-in-badge .auth .isClear label, .log-in-badge .sub-auth .isClear label {
    left: -999em
}

.log-in-badge .auth .last, .log-in-badge .sub-auth .last {
    float: right
}

.log-in-badge .auth .hasFocus label, .log-in-badge .auth .hasInput label, .log-in-badge .sub-auth .hasFocus label, .log-in-badge .sub-auth .hasInput label {
    top: -999em
}

.log-in-badge .auth .full, .log-in-badge .sub-auth .full {
    float: none !important;
    width: 100% !important
}

.log-in-badge .auth-help p, .log-in-badge p.auth-help {
    margin-top: 0;
    float: left;
    display: block
}

.log-in-badge .auth-help p .enroll, .log-in-badge p.auth-help .enroll {
    width: 100%;
    margin-top: 1em
}

.log-in-badge .submit-btn {
    float: none;
    padding: 0.75em 1em;
    margin-bottom: 16px;
    width: 100%;
    clear: both
}

.log-in-badge .additional {
    text-align: center;
    position: absolute;
    bottom: 0;
    display: none !important
}

.log-in-badge .additional img {
    margin: 0 auto
}

.log-in-badge .additional p {
    margin: 0.4em 0;
    border-top: 1px solid #c4c4c4;
    padding-top: 5px
}

.log-in-badge .additional p a {
    padding: 3px 5px;
    border-right: 1px solid #c4c4c4;
    font-weight: bold;
    font-size: 0.85em
}

.log-in-badge .additional p a.privacy {
    background: none;
    padding: 3px 5px
}

.log-in-badge .additional p a.fraud {
    border: 0
}

.log-in-badge .hl-type span {
    display: block;
    width: 100%;
    min-height: 15px;
    margin-bottom: 5px
}

.log-in-badge .hl-type input {
    display: inline-block;
    float: left;
    width: 8%;
    margin-right: 2%
}

.log-in-badge .hl-type label {
    display: inline-block;
    float: left;
    cursor: default;
    overflow: hidden;
    width: 90%;
    font-size: 0.95em !important;
    font-weight: normal !important
}

.log-in-badge .error-shadow {
    outline-color: transparent;
    outline-style: none
}

.log-in-badge .option:hover {
    background: #f0f0f0;
    cursor: pointer;
    padding: 10px;
    margin-left: -10px;
    width: 100%
}

.log-in-badge .forgot-collision-popup {
    position: absolute;
    z-index: 99;
    top: 15px;
    left: 166px;
    background: #fff url(../../img/bg/input-bg.png) top repeat-x;
    width: 258px;
    border: 1px solid #999;
    padding: 10px 0 10px 10px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.6);
    border-radius: 3px
}

.log-in-badge .forgot-collision-popup:before {
    display: block;
    width: 17px;
    height: 28px;
    position: absolute;
    top: 50%;
    left: -17px;
    margin-top: -14px;
    content: url(../../img/icon/security-arrow-tail-left.png)
}

.log-in-badge .loader {
    display: none
}

@media (min-width: 48em) {
    .log-in-badge {
        margin: 1em 0 0
    }
}

@media (min-width: 60em) {
    .log-in-badge h2 {
        font-size: 1.4em
    }
    .log-in-badge .select-account .active-account, .log-in-badge .select-account li label, .log-in-badge .select-account li a {
        font-size: 1.2em !important
    }
    .log-in-badge .additional p a {
        font-size: 1em
    }
    .log-in-badge .additional p a.privacy {
        padding-left: 25px;
        background-image: url(../../img/icon/global-icons.png);
        background-position: 0 -700px;
        background-repeat: no-repeat
    }
}

.log-in-badge-bg {
    background-color: #e8e8e8;
    background-image: url("../../img/bg/row-stripe-texture.jpg")
}

.remember {
    display: none
}

.user-id::-ms-clear {
    display: none
}

.primaryNavLoginZindex {
    z-index: 52 !important
}

.remember-un {
    float: none;
    clear: both;
    margin-bottom: 16px
}

.remember-un label {
    display: inline !important;
    font-weight: normal !important;
    border-bottom: 1px dashed #bbb
}

#auth-default {
    display: none !important
}

#auth-default .submit-btn {
    cursor: pointer
}

#auth-default-error-user {
    top: -16px
}

#auth-home-equity .additional, #auth-home-equity .auth-help {
    display: none !important
}

#auth-credit-cards-us .auth-help, #auth-credit-cards-ca .auth-help, #auth-banking .auth-help, #auth-360 .auth-help {
    margin-top: 0;
    width: 100%
}

.auth-welcome {
    margin-left: -4px;
    float: left;
    display: block
}

.auth-welcome img {
    width: 100%
}

.log-in-overlay {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.35) 0, rgba(0, 0, 0, 0.35) 100%);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.35) 0, rgba(0, 0, 0, 0.35) 100%);
    z-index: 400
}

#page-body-wrapper .log-in-badge form div {
    margin: 0 !important
}

#page-body-wrapper .log-in-badge form div.remember-un {
    margin-bottom: 16px !important
}

.collision .option-divider {
    color: #fff;
    display: block;
    position: relative;
    text-align: center;
    z-index: 0;
    font-size: 10px;
    font-weight: bold
}

.collision .option-divider:before {
    border-top: 1px solid #ccc;
    content: " ";
    display: block;
    position: absolute;
    top: 50%;
    width: 100%;
    z-index: 10
}

.collision .option-divider:after {
    background-color: #fff;
    color: #9c9c9c;
    content: "OR";
    display: block;
    left: 50%;
    margin-left: -18px;
    padding: 0 10px;
    position: absolute;
    top: 0;
    z-index: 20
}

.collision .option {
    padding: 10px 0
}

.collision .option:after {
    clear: left;
    content: "";
    display: block
}

.collision .option img {
    height: auto !important;
    width: 100% !important;
    float: left
}

.collision .select {
    color: #4c4c4c;
    line-height: 18px;
    font-size: 13px;
    font-weight: bold
}

.mail-offer-badge, .signup-online-access, .log-in {
    display: none
}

@media (min-width: 60em) {
    .mail-offer-badge, .log-in {
        display: block
    }
    .mail-offer-badge {
        margin-top: 10px;
        padding: 5px 10px 6px;
        min-height: 30px;
        border: 1px solid #ababab;
        font-weight: normal;
        font-size: 1em;
        background-color: #fff;
        line-height: 30px
    }
    .mail-offer-badge a {
        padding-left: 36px
    }
    .mail-offer-badge a:before {
        font-family: "Streamline";
        font-weight: normal;
        font-style: normal;
        text-decoration: inherit;
        speak: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "\e125";
        font-size: 1.5em;
        position: absolute;
        left: 33px;
        margin-top: -1px
    }
    #auth-home-equity .auth-help {
        display: block !important
    }
    #auth-home-equity .submit-btn {
        margin-bottom: 5px
    }
    .signup-online-access {
        min-height: 30px;
        font-size: 1.05em;
        line-height: 30px
    }
}

#welcome-360>img {
    width: auto
}

.additional-default {
    position: absolute;
    bottom: 0
}

#RSAFsoDiv {
    position: absolute;
    top: 0
}

.badge-feature {
    min-height: 470px
}

.ui-slider {
    position: relative;
    text-align: left
}

.ui-slider .ui-slider-handle {
    position: absolute;
    z-index: 2;
    width: 1.2em;
    height: 1.2em;
    cursor: default;
    display: block
}

.ui-slider .ui-slider-range {
    position: absolute;
    z-index: 1;
    font-size: 0.7em;
    display: block;
    border: 0;
    background-position: 0 0
}

.ui-slider-horizontal {
    height: 0.8em
}

.ui-slider-horizontal .ui-slider-handle {
    top: -0.3em;
    margin-left: -0.6em
}

.ui-slider-horizontal .ui-slider-range {
    top: 0;
    height: 100%
}

.ui-slider-horizontal .ui-slider-range-min {
    left: 0
}

.ui-slider-horizontal .ui-slider-range-max {
    right: 0
}

.ui-slider-vertical {
    width: 0.8em;
    height: 100px
}

.ui-slider-vertical .ui-slider-handle {
    left: -0.3em;
    margin-left: 0;
    margin-bottom: -0.6em
}

.ui-slider-vertical .ui-slider-range {
    left: 0;
    width: 100%
}

.ui-slider-vertical .ui-slider-range-min {
    bottom: 0
}

.ui-slider-vertical .ui-slider-range-max {
    top: 0
}

.slider-container-outer div[class*="rewards-header"] {
    text-align: right
}

.slider-container-outer .icon-checkmark:before {
    content: "\f00c";
    font-size: 0.875em;
    color: #fff
}

.slider-container-outer .left-box {
    display: inline-block;
    width: auto
}

.slider-container-outer .left-box.mobile-full-width {
    width: 100%
}

.slider-container-outer .small {
    font-size: 90%;
    font-weight: normal
}

.slider-container-outer .bd {
    padding-left: 17px;
    padding-bottom: 40px
}

@media (min-width: 60em) {
    .slider-container-outer .bd {
        padding-bottom: 30px
    }
}

.slider-container-outer .row-label {
    display: inline-block;
    padding-left: 0;
    text-align: right;
    float: right
}

.slider-container-outer .top-label {
    padding-top: 30px;
    position: relative
}

.slider-container-outer .top-label .calc-slider {
    position: relative
}

.slider-container-outer .top-label label {
    position: absolute;
    top: -30px
}

.slider-container-outer .top-label .spend-total {
    position: absolute;
    top: 30px;
    right: 0
}

.slider-container-outer.ver2 {
    position: relative;
    left: -10px
}

#page-body-wrapper .slider-container-outer.ver2 .slider-container-inner {
    float: left;
    width: 70%
}

@media (min-width: 48em) {
    #page-body-wrapper .slider-container-outer.ver2 .slider-container-inner {
        width: 82%
    }
}

.ie8 #page-body-wrapper .slider-container-outer.ver2 .slider-container-inner {
    width: 75%
}

.slider-container-outer.ver2 .left-box, .slider-container-outer.ver2 .right-box {
    padding-top: 9px;
    color: #767676;
    width: 7% !important
}

@media (max-width: 47.9375em) {
    .slider-container-outer.ver2 .left-box, .slider-container-outer.ver2 .right-box {
        margin-right: 5%
    }
}

.slider-container-outer.ver2 .left-box {
    float: left
}

.slider-container-outer.ver2 .right-box {
    float: right;
    text-align: right
}

.slider-container-outer.ver2 .calc-slider {
    background: #313e4d;
    border-radius: 0;
    height: 5px;
    max-width: 600px;
    margin-top: 15px
}

.slider-container-outer.ver2 .ui-slider-handle {
    background-image: none;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    position: relative;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
    margin: -10px 0 0 -15px
}

.slider-container-outer.ver2 .ui-slider-handle span {
    color: #fff;
    font-weight: bold;
    font-size: 22px;
    text-align: center;
    display: block
}

@media (min-width: 48em) {
    .slider-container-outer.ver2 .row-label {
        text-align: center;
        display: block;
        float: none;
        white-space: nowrap;
        overflow: visible
    }
}

.slider-container-outer.ver2 .green-slide .ui-slider-handle {
    background-color: #19a328
}

.slider-container-outer.ver2 .green-slide .ui-slider-range {
    background-image: -webkit-linear-gradient(to left, #19a328 5%, #0f6118);
    background-image: linear-gradient(to left, #19a328 5%, #0f6118);
    background-color: #19a328
}

.slider-container-outer.ver2 .blue-slide .ui-slider-handle {
    background-color: #037fb0
}

.slider-container-outer.ver2 .spend-total {
    font-weight: bold
}

.slider-container-outer.ver2 .spend-total .large.green {
    color: #19a328
}

.slider-container-outer.ver2 .spend-total .large.blue, #page-body-wrapper .slider-container-outer.ver2 .blue.input-label+#monthly-spend-total {
    color: #037fb0
}

.slider-container-outer.ver2 #annual-rewards, .slider-container-outer.ver2 .spend-total .large {
    font-size: 1.75em;
    position: relative;
    top: 3px;
    text-align: left;
    font-weight: bold
}

#page-body-wrapper .slider-container-outer.ver2 #monthly-spend-total {
    cursor: text;
    border: 1px solid #efefef;
    font-size: 1.45em;
    color: #19a328;
    background-image: none;
    display: inline-block;
    width: 75px;
    vertical-align: middle;
    margin-bottom: 0
}

@media (min-width: 48em) {
    #page-body-wrapper .slider-container-outer.ver2 #monthly-spend-total {
        width: 105px
    }
}

#page-body-wrapper .slider-container-outer.ver2 label {
    display: block;
    text-align: left;
    font-family: "Optimist", Arial, Helvetica, sans-serif
}

@media (min-width: 48em) {
    #page-body-wrapper .slider-container-outer.ver2 label {
        display: inline-block
    }
}

.slider-container-outer.ver2 .slider-container-outer.venture-rewards {
    width: 65%;
    margin: 0 auto
}

.slider-container-outer.ver2 .venture-planes {
    background: url(../../img/icon/plane-icon.png) 50% 50% no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

#page-body-wrapper .slider-container-inner {
    width: 75%;
    display: inline-block
}

#page-body-wrapper .slider-container-inner .desktop-row {
    margin: 0;
    line-height: 1em;
    display: block;
    position: relative;
    top: auto
}

@media (min-width: 60em) {
    #page-body-wrapper .slider-container-inner .desktop-row {
        display: inline-block;
        position: absolute;
        left: -20px;
        top: 11px
    }
}

#page-body-wrapper .slider-container-inner .spend-total .input-label {
    position: absolute;
    top: 4px;
    z-index: 1
}

#page-body-wrapper .slider-container-inner .spend-total input, #page-body-wrapper .slider-container-inner .spend-total .highlight-blue {
    display: inline-block;
    width: 60px
}

#page-body-wrapper .slider-container-inner input {
    position: absolute;
    right: 0;
    text-align: right;
    padding: 5px 2px 5px 0;
    height: 36px
}

#page-body-wrapper .slider-container-inner input.loan-term, #page-body-wrapper .slider-container-inner input.interest-rate, #page-body-wrapper .slider-container-inner input.loan-amount {
    width: 90px;
    font-size: 0.8em
}

#page-body-wrapper .slider-container-inner input.loan-term+.input-label, #page-body-wrapper .slider-container-inner input.interest-rate+.input-label, #page-body-wrapper .slider-container-inner input.loan-amount+.input-label {
    font-size: 130%
}

#page-body-wrapper .slider-container-inner input.loan-term+.left, #page-body-wrapper .slider-container-inner input.interest-rate+.left, #page-body-wrapper .slider-container-inner input.loan-amount+.left {
    left: -90px
}

@media (min-width: 48em) {
    #page-body-wrapper .slider-container-inner input.loan-term+.left, #page-body-wrapper .slider-container-inner input.interest-rate+.left, #page-body-wrapper .slider-container-inner input.loan-amount+.left {
        left: -80px
    }
}

@media (min-width: 60em) {
    #page-body-wrapper .slider-container-inner input.loan-term+.left, #page-body-wrapper .slider-container-inner input.interest-rate+.left, #page-body-wrapper .slider-container-inner input.loan-amount+.left {
        left: -90px
    }
}

#page-body-wrapper .slider-container-inner input.loan-term+.right, #page-body-wrapper .slider-container-inner input.interest-rate+.right, #page-body-wrapper .slider-container-inner input.loan-amount+.right {
    left: auto;
    right: 5px;
    line-height: 25px
}

@media (min-width: 48em) {
    #page-body-wrapper .slider-container-inner input.loan-term, #page-body-wrapper .slider-container-inner input.interest-rate, #page-body-wrapper .slider-container-inner input.loan-amount {
        padding-right: 5px
    }
}

@media (min-width: 60em) {
    #page-body-wrapper .slider-container-inner input.loan-term, #page-body-wrapper .slider-container-inner input.interest-rate, #page-body-wrapper .slider-container-inner input.loan-amount {
        width: 110px;
        font-size: 1em
    }
}

#page-body-wrapper .slider-container-inner input.loan-term+.input-label {
    font-size: 90%
}

@media (min-width: 60em) {
    #page-body-wrapper .slider-container-inner input.loan-term+.input-label {
        font-size: 115%
    }
}

#page-body-wrapper .slider-container-inner input.interest-rate, #page-body-wrapper .slider-container-inner input.loan-term {
    text-align: left;
    padding-left: 5px
}

#page-body-wrapper .slider-container-inner .input-container {
    position: relative;
    margin: 0;
    min-width: 95px
}

.annual-rewards-header+.media-row {
    margin-top: 0
}

.text-label.desktop-row {
    display: block;
    margin-top: -10px;
    line-height: 1em
}

@media (min-width: 48em) {
    .text-label.desktop-row {
        display: inline-block;
        margin-top: 0
    }
}

@media (min-width: 48em) {
    .annual-rewards-header.row {
        margin-bottom: 1em
    }
}

#spend-total-label {
    display: inline-block;
    position: absolute;
    font-size: 0.55em;
    left: -100px;
    top: 8px
}

#annual-rewards {
    font-size: 1.25em
}

.calc-slider {
    background: #d9d9d9;
    border-radius: 5px;
    display: inline-block;
    width: 100%;
    max-width: 375px;
    height: 12px;
    margin-right: -1px;
    margin-top: 10px;
    z-index: 10
}

.calc-slider.wideTooltip .calcTooltip {
    width: 55px
}

.calc-slider .calcTooltip {
    left: -3px
}

.calc-slider .ui-slider-handle {
    height: 28px;
    width: 29px;
    border: 0;
    background: url(../../img/btn/calc-sliderhandle.png) no-repeat;
    margin: -5px 0 0 -13px;
    z-index: 10
}

.calc-slider .ui-slider-handle:focus {
    outline: 1px solid #558dad
}

.calc-slider .ui-slider-handle:hover {
    cursor: pointer
}

.calc-slider .ui-slider-handle:hover .calcTooltip {
    display: block
}

.calc-slider .ui-slider-range {
    background-image: -webkit-linear-gradient(to left, #1d78b0 5%, #37517a);
    background-image: linear-gradient(to left, #1d78b0 5%, #37517a);
    background-color: #1d78b0;
    box-shadow: inset 1px 0 1px 1px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    z-index: 5
}

.calc-summary {
    text-align: right;
    position: relative;
    float: right
}

.calc-summary .highlight-blue {
    font-size: 2em
}

.slider-guide {
    position: relative;
    max-width: 375px;
    list-style: none;
    margin: 0;
    padding: 0;
    z-index: 5
}

.slider-guide li {
    list-style: none;
    font-size: 1em;
    color: #878787;
    margin: 0;
    padding: 0;
    position: absolute;
    top: -28px;
    height: 38px;
    width: 3px;
    text-indent: 9999px;
    z-index: 1;
    background-image: url(../../img/bg/calc-slider-guide.png);
    background-repeat: no-repeat
}

.slider-guide li.zero.percent {
    left: -5px
}

.slider-guide li.ten.percent {
    left: 10%
}

.slider-guide li.twenty.percent {
    left: 20%
}

.slider-guide li.thirty.percent {
    left: 30%
}

.slider-guide li.forty.percent {
    left: 40%
}

.slider-guide li.fifty.percent {
    left: 50%
}

.slider-guide li.sixty.percent {
    left: 60%
}

.slider-guide li.seventy.percent {
    left: 70%
}

.slider-guide li.eighty.percent {
    left: 80%
}

.slider-guide li.ninety.percent {
    left: 90%
}

.slider-guide li.hundred.percent {
    right: 25px
}

.slider-guide li.text {
    background-image: none;
    text-indent: 0;
    top: 10px
}

.searchPagination {
    list-style: none outside none;
    margin: 0
}

.searchPagination .inactive {
    color: #a5a3a3
}

.searchPagination li, .searchPagination .prev {
    border: 1px solid #bfbfbf;
    float: left
}

.searchPagination li {
    margin-right: 6px;
    padding: 2px 5px;
    text-align: center;
    vertical-align: middle
}

.searchPagination .prev {
    margin-right: 10px
}

#smartbanner {
    background-image: -webkit-linear-gradient(top, #f4f4f4 0, #cdcdcd 100%);
    background-image: linear-gradient(to bottom, #f4f4f4 0, #cdcdcd 100%);
    position: absolute;
    left: 0;
    top: -82px;
    border-bottom: 1px solid #e8e8e8;
    width: 100%;
    height: 78px;
    font-family: "Optimist Bold", Arial, Helvetica, sans-serif;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
    z-index: 9998;
    overflow: hidden;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: none
}

#smartbanner .sb-button {
    position: absolute;
    right: 20px;
    top: 24px;
    border: 1px solid #bfbfbf;
    border-radius: 3px;
    padding: 0 10px;
    min-width: 10%;
    height: 24px;
    font-size: 14px;
    font-weight: bold;
    color: #6a6a6a;
    line-height: 24px;
    background: -webkit-linear-gradient(top, #efefef 0%, #dcdcdc 100%);
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.8);
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.6), 0 1px 0 rgba(255, 255, 255, 0.7) inset
}

#smartbanner .sb-button:active, #smartbanner .sb-button:hover {
    background: -webkit-linear-gradient(top, #dcdcdc 0%, #efefef 100%)
}

#smartbanner .sb-container {
    margin: 0 auto
}

#smartbanner .sb-close {
    position: absolute;
    left: 5px;
    top: 5px;
    display: block;
    border: 2px solid #fff;
    width: 14px;
    height: 14px;
    font-family: "Optimist Bold", Arial, Helvetica, sans-serif;
    font-size: 15px;
    line-height: 15px;
    color: #fff;
    background: #070707;
    text-decoration: none;
    text-shadow: none;
    text-align: center;
    border-radius: 14px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.4);
    -webkit-font-smoothing: subpixel-antialiased
}

#smartbanner .sb-close:active {
    font-size: 13px;
    color: #aaa
}

#smartbanner .sb-icon {
    position: absolute;
    left: 30px;
    top: 10px;
    display: block;
    width: 57px;
    height: 57px;
    background-color: rgba(0, 0, 0, 0.6);
    background-size: cover;
    border-radius: 10px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3)
}

#smartbanner .sb-icon.gloss:after {
    content: "";
    position: absolute;
    left: 0;
    top: -1px;
    width: 100%;
    height: 50%;
    border-top: 1px solid rgba(255, 255, 255, 0.8);
    border-radius: 10px 10px 12px 12px;
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.2) 100%)
}

#smartbanner .sb-info {
    position: absolute;
    left: 98px;
    top: 18px;
    width: 44%;
    font-size: 11px;
    line-height: 1.2em;
    font-weight: bold;
    color: #6a6a6a;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.8)
}

#smartbanner .sb-info strong {
    display: block;
    font-size: 13px;
    color: #4d4d4d;
    line-height: 18px
}

#smartbanner .sb-info>span {
    display: block
}

#smartbanner .sb-info em {
    font-style: normal;
    text-transform: uppercase
}

#smartbanner.android {
    border-color: #212228;
    border-top: 5px solid #88b131;
    box-shadow: none;
    background: #3d3d3d url("../../img/bg/dark_background_stripes.gif")
}

#smartbanner.android .sb-close {
    border: 0;
    width: 17px;
    height: 17px;
    line-height: 17px;
    color: #b1b1b3;
    background: #1c1e21;
    text-shadow: 0 1px 1px #000;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.8) inset, 0 1px 1px rgba(255, 255, 255, 0.3)
}

#smartbanner.android .sb-close:active {
    color: #eee
}

#smartbanner.android .sb-info {
    color: #ccc;
    text-shadow: 0 1px 2px #000
}

#smartbanner.android .sb-info strong {
    color: #fff
}

#smartbanner.android .sb-button {
    min-width: 12%;
    border: 1px solid #dddcdc;
    border-radius: 0;
    padding: 1px;
    color: #d1d1d1;
    background: none;
    box-shadow: none
}

#smartbanner.android .sb-button span {
    display: block;
    padding: 0 10px;
    box-shadow: none;
    background-color: #42b6c9;
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#42b6c9), to(#39a9bb));
    background-image: -moz-linear-gradient(top, #42b6c9, #39a9bb);
    text-align: center;
    text-transform: none;
    text-shadow: none
}

#smartbanner.android .sb-button:active, #smartbanner.android .sb-button:hover {
    background: none
}

#smartbanner.android .sb-button:active span, #smartbanner.android .sb-button:hover span {
    background: #2ac7e1
}

#smartbanner.windows .sb-icon {
    border-radius: 0
}

#smartbanner.no-icon .sb-icon {
    display: none
}

#smartbanner.no-icon .sb-info {
    left: 34px
}

.novideo-bg {
    background-repeat: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0
}

.has-video .bg-video {
    position: relative
}

.bg-video video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: auto;
    z-index: -1
}

.video-controls {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    position: absolute;
    top: 10%;
    right: 10%;
    z-index: 1
}

.video-controls>button {
    background: rgba(255, 255, 255, 0.5);
    color: #037fb0;
    width: 30px;
    height: 30px;
    text-align: center;
    font-size: 18px;
    border-width: 1px;
    border-style: solid;
    border-color: #037fb0;
    border-radius: 50%
}

.ie8 .video-controls>button {
    background: #efefef
}

.video-controls>button:hover {
    background: rgba(255, 255, 255, 0.9);
    cursor: pointer;
    text-decoration: none;
    color: #04a3e2;
    border-color: #04a3e2;
    box-shadow: rgba(0, 0, 0, 0.1)
}

.video-controls>button span {
    position: relative;
    left: -2px;
    top: 2px
}

.video-controls>button span:before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    display: block;
    width: 30px;
    height: 30px;
    padding-top: 3px
}

.video-controls>button span.icon-play:before, .video-controls>button span.icon-pause:before {
    padding-left: 7px
}

.video-controls>button span.icon-volume-off:before {
    padding-left: 3px
}

.youtube-container iframe {
    max-width: 100%
}

@media (max-width: 47.9375em) {
    .youtube-container iframe {
        width: 100%;
        height: auto
    }
}

.ytcontrolwrap {
    display: none
}

.ytcontrolwrap:focus {
    display: block
}

.ytplayerbuttons {
    margin: 0
}

.ytplayerbuttons li {
    list-style-type: none;
    display: inline-block
}

.ytplayerbuttons button {
    transition: background-color 0.2s ease;
    width: 3em;
    background: #fff;
    border-width: 1px;
    border-style: solid;
    border-color: #004977;
    border-radius: 3px;
    padding: 2px 0
}

.ytplayerbuttons button span[class^="icon"] {
    transition: color 0.2s ease;
    position: relative;
    top: 1px;
    color: #004977;
    font-size: 1.25em
}

.ytplayerbuttons button:hover {
    transition: background-color 0.2s ease;
    background: #004977
}

.ytplayerbuttons button:hover span[class^="icon"] {
    transition: color 0.2s ease;
    color: #fff
}

.callout-block-left.youtube-container .image-wrapper {
    position: relative;
    min-width: 90px
}

.callout-block-left.youtube-container .image-wrapper:after {
    font-family: "icomoon";
    font-weight: normal;
    font-style: normal;
    text-decoration: inherit;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\f04b";
    position: absolute;
    top: 23%;
    left: 32%;
    font-size: 2em;
    color: #004977;
    border: 1px solid #004977;
    background: rgba(255, 255, 255, 0.5);
    padding: 0 5px 0 8px;
    border-radius: 3px;
    line-height: 1.2em
}

.component-details {
    border: 1px solid #e8e4e7;
    border-radius: 3px;
    margin-bottom: 1em
}

.component-details>* {
    padding: 1em;
    border-bottom: 1px solid #e8e4e7
}

.component-details>.bg {
    background-color: #faf8f9
}

.component-details .preformatted {
    border: 0
}

.component-details .preformatted pre {
    background-color: transparent;
    color: #021829
}

table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    border-width: 0;
    background-color: transparent
}

caption {
    display: none
}

th, td {
    padding: 0;
    vertical-align: top;
    text-align: left
}

th.align-center, td.align-center {
    text-align: center !important
}

table.rates {
    margin-top: 1em
}

table.rates th, table.rates td {
    text-align: right
}

table.rates th.first, table.rates td.first {
    text-align: left
}

table.rates td {
    border-bottom: 1px solid #ccd8e2
}

table.rates th {
    border: 1px solid #ccd8e2;
    font-weight: normal;
    padding: 5px 0
}

table.rates .last th, table.rates .last td, table.rates tfoot th, table.rates tfoot td {
    border: 0
}

table.rates thead th, table.rates thead td {
    font-weight: bold
}

table.rates thead th {
    padding-right: 0;
    padding-bottom: 0;
    border: 0;
    color: #666
}

table.rates tbody th, table.rates tbody td {
    padding: 5px 0;
    border-bottom: 1px solid #ccd8e2
}

.horScroll {
    width: 100%;
    overflow: scroll
}

table.basic {
    margin-top: 1em
}

table.basic h3 {
    line-height: 1.2;
    margin-bottom: 0
}

table.basic thead th {
    border-bottom: 1px solid #e5e5e5
}

table.basic th, table.basic td {
    background-image: none;
    background-repeat: repeat;
    background-attachment: scroll;
    background-position: 0 0;
    background-color: #fff;
    border-left: 1px solid #e5e5e5;
    padding: 10px;
    vertical-align: top
}

.center table.basic th, .center table.basic td {
    text-align: center
}

.align-right table.basic th, .align-right table.basic td {
    text-align: right
}

table.basic .even th, table.basic .even td {
    background-color: #eff4f9
}

table.basic .first {
    border-left: 0
}

table.basic tbody th {
    font-weight: normal
}

table.basic tfoot td, table.basic tfoot th {
    background-image: url(../../img/bg/compare-table-gradient.jpg);
    background-repeat: repeat-x;
    background-position: 0 top;
    background-color: transparent;
    border-top: 1px solid #e5e5e5;
    border-left: 0
}

table.basic ol, table.basic ul {
    margin: 0 0 1em 15px
}

table.basic .second-rate-break {
    display: block;
    margin-top: 1em
}

table.marketing thead th, table.marketing thead td {
    background-image: url(../../img/bg/compare-table-border-top.gif);
    background-repeat: repeat-x;
    background-position: bottom;
    padding-bottom: 15px
}

table.marketing tfoot td, table.marketing tfoot th {
    background-image: url(../../img/bg/marketing-table-border-btm.gif)
}

tr.showCollapsed td {
    padding: 5px 0 15px
}

tr.showCollapsed td a {
    background: #efefef;
    background-image: -webkit-linear-gradient(top, #efefef 0, #dfdfdf 100%);
    background-image: linear-gradient(to bottom, #efefef 0, #dfdfdf 100%);
    display: block;
    text-align: center;
    padding: 6px 0;
    border: 1px solid #d6d6d6;
    position: relative
}

tr.showCollapsed td a span {
    display: block;
    width: 18px;
    height: 8px;
    background-image: url(../../img/icon/showCollapsed-down-arrow.jpg);
    background-repeat: no-repeat;
    position: absolute;
    bottom: -8px;
    left: 50%;
    margin-left: -9px;
    text-indent: -999em;
    overflow: hidden
}

#results-status {
    display: none
}

@media (min-width: 48em) {
    #results-status {
        display: inline;
        font-size: 20px
    }
    #results-status em {
        font-style: normal;
        color: #d22f2f
    }
}

table.browse-table {
    margin-bottom: 1em
}

@media (min-width: 48em) {
    table.browse-table div {
        margin: 0
    }
}

table.browse-table tbody td, table.browse-table tbody th, table.browse-table thead td, table.browse-table thead th, table.browse-table tfoot td, table.browse-table tfoot th {
    display: none
}

@media (min-width: 48em) {
    table.browse-table tbody td, table.browse-table tbody th, table.browse-table thead td, table.browse-table thead th, table.browse-table tfoot td, table.browse-table tfoot th {
        display: table-cell
    }
}

table.browse-table td, table.browse-table th {
    padding: 10px;
    text-align: left
}

@media (min-width: 48em) {
    table.browse-table td, table.browse-table th {
        padding: 4px 9px;
        border-right: 1px solid #e8e8e8
    }
}

table.browse-table td.first, table.browse-table th.first {
    max-width: 200px !important;
    width: 190px !important
}

table.browse-table thead th {
    background-color: #464646;
    background-image: -webkit-linear-gradient(top, #7a7a7a 0, #464646 100%);
    background-image: linear-gradient(to bottom, #7a7a7a 0, #464646 100%);
    color: #fff;
    font-weight: normal;
    font-size: 13px
}

table.browse-table tbody {
    font-size: 14px
}

table.browse-table tbody td.first {
    display: table-cell;
    border-top: 1px solid #e8e8e8
}

@media (min-width: 48em) {
    table.browse-table tbody td.first {
        border-right: 0;
        line-height: 1.1
    }
}

table.browse-table tbody td.first h3 {
    font-size: 16px;
    font-weight: normal
}

table.browse-table tbody td.first b {
    font-size: 13px
}

@media (min-width: 48em) {
    table.browse-table tfoot td, table.browse-table tfoot th {
        border-top: 1px solid #e8e8e8;
        border-right: 0;
        background-image: url(../../img/bg/compare-table-gradient.jpg);
        background-repeat: repeat-x;
        padding-top: 2em;
        vertical-align: middle
    }
    table.browse-table tfoot img {
        vertical-align: middle;
        margin-right: 0.5em
    }
    table.browse-table tfoot .last {
        text-align: right
    }
}

table.browse-table .row-odd td, table.browse-table .row-odd th {
    background-color: #f3f3f3
}

table.browse-table .product-image {
    float: left;
    margin: 0 10px 10px 0
}

@media (min-width: 48em) {
    table.browse-table .product-image {
        margin-right: 0;
        display: block;
        float: none
    }
}

@media (min-width: 60em) {
    table.browse-table .product-image {
        float: left;
        margin-right: 10px;
        width: 80px;
        margin-bottom: 25px
    }
}

table.browse-table .compareCheckbox {
    display: none;
    clear: both;
    margin-top: 1em !important;
    margin-bottom: 10px !important
}

table.browse-table .compareCheckbox input {
    cursor: pointer
}

table.browse-table .compareCheckbox label {
    display: inline !important;
    cursor: pointer;
    font-size: 11px !important;
    color: #246fc8;
    padding: 6px 3px
}

@media (min-width: 48em) {
    table.browse-table .compareCheckbox {
        display: block
    }
}

table.browse-table .cb-compare {
    display: inline;
    width: auto
}

table.browse-table .arrow-mobile {
    display: block;
    float: right;
    width: 30px;
    height: 50px;
    padding: 10px 0 0 15px
}

@media (min-width: 48em) {
    table.browse-table .arrow-mobile {
        display: none
    }
}

table.browse-table .extra-credit {
    display: block;
    margin-top: 10px
}

@media (min-width: 48em) {
    table.browse-table .extra-credit {
        display: none
    }
}

table.browse-table .hype-text {
    width: auto;
    margin: 0 !important;
    display: inline
}

table.browse-table .hype-text p {
    margin: 0
}

table.browse-table .hype-text ul, table.browse-table .hype-text ol {
    margin: 1em 0;
    list-style-position: inside
}

@media (min-width: 48em) {
    table.browse-table .hype-text {
        float: none
    }
}

@media (min-width: 60em) {
    table.browse-table .hype-text {
        width: 12em;
        float: left
    }
}

@media (min-width: 48em) {
    table.browse-table tbody .last, table.browse-table tbody .credit-level {
        text-align: center;
        border-right: 0;
        font-size: 16
    }
    table.browse-table tbody .credit-level {
        padding: 4px 5px
    }
    table.browse-table tbody .credit-level img {
        display: block;
        margin: 5px auto;
        clear: both
    }
    table.browse-table tbody .credit-level .linkSelectCreditLevel {
        display: block;
        font-size: 13px;
        font-weight: bold;
        padding: 4px 0;
        background-repeat: repeat;
        background-color: #fff;
        border: 1px solid #000
    }
    table.browse-table tbody .credit-level span a {
        display: block
    }
    table.browse-table tbody .credit-level .c1-button {
        margin: 6px 0
    }
    table.browse-table tbody .credit-level .stars {
        margin: 5px auto 0
    }
    table.browse-table .excellent .credit-level .linkSelectCreditLevel {
        border-color: #9fba8d;
        background-image: url(../../img/bg/excellent-credit-bg.jpg);
        color: #1c6d40
    }
    table.browse-table .average .credit-level .linkSelectCreditLevel {
        border-color: #829eb2;
        background-image: url(../../img/bg/average-credit-bg.jpg);
        color: #004977
    }
    table.browse-table .rebuilding .credit-level .linkSelectCreditLevel {
        border-color: #f3d06d;
        background-image: url(../../img/bg/rebuilding-credit-bg.jpg);
        color: #975b00
    }
    table.browse-table .hover td, table.browse-table .hover th {
        background-color: #eff4fa
    }
    table.browse-table .rate-2 {
        display: block
    }
    table.browse-table .second-rate-2 {
        margin-top: 1em
    }
    table.browse-table .rate-semicolon {
        display: none
    }
}

#nav-lob-filter {
    background-color: #eff4fa;
    padding: 10px;
    margin: 10px 0;
    display: none
}

@media (min-width: 48em) {
    #nav-lob-filter {
        padding-left: 20px;
        display: block
    }
    #nav-lob-filter h3 {
        font-size: 13px
    }
}

#nav-lob-filter h3, #nav-lob-filter legend span {
    color: #004977;
    margin-bottom: 5px
}

#nav-lob-filter legend span {
    font-size: 13px
}

#nav-lob-filter legend span span {
    font-weight: normal;
    font-style: italic;
    font-size: 12px;
    display: inline
}

#nav-lob-filter fieldset {
    width: 50%;
    float: left
}

#nav-lob-filter fieldset span {
    display: block;
    clear: both
}

@media (min-width: 48em) {
    #nav-lob-filter fieldset {
        margin-top: 0;
        float: none;
        width: auto
    }
}

#nav-lob-filter input {
    float: left;
    margin-right: 5px;
    cursor: pointer
}

#nav-lob-filter label {
    float: left;
    font-weight: normal;
    cursor: pointer;
    padding-bottom: 5px
}

@media (min-width: 48em) {
    #nav-lob-filter label {
        font-size: 0.675em
    }
}

@media (min-width: 60em) {
    #nav-lob-filter label {
        font-size: 13px;
        padding-bottom: 0
    }
}

#nav-lob-filter label.disabled {
    color: #999;
    cursor: default
}

@media (min-width: 48em) {
    #nav-lob-filter p {
        margin: 0.5em 0 0;
        font-size: 0.6875em
    }
}

.ch-compare-wrapper {
    display: none
}

@media (min-width: 48em) {
    .ch-compare-wrapper {
        display: block;
        margin: 10px 0 15px !important
    }
    .ch-compare-wrapper span {
        vertical-align: middle;
        margin-right: 0.5em
    }
}

#reset-filters {
    clear: both;
    display: block;
    text-align: right
}

.detailed-compare-wrapper {
    overflow: auto;
    height: auto
}

table.detailed-compare {
    margin: 1em 0;
    clear: both
}

table.detailed-compare h3 {
    font-size: 1em
}

table.detailed-compare th, table.detailed-compare td {
    text-align: center;
    padding: 10px
}

table.detailed-compare th.first, table.detailed-compare td.first {
    text-align: left;
    font-weight: bold
}

table.detailed-compare thead th, table.detailed-compare thead td {
    border-left: 1px solid #ccc
}

table.detailed-compare thead th.first, table.detailed-compare thead td.first {
    border-left: 0
}

table.detailed-compare thead th p, table.detailed-compare thead td p {
    margin-top: 0
}

table.detailed-compare thead .first {
    border-left: 0;
    text-align: center
}

@media (min-width: 48em) {
    table.detailed-compare thead .first {
        background-color: transparent
    }
}

table.detailed-compare thead .last th, table.detailed-compare thead .last td {
    border-bottom: 2px solid #ccc
}

table.detailed-compare.rb-compare-table thead th, table.detailed-compare.rb-compare-table thead td {
    background-color: #008bc1;
    border-left: 1px solid #fff
}

table.detailed-compare.rb-compare-table thead th.first, table.detailed-compare.rb-compare-table thead td.first {
    border-left: 0
}

table.detailed-compare.rb-compare-table thead th p, table.detailed-compare.rb-compare-table thead td p {
    margin-top: 0
}

table.detailed-compare.rb-compare-table thead th a, table.detailed-compare.rb-compare-table thead td a {
    color: #fff;
    text-decoration: underline
}

table.detailed-compare.rb-compare-table thead .first {
    border-left: 0;
    text-align: center
}

@media (min-width: 48em) {
    table.detailed-compare.rb-compare-table thead .first {
        background-color: transparent
    }
}

table.detailed-compare.rb-compare-table thead .last th, table.detailed-compare.rb-compare-table thead .last td {
    border-bottom: 0
}

table.detailed-compare tbody .odd td, table.detailed-compare tbody .odd th {
    background-color: #e6f5fb
}

table.detailed-compare tbody .last td, table.detailed-compare tbody .last th {
    border-bottom: 1px solid #ccc
}

table.detailed-compare tbody th, table.detailed-compare tbody td {
    border-left: 1px solid #ccc
}

table.detailed-compare tbody th.first, table.detailed-compare tbody td.first {
    background-image: none;
    border-left: 0
}

table.detailed-compare.rb-compare-table tbody .odd td, table.detailed-compare.rb-compare-table tbody .odd th {
    background-color: #e6f5fb
}

table.detailed-compare.rb-compare-table tbody .last td, table.detailed-compare.rb-compare-table tbody .last th {
    border-bottom: 1px solid #ccc
}

table.detailed-compare.rb-compare-table tbody th, table.detailed-compare.rb-compare-table tbody td {
    border-left: 1px solid #fff
}

table.detailed-compare.rb-compare-table tbody th.first, table.detailed-compare.rb-compare-table tbody td.first {
    background-image: none;
    border-left: 0
}

table.detailed-compare tfoot td, table.detailed-compare tfoot th {
    border-left: 1px solid #ccc
}

table.detailed-compare tfoot td.first, table.detailed-compare tfoot th.first {
    border-left: 0
}

table.detailed-compare.rb-compare-table tfoot td, table.detailed-compare.rb-compare-table tfoot th {
    border-left: 1px solid #fff
}

table.detailed-compare.rb-compare-table tfoot td.first, table.detailed-compare.rb-compare-table tfoot th.first {
    border-left: 0
}

table.detailed-compare .stars {
    margin: 5px auto 0
}

table.compare-four th, table.compare-four td {
    width: 20%
}

table.compare-three th, table.compare-three td {
    width: 25%
}

table.compare-two th, table.compare-two td {
    width: 33%
}

table.compare-one th, table.compare-one td {
    width: 50%
}

.icon-tickmark:after {
    font-family: "Streamline";
    font-weight: normal;
    font-style: normal;
    text-decoration: inherit;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\e2fd";
    width: 1.5em
}

.borderless th, .borderless td {
    background-color: #fff;
    padding: 0 5px 5px 0;
    vertical-align: top
}

.borderless tr.last {
    border-bottom: 0
}

table.glance th, table.glance td {
    font-weight: normal;
    padding: 10px;
    vertical-align: top;
    width: 67%
}

table.glance thead th, table.glance thead td {
    border-bottom: 1px solid #e5e5e5
}

table.glance thead a img {
    float: none;
    margin: 5px 0 0;
    padding: 0
}

@media (max-width: 47.9375em) {
    table.glance thead a img {
        float: none !important
    }
}

@media (min-width: 48em) {
    table.glance thead img {
        float: left;
        padding-right: 20px;
        padding-left: 5px
    }
}

table.glance thead h3 {
    clear: none;
    margin-bottom: 0;
    padding-top: 2px
}

table.glance thead .c1-button {
    margin-top: 5px
}

table.glance tbody th {
    text-align: right
}

table.glance tbody th, table.glance tbody th.first, table.glance tbody td.first {
    font-weight: bold;
    width: 33%;
    border-right: 1px solid #e5e5e5
}

table.glance tbody tr.odd td, table.glance tbody tr.odd th {
    background-color: #eff4f9
}

table.glance tfoot td {
    border-top: 1px solid #e5e5e5
}

table.glance tr.credit-level td {
    padding: 4px 0 4px 5px
}

table.glance tr.credit-level td div {
    background-repeat: repeat;
    padding: 5px;
    text-align: center
}

table.glance tr.credit-level td div a {
    font-size: 13px;
    font-weight: bold
}

table.glance tr.credit-level td div a span {
    color: #037fb0 !important;
    font-size: 12px;
    font-style: italic;
    font-weight: normal;
    margin: 0 0 0 4px;
    text-decoration: none
}

table.glance tr.credit-level .Excellent div {
    background-image: url("../../img/bg/excellent-credit-bg.jpg");
    border: 1px solid #9fba8d
}

table.glance tr.credit-level .Excellent div a {
    color: #1c6d40
}

table.glance tr.credit-level .Average div {
    background-image: url("../../img/bg/average-credit-bg.jpg");
    border: 1px solid #829eb2
}

table.glance tr.credit-level .Average div a {
    color: #004977
}

table.glance tr.credit-level .Rebuilding div {
    background-image: url("../../img/bg/rebuilding-credit-bg.jpg");
    border: 1px solid #f3d06d
}

table.glance tr.credit-level .Rebuilding div a {
    color: #a36401
}

table.credit-details-table tbody th {
    width: 30%
}

table.credit-details-table tbody th, table.credit-details-table tbody td {
    padding-bottom: 5px;
    vertical-align: top
}

.product-suite {
    margin-bottom: 1em
}

.product-suite thead th {
    padding-bottom: 1em
}

.product-suite thead img {
    width: 70%
}

.product-suite thead h2 {
    font-size: 1.0625em
}

@media (min-width: 48em) {
    .product-suite thead h2 {
        font-size: 1.25em
    }
}

.product-suite tbody h3 {
    font-family: "Optimist", Arial, Helvetica, sans-serif;
    font-size: 0.8125em
}

@media (min-width: 48em) {
    .product-suite tbody h3 {
        font-size: 1em
    }
}

.product-suite tbody .rate-2 {
    color: #021829;
    font-size: 1em;
    font-weight: normal
}

.product-suite tbody .stars {
    margin: 0 auto 5px
}

.product-suite tbody .BVRRCount, .product-suite tbody .BVRRCount span {
    font-size: 1em
}

.product-suite tbody td {
    padding: 0 5px 20px
}

@media (min-width: 48em) {
    .product-suite tbody td {
        padding: 0 20px 20px
    }
}

.product-suite tbody td strong {
    display: block
}

@media (min-width: 48em) {
    .product-suite tbody td strong {
        display: inline
    }
}

.product-suite tbody .modal-overlay {
    text-transform: uppercase
}

.product-suite tfoot td {
    padding: 0 5px
}

@media (min-width: 48em) {
    .product-suite .c1-button+.c1-button {
        margin-left: 5px
    }
}

@media (min-width: 48em) {
    .two-products {
        margin: 0 auto;
        width: 66%
    }
}

.product-suite-horz {
    list-style-type: none;
    margin: 0;
    padding-top: 1.5625em
}

.product-suite-horz h2 {
    margin-bottom: 10px
}

.product-suite-horz li {
    display: block;
    clear: both;
    margin-bottom: 2.1875em;
    padding-bottom: 1.875em;
    border-bottom: 1px solid #efefef
}

.product-suite-horz li:after {
    display: block;
    visibility: hidden;
    clear: both;
    height: 0;
    content: " "
}

.product-suite-horz li:last-child {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0
}

.product-suite-horz li div {
    margin-bottom: 1em
}

@media (min-width: 48em) {
    .product-suite-horz li div {
        margin-bottom: 0
    }
}

.product-suite-horz .product-head {
    text-align: center
}

@media (min-width: 48em) {
    .product-suite-horz .product-head {
        width: 12em
    }
}

@media (min-width: 60em) {
    .product-suite-horz .product-head {
        width: 14em
    }
}

.product-suite-horz .card-art {
    text-align: center
}

.product-suite-horz .card-art img {
    width: 10em;
    margin: 0 auto
}

@media (min-width: 48em) {
    .product-suite-horz .card-art img {
        margin: 0
    }
}

.product-suite-horz .secured {
    border-top: 2px solid #efefef
}

.product-suite-horz strong {
    display: block
}

.product-suite-horz .credit-level {
    text-transform: uppercase
}

.product-suite-horz .stars {
    margin: 0 auto 0.3125em
}

.product-suite-horz .rate-2 {
    color: #021829;
    font-size: 1em;
    font-weight: normal
}

.product-suite-horz .ratings-stars {
    margin-bottom: 15px;
    text-align: center
}

.product-suite-horz .cta-column {
    text-align: center
}

.product-suite-horz .cta-column .c1-button {
    display: inline;
    width: auto
}

@media (min-width: 48em) {
    .product-suite-horz .cta-column {
        width: 7.1875em;
        margin: 0
    }
    .product-suite-horz .cta-column .c1-button {
        display: block
    }
    .product-suite-horz .cta-column .c1-button+.c1-button {
        margin-left: 0
    }
}

@media (min-width: 60em) {
    .product-suite-horz .cta-column {
        width: 8.4375em
    }
}

@media (min-width: 48em) {
    .product-suite-horz li>div {
        margin: 0 10px 0 0;
        float: left
    }
    .product-suite-horz .title {
        width: 7.5em
    }
    .product-suite-horz .benefits, .product-suite-horz .apr-text, .product-suite-horz .transfer-text, .product-suite-horz .fee-text {
        width: 5.8125em
    }
}

@media (min-width: 60em) {
    .product-suite-horz .title {
        width: 10.625em
    }
    .product-suite-horz .benefits, .product-suite-horz .apr-text, .product-suite-horz .transfer-text, .product-suite-horz .fee-text {
        width: 7.1875em;
        margin-right: 20px
    }
}

.ie8 .product-suite-horz h2 {
    font-size: 1.125em !important
}

.callout-block-left .image-wrapper {
    float: left;
    margin-right: 10px
}

.callout-block-left .image-wrapper img {
    display: block;
    margin-bottom: 10px
}

.callout-block-right .image-wrapper {
    float: right;
    margin-left: 10px
}

.callout-block-right .image-wrapper img {
    display: block;
    margin-bottom: 10px
}

.callout-block-left:after, .callout-block-right:after, table[class*="callout-block"]:after {
    display: block;
    visibility: hidden;
    clear: both;
    height: 0;
    content: " "
}

.vertical-align .row-wrapper {
    display: table;
    width: 100%
}

.vertical-align .row-wrapper .image-wrapper, .vertical-align .row-wrapper .content-block {
    display: table-cell;
    vertical-align: middle;
    margin: 0
}

.vertical-align .row-wrapper .image-wrapper {
    float: none;
    padding-right: 10px
}

.vertical-align .row-wrapper .content-block {
    width: 100%
}

.callout-block-right.vertical-align .image-wrapper {
    padding-right: 0;
    padding-left: 10px
}

.callout-block-left.cmf-closings {
    border-bottom: 1px solid #e5ebf0;
    border-collapse: initial;
    padding-bottom: 1em;
    margin-bottom: 2em
}

.callout-block-left.cmf-closings .content-block {
    width: 200px
}

@media (min-width: 60em) {
    .callout-block-left.cmf-closings .content-block {
        width: 270px
    }
}

table[class*="callout-block"] .image-wrapper {
    margin: 0;
    overflow: hidden
}

table[class*="callout-block"] .content-block p {
    margin-top: 0
}

.gradient-bg {
    background: url(../../img/bg/portfolio.png) repeat-x;
    padding: 15px 20px 0
}

.gradient-bg h3 {
    padding-top: 1em
}

.gradient-bg .callout-block-left .image-wrapper img {
    max-width: none
}

.browse-text-banner {
    background: #f0f0f0;
    padding: 20px;
    text-align: center;
    margin: 20px 0
}

.browse-text-banner h2 {
    font-size: 1.25em
}

@media (min-width: 60em) {
    .browse-text-banner h2 {
        font-size: 1.5em
    }
}

.callout-basic-left img, .callout-basic-right img, .callout-basic-top img {
    margin-bottom: 10px
}

.callout-basic-left ol, .callout-basic-right ol, .callout-basic-top ol, .callout-basic-left ul, .callout-basic-right ul, .callout-basic-top ul {
    margin: 1em 0 1em 15px
}

.callout-basic-left img {
    float: left;
    clear: left;
    margin-right: 10px
}

.callout-basic-left img+ul {
    list-style-position: inside
}

.callout-basic-right img {
    float: right;
    clear: right;
    margin-left: 10px
}

.callout-basic-right .component-cta img {
    margin-left: 0
}

.callout-basic-top .rate-2 {
    color: #262626;
    font-size: 1em;
    font-weight: normal
}

.callout-bg {
    width: 100%;
    text-shadow: 2px 0 1px #fff, 0 2px 1px #fff, -2px 0 1px #fff, 0 -2px 1px #fff
}

.callout-bg .callout-bg-image {
    background-position: -999em;
    background-repeat: no-repeat
}

@media (min-width: 48em) {
    .callout-bg .callout-bg-image {
        background-size: 100% auto;
        background-position: right top
    }
}

.callout-bg .component-cta br {
    display: none
}

@media (min-width: 48em) {
    .callout-bg .component-cta br {
        display: inherit
    }
}

.callout-bg ol, .callout-bg ul {
    margin: 1em 0 1em 15px
}

@media (min-width: 48em) {
    .callout-bg.bottom-right .callout-bg-image {
        background-position: right bottom
    }
    .callout-bg.bottom-left .callout-bg-image {
        background-position: left bottom
    }
    .callout-bg.top-left .callout-bg-image {
        background-position: left top;
        background-repeat: repeat;
        background-size: auto auto;
        padding: 10px;
        text-shadow: none
    }
}

.callout-product img {
    float: left;
    clear: left;
    margin: 0 10px 10px 0
}

@media (min-width: 48em) {
    .callout-product img {
        float: none;
        margin-bottom: 0
    }
}

@media (min-width: 60em) {
    .callout-product img {
        float: left;
        margin-bottom: 10px
    }
}

.callout-product .c1-button {
    font-size: 0.875em
}

.callout-product .component-cta {
    clear: right;
    overflow: hidden;
    padding-top: 10px
}

@media (min-width: 48em) {
    .callout-product .component-cta {
        float: none;
        padding-top: 0
    }
}

@media (min-width: 60em) {
    .callout-product .component-cta {
        float: left;
        padding-top: 10px
    }
}

.callout-product .component-cta img {
    float: none
}

.callout-product .component-cta img, .callout-product .component-cta span {
    display: block;
    clear: both;
    margin: 0;
    padding-bottom: 5px
}

.callout-product ul, .callout-product ol {
    margin: 1em 0 1em 15px
}

.key-benefits h3, .key-benefits-callout h3 {
    background-color: #004977;
    background-image: url(../../img/bg/key-benefits-callout.gif);
    background-repeat: repeat-x;
    color: #fff;
    padding: 0 10px;
    height: 35px;
    line-height: 28px
}

.key-benefits ul, .key-benefits-callout ul {
    list-style-type: none;
    margin: 0 0 1em
}

.key-benefits li, .key-benefits-callout li {
    background-image: url(../../img/icon/key-benefit-checkmark.gif);
    background-repeat: no-repeat;
    background-position: 0 0;
    padding: 7px 0 0 20px
}

div.key-benefits {
    background-repeat: no-repeat;
    background-position: -999em
}

@media (min-width: 48em) {
    div.key-benefits {
        background-position: left top;
        background-size: 100% auto;
        background-repeat: no-repeat;
        min-height: 16.25em
    }
    div.key-benefits h3 {
        background-image: none;
        background-color: transparent
    }
    div.key-benefits ul {
        margin: 1em 0 1em 1em;
        width: 18em
    }
    div.key-benefits .component-cta, div.key-benefits p {
        margin-left: 30px
    }
}

@media (min-width: 60em) {
    div.key-benefits ul {
        width: 20.25em
    }
}

.composite-banner {
    background-repeat: no-repeat;
    background-position: -999em
}

@media (min-width: 48em) {
    .composite-banner {
        background-position: left top;
        background-size: 100% auto;
        clear: both;
        min-height: 9.6875em;
        overflow: hidden;
        padding: 2.8125em 1.25em 0.9375em;
        width: 26.25em
    }
    .composite-banner h2 {
        left: -999em;
        position: absolute;
        white-space: nowrap
    }
    .composite-banner strong {
        font-weight: bold
    }
    .composite-banner .content {
        width: 18.75em
    }
    .composite-banner .component-cta, .composite-banner .component-cta span, .composite-banner .component-cta img {
        vertical-align: middle
    }
    .composite-banner .component-cta span {
        padding-bottom: 5px
    }
    .composite-banner .component-cta img {
        margin-right: 10px;
        padding-bottom: 5px
    }
    .composite-banner.red, .composite-banner.red h3, .composite-banner.blue, .composite-banner.blue h3 {
        color: #fff
    }
    .composite-banner.red .highlight, .composite-banner.blue .highlight {
        color: #ffe512
    }
    .composite-banner.red {
        background-color: #d22f2f
    }
    .composite-banner.blue {
        background-color: #004977
    }
    .composite-banner.yellow {
        background-color: #ffe512
    }
    .composite-banner.yellow, .composite-banner.yellow h3 {
        color: #d22f2f
    }
    .composite-banner.yellow .highlight {
        color: #004977
    }
    .composite-banner.right {
        padding-right: 0;
        width: 27.5em
    }
    .composite-banner.right .content {
        border-right: 1px solid #fff;
        clear: left;
        float: left;
        min-height: 9.6875em;
        width: 18.125em;
        padding-right: 0.625em
    }
    .composite-banner.right .component-cta {
        clear: right;
        float: right;
        overflow: hidden;
        padding-top: 40px;
        text-align: center;
        width: 8.4375em
    }
    .composite-banner.right .component-cta>img, .composite-banner.right .component-cta>span {
        clear: both;
        display: block;
        margin: 0 auto;
        padding-bottom: 5px
    }
}

@media (min-width: 60em) {
    .composite-banner {
        min-height: 13.125em;
        padding: 3.75em 1.25em 1.25em;
        width: 32.5em
    }
    .composite-banner.right {
        width: 33.75em
    }
    .composite-banner.right .content {
        min-height: 13.125em;
        width: 20em;
        padding-right: 1.25em
    }
    .composite-banner.right .component-cta {
        width: 11.875em
    }
}

.composite-banner ul {
    margin-left: 15px
}

.product-details-banner {
    margin-bottom: 0
}

.product-details-banner h2 {
    font-size: 26px;
    line-height: 1;
    color: #004977;
    margin-bottom: 30px
}

@media (min-width: 48em) {
    .product-details-banner h2 {
        font-size: 38px
    }
}

.product-details-banner .ratings-stars .average-reviews {
    font-size: 20px;
    font-weight: 400;
    color: #004977;
    line-height: 1;
    margin-right: 10px;
    vertical-align: 6px
}

@media (min-width: 48em) {
    .product-details-banner .ratings-stars .average-reviews {
        font-size: 25px
    }
}

.product-details-banner .ratings-stars .rating {
    font-size: 1.2em;
    vertical-align: -2px
}

.product-details-banner .ratings-stars .reversed .average-reviews, .reversed .product-details-banner .ratings-stars h1 .average-reviews, .reversed .product-details-banner .ratings-stars h2 .average-reviews, .reversed .product-details-banner .ratings-stars h3 .average-reviews, .reversed .product-details-banner .ratings-stars h4 .average-reviews, .reversed .product-details-banner .ratings-stars h5 .average-reviews {
    color: #fff
}

.product-details-banner .ratings-stars .stars {
    display: inline-block
}

.product-details-banner .ratings-stars .BVRRCount, .product-details-banner .ratings-stars .BVRRCount span {
    font-size: 1em
}

.product-details-banner .ratings-stars a {
    display: block
}

.product-details-banner .image-wrapper {
    text-align: center;
    width: 40%
}

@media (min-width: 48em) {
    .product-details-banner .image-wrapper {
        width: 25%
    }
}

.product-details-banner .image-wrapper img {
    max-width: 100% !important;
    display: inline
}

.product-details-banner .image-wrapper .component-cta span {
    display: block;
    font-size: 0.875em
}

.product-details-banner .content-block {
    overflow: visible
}

@media (min-width: 48em) {
    .product-details-banner .content-block {
        overflow: hidden
    }
}

@media (min-width: 48em) {
    .product-details-banner h2 {
        text-align: center
    }
    .product-details-banner .content-block {
        text-align: center
    }
}

.product-details-banner.mfirst .image-wrapper {
    width: 48%;
    margin-right: 2%;
    text-align: right
}

.product-details-banner.mfirst .content-block {
    text-align: left;
    float: right;
    width: 50%
}

.product-details-banner.mfirst .content-block p {
    margin-bottom: 0.889em
}

.product-details-banner.mfirst .content-additional {
    clear: both;
    text-align: center
}

.product-details-banner.mfirst .ratings-stars {
    margin-bottom: 0.25em
}

.ie .product-details-banner h2 {
    font-size: 42px
}

.callout-bg-icon {
    position: relative
}

.callout-bg-icon .content-wrapper {
    padding: 0.9375em;
    position: relative;
    min-height: 19.6875em
}

.callout-bg-icon .content-bottom {
    position: absolute;
    left: 0.9375em;
    right: 0.9375em;
    bottom: 0.9375em
}

.callout-bg-icon .c1-button {
    margin-bottom: 0;
    display: inline-block;
    width: auto
}

.callout-bg-icon img {
    margin-bottom: 0.625em
}

.callout-bg-icon.image-left .image-wrapper, .callout-bg-icon.image-right .image-wrapper {
    float: left;
    padding: 0.9375em 0 0.9375em 0.9375em
}

.callout-bg-icon.image-left img, .callout-bg-icon.image-right img {
    max-width: 110px;
    margin-bottom: 0
}

@media (min-width: 48em) {
    .callout-bg-icon.image-left img, .callout-bg-icon.image-right img {
        max-width: 100%
    }
}

.callout-bg-icon.image-right .image-wrapper {
    float: right;
    padding: 0.9375em 0.9375em 0.9375em 0
}

.callout-bg-icon.padding {
    padding: 0
}

.callout-bg-icon.padding .image-wrapper {
    padding: 1.875em 0 1.875em 1.875em
}

.callout-bg-icon.padding .content-wrapper {
    padding: 1.875em;
    min-height: 17.8125em
}

.callout-bg-icon.padding .content-bottom {
    left: 1.875em;
    right: 1.875em;
    bottom: 1.875em
}

.callout-bg-icon.no-bottom .content-bottom {
    position: relative;
    left: auto;
    right: auto;
    bottom: auto;
    margin-top: 0.9375em
}

.callout-bg-icon.hover .content-wrapper:hover {
    background-color: rgba(0, 0, 0, 0.1)
}

.decorated {
    border-top: 1px solid #dad8db;
    padding-top: 2em;
    margin-top: 2em;
    position: relative
}

.decorated:before {
    position: absolute;
    top: -24px;
    left: 50%;
    margin-left: -85px;
    display: block;
    width: 170px;
    height: 50px;
    font-family: Georgia, "Times New Roman", Times, serif;
    font-size: 8.4375em;
    font-style: normal;
    color: #008bc1;
    line-height: 0.85;
    content: open-quote " " close-quote;
    background-color: #fff;
    text-align: center
}

.decorated h2 {
    font-size: 28px
}

.decorated blockquote {
    padding: 0;
    font-size: 24px;
    line-height: 1.2;
    border: 0;
    color: #606a78;
    font-style: italic;
    position: relative
}

.decorated blockquote p {
    font-size: 24px
}

.decorated .content-wrapper {
    display: table
}

.decorated .content-wrapper .content-block {
    display: table-cell;
    vertical-align: middle
}

.image-left .image-block {
    display: table-cell;
    vertical-align: middle;
    padding-right: 1.875em
}

.image-right .image-block {
    display: table-cell;
    vertical-align: middle;
    padding-left: 1.875em
}

.quote-top {
    margin-top: 1em
}

.quote-top:before {
    display: block;
    font-family: Georgia, "Times New Roman", Times, serif;
    font-size: 80px;
    line-height: 16px;
    vertical-align: bottom;
    color: #008bc1;
    content: open-quote
}

.quote-top:after {
    content: no-close-quote
}

.quote-top blockquote {
    padding: 0;
    font-size: 1em;
    line-height: 1.4;
    border: 0
}

.cite, cite {
    font-size: 85%;
    margin-left: 10px
}

@media (max-width: 47.9375em) {
    .callout-block-left img, .callout-block-right img, .callout-basic-left img, .callout-basic-right img {
        max-width: 140px
    }
}

.ie .callout-block-left img, .ie .callout-block-right img, .ie .callout-basic-left img, .ie .callout-basic-right img {
    max-width: none
}

.component-cta {
    vertical-align: middle
}

.component-cta img {
    float: none;
    margin-right: 10px;
    margin-bottom: 0;
    padding-bottom: 5px;
    vertical-align: middle
}

.component-cta>span {
    padding-left: 5px
}

.ui-cluetip, .cluetip-inner {
    background-color: transparent !important;
    border: 0 !important
}

.ui-cluetip-content a {
    color: #037fb0 !important
}

.cluetip-close {
    position: absolute
}

.cluetip-close:after {
    font-family: "Streamline";
    font-weight: normal;
    font-style: normal;
    text-decoration: inherit;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -moz-osx-font-smoothing: auto;
    -webkit-font-smoothing: auto;
    content: "\e306";
    padding-left: 5px;
    font-size: 12px;
    color: #037fb0
}

.cluetip-tooltip-rounded .cluetip-outer {
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 25px 25px 40px;
    position: relative;
    margin-top: 20px
}

.cluetip-tooltip-rounded.clue-bottom-tooltip-rounded .cluetip-outer {
    margin-top: 0
}

.cluetip-tooltip-rounded .cluetip-close {
    bottom: 10px;
    right: 20px
}

.cluetip-tooltip-rounded .cluetip-arrows {
    background-position: 0 0;
    background-repeat: no-repeat;
    width: 11px;
    height: 22px;
    position: absolute;
    top: 0
}

.clue-right-tooltip-rounded .cluetip-outer, .clue-left-tooltip-rounded .cluetip-outer {
    margin-top: 0
}

.clue-right-tooltip-rounded .cluetip-arrows {
    background-image: url("https://www.capitalone.com/assets/rwd/img/bg/tooltip-arrowleft.png");
    left: -9px;
    top: 10px !important
}

.clue-left-tooltip-rounded .cluetip-arrows {
    background-image: url("https://www.capitalone.com/assets/rwd/img/bg/tooltip-arrowright.png");
    left: auto;
    right: -9px;
    top: 10px !important
}

.clue-top-tooltip-rounded .cluetip-arrows, .clue-bottom-tooltip-rounded .cluetip-arrows {
    width: 22px;
    height: 11px;
    margin-left: -11px;
    left: 50%
}

.clue-top-tooltip-rounded .cluetip-arrows {
    background-image: url("https://www.capitalone.com/assets/rwd/img/bg/tooltip-arrowdown.png");
    margin-top: -11px;
    top: auto;
    bottom: -9px
}

.clue-bottom-tooltip-rounded .cluetip-arrows {
    background-image: url("https://www.capitalone.com/assets/rwd/img/bg/tooltip-arrowup.png");
    top: -9px
}

#tooltip-hint, .cluetip-finish {
    position: absolute;
    top: -999em
}

.clicktip-content {
    display: none
}

.ui-cluetip-header {
    display: none !important
}

.calcTooltip {
    position: absolute;
    left: -999em;
    background-color: #666;
    color: #fff;
    display: none;
    margin: 0;
    z-index: 45;
    top: 40px;
    padding: 2px 0;
    width: 35px;
    border-radius: 2px;
    text-align: center;
    font-size: 0.8em
}

.calcTooltip.visible {
    left: -10px
}

.calcTooltip:after, .calcTooltip:before {
    position: absolute;
    display: block;
    width: 20px;
    height: 13px;
    content: ""
}

.calcTooltip:before {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    background-image: url("../../img/icon/global-icons.png");
    background-repeat: no-repeat;
    background-position: 0 -400px;
    top: -12px;
    left: 7px
}

.ie8 .calcTooltip:before {
    display: none
}

.calcTooltip, .calcTooltip:focus {
    outline: none
}

#access-account-content a {
    display: block
}

#access-account-content .icon-lock {
    position: absolute;
    top: 10px;
    right: 15px
}

.tabbed .royalControlNavOverflow li, .tabbed .royalControlNavOverflow li:hover {
    transition: bottom 0.25s ease-in
}

.rsContainer {
    position: relative;
    width: 100%;
    height: 100%;
    -webkit-tap-highlight-color: transparent
}

.rsNav, .rsArrow, .rsThumbsArrow {
    opacity: 1;
    transition: opacity 0.3s linear
}

.rsArrow, .rsThumbsArrow {
    cursor: pointer
}

.rsHidden {
    opacity: 0.25;
    transition: visibility 0s linear 0.3s, opacity 0.3s linear
}

.rsSlide {
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    height: 100%;
    width: 100%
}

.rsSlide {
    -webkit-transition: 1s;
    -ms-transition: 1s;
    transition: 1s;
    opacity: 1
}

.rsHiddenSlide {
    visibility: hidden;
    opacity: 0
}

.rsBullet {
    text-indent: -99999px;
    display: inline-block !important
}

.rsArrow {
    position: absolute;
    top: 45%;
    z-index: 25;
    display: block;
    width: 44px;
    height: 44px;
    font-size: 0;
    line-height: 0;
    overflow: hidden;
    text-indent: -9999px;
    cursor: pointer;
    background-image: url("../../img/icon/carousel-controls.png")
}

.rsArrowDisabled {
    -moz-opacity: 0.3;
    opacity: 0.3
}

.rsArrowLeft {
    background-position: 0 0;
    left: 5px
}

.rsArrowRight {
    background-position: 0 -50px;
    right: 5px
}

.rsBullet {
    display: inline;
    list-style-type: none;
    width: 44px;
    height: 44px;
    background-image: url("../../img/icon/carousel-controls.png");
    background-repeat: no-repeat;
    background-position: 0 -100px;
    background-color: transparent
}

.rsBullet:hover {
    cursor: pointer
}

.rsBullet:hover, .rsBullet.current, .rsBullet.rsNavSelected {
    background-position: 0 -150px
}

.rsNavCenterer {
    width: 100%;
    bottom: 0;
    position: absolute;
    margin: 0;
    padding: 0;
    z-index: 35
}

.rsBullets {
    text-align: center;
    bottom: 50px;
    margin: 0;
    padding: 0
}

.royalSlider.rsAutoHeight, .rsAutoHeight .rsSlide {
    height: auto
}

.rsContent {
    width: 100%;
    height: 100%;
    position: relative;
    list-style-type: none;
    overflow: hidden
}

img.rsImg {
    max-width: none
}

.rsTabs {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding-left: 1%;
    text-transform: uppercase;
    overflow: hidden
}

.rsTabs .rsTab {
    background-image: -webkit-linear-gradient(top, #fbfbfb 0, #e3ebf3 100%);
    background-image: linear-gradient(to bottom, #fbfbfb 0, #e3ebf3 100%);
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
    top: 20px;
    display: inline-block;
    width: 19%;
    margin-right: 0.5%;
    padding: 0.25em 1em;
    border-width: 1px;
    border-style: solid;
    border-color: #000;
    border-radius: 10px 10px 0 0;
    border-bottom-width: 0;
    font-size: 11px;
    font-weight: bold;
    text-transform: uppercase
}

.rsTabs .rsTab h3 {
    font-size: 1.25em
}

.rsTabs .rsTab.rsNavSelected, .rsTabs .rsTab:hover {
    top: 0
}

.rsTabs .rsTab.rsNavSelected {
    background-image: -webkit-linear-gradient(top, #075a97 0, #00356b 100%);
    background-image: linear-gradient(to bottom, #075a97 0, #00356b 100%);
    color: #fff
}

.rsTabs .rsTab:hover {
    cursor: pointer
}

.rsTabs .rsTab:last-child {
    margin-right: 0
}

.royalSlider {
    position: relative;
    overflow: hidden !important;
    margin: 0;
    z-index: 30;
    height: 0
}

.carousel-content {
    width: 100%;
    overflow: hidden;
    position: relative;
    z-index: 10;
    margin: 0
}

.carousel-content li {
    list-style-type: none;
    overflow: hidden;
    width: 100%;
    display: inline-block;
    float: left
}

.carousel-content li img {
    width: 100%
}

.carousel-content li a {
    display: block
}

.carousel-content li a:focus {
    border: 2px solid #246fc8
}

#news {
    position: relative;
    padding: 3px 0 4px
}

@media (min-width: 48em) {
    #news {
        height: 239px
    }
}

@media (min-width: 60em) {
    #news {
        height: 304px
    }
}

#news h3 {
    margin: 5px;
    color: #fff;
    font-size: 1.4em;
    padding: 0 5px
}

@media (min-width: 48em) {
    #news h3 {
        font-size: 1.2em;
        padding: 0 5px
    }
}

@media (min-width: 60em) {
    #news h3 {
        margin: 10px;
        font-size: 1.7em
    }
}

#news h3 a {
    text-decoration: none
}

#news h3 a:hover {
    text-decoration: underline
}

#news ul {
    list-style-type: none;
    margin: 10px 0 0
}

@media (min-width: 60em) {
    #news ul {
        margin: 15px 0 0
    }
}

#news li {
    border-bottom: 1px dotted #ad5d5c;
    margin: 0 0 20px;
    padding: 0 15px
}

@media (min-width: 48em) {
    #news li {
        margin: 0 0 10px;
        padding: 0 10px
    }
}

@media (min-width: 60em) {
    #news li {
        padding: 0 15px
    }
}

#news li.last {
    border-bottom: 0 dotted #fff
}

#news p {
    word-wrap: break-word;
    -ms-word-wrap: break-word;
    font-size: 1em;
    margin: 1em 0
}

@media (min-width: 48em) {
    #news p {
        font-size: 0.85em;
        margin: 0.7em 0
    }
}

@media (min-width: 60em) {
    #news p {
        font-size: 1em;
        margin: 1em 0
    }
}

#news .readmore {
    background-image: url("https://www.capitalone.com/assets/rwd/img/icon/icon_read_arrow.png");
    background-repeat: no-repeat;
    background-position: 0 0;
    position: absolute;
    right: 8px;
    padding: 0 0 0 20px
}

@media (min-width: 48em) {
    #news .readmore {
        right: 10px;
        font-size: 0.8em
    }
}

@media (min-width: 60em) {
    #news .readmore {
        font-size: 1em
    }
}

#newsTicker {
    margin: -10px 0 10px;
    padding: 10px;
    text-align: center
}

#newsTicker h3 {
    font-size: 1.3em;
    font-weight: bold
}

@media (min-width: 48em) {
    #newsTicker h3 {
        font-size: 1.2em
    }
}

@media (min-width: 60em) {
    #newsTicker h3 {
        font-size: 1.5em
    }
}

#newsTicker h3 a {
    text-decoration: none;
    color: #8a1420
}

#newsTicker h3 a:hover {
    text-decoration: underline
}

#newsTicker .ticker-wrapper.has-js {
    border-radius: 8px !important;
    width: 97% !important;
    margin: 5px 0 !important;
    padding: 0 10px !important;
    background-color: #fff !important;
    font-size: 1.1em !important
}

@media (min-width: 48em) {
    #newsTicker .ticker-wrapper.has-js {
        font-size: 1em !important
    }
}

@media (min-width: 60em) {
    #newsTicker .ticker-wrapper.has-js {
        font-size: 1.1em !important
    }
}

#newsTicker .ticker {
    background-color: #fff !important;
    width: 87% !important;
    height: inherit !important
}

#newsTicker .ticker-content {
    background-color: #fff !important;
    left: 10px !important
}

#newsTicker .ticker-swipe {
    width: 100% !important
}

#newsTicker .ticker-swipe, #newsTicker .ticker-swipe span {
    background-color: #fff !important
}

#newsTicker .ticker-controls {
    margin: 0 !important;
    padding: 9px 0 !important;
    float: right !important
}

.ticker-wrapper.has-js {
    margin: 20px 0;
    padding: 0 20px;
    width: 780px;
    height: 32px;
    display: block;
    border-radius: 15px;
    background-color: #f8f0db;
    font-size: 0.75em
}

.ticker {
    width: 710px;
    height: 23px;
    display: block;
    position: relative;
    overflow: hidden;
    background-color: #f8f0db
}

.ticker-title {
    padding-top: 9px;
    color: #900;
    font-weight: bold;
    background-color: #f8f0db;
    text-transform: uppercase
}

.ticker-content {
    margin: 0;
    padding-top: 9px;
    position: absolute;
    color: #1f527b;
    font-weight: bold;
    background-color: #f8f0db;
    overflow: hidden;
    white-space: nowrap;
    line-height: 1.2em
}

.ticker-content a {
    text-decoration: none;
    color: #1f527b
}

.ticker-content a:hover {
    text-decoration: underline;
    color: #0d3059
}

.ticker-swipe {
    padding-top: 9px;
    position: absolute;
    top: 0;
    background-color: #f8f0db;
    display: block;
    width: 800px;
    height: 23px
}

.ticker-swipe span {
    margin-left: 1px;
    background-color: #f8f0db;
    border-bottom: 1px solid #1f527b;
    height: 12px;
    width: 7px;
    display: block
}

.ticker-controls {
    padding: 8px 0 0;
    list-style-type: none;
    float: left
}

.ticker-controls li a {
    position: absolute;
    text-indent: -9999px;
    overflow: hidden
}

.news .ticker-controls li {
    padding: 0;
    margin-left: 5px !important;
    float: left;
    cursor: pointer;
    height: 16px;
    width: 16px;
    display: block
}

.ticker-controls li.jnt-play-pause, .ticker-controls li.jnt-prev, .ticker-controls li.jnt-next {
    background-image: url("https://www.capitalone.com/assets/rwd/img/bg/controls.png")
}

.ticker-controls li.jnt-play-pause {
    background-position: 32px 16px
}

.ticker-controls li.jnt-play-pause.over {
    background-position: 32px 32px
}

.ticker-controls li.jnt-play-pause.down {
    background-position: 32px 0
}

.ticker-controls li.jnt-play-pause.paused {
    background-position: 48px 16px
}

.ticker-controls li.jnt-play-pause.paused.over {
    background-position: 48px 32px
}

.ticker-controls li.jnt-play-pause.paused.down {
    background-position: 48px 0
}

.ticker-controls li.jnt-prev {
    background-position: 0 16px
}

.ticker-controls li.jnt-prev.over {
    background-position: 0 32px
}

.ticker-controls li.jnt-prev.down {
    background-position: 0 0
}

.ticker-controls li.jnt-next {
    background-position: 16px 16px
}

.ticker-controls li.jnt-next.jnt-next.over {
    background-position: 16px 32px
}

.ticker-controls li.jnt-next.jnt-next.down {
    background-position: 16px 0
}

.js-hidden {
    display: none
}

.no-js-news {
    padding: 10px 0 0 45px;
    color: #f8f0db
}

.left .ticker-controls, .left .ticker-content, .left .ticker-title, .left .ticker {
    float: left
}

.left .ticker-controls {
    padding-left: 6px
}

.right .ticker-controls, .right .ticker-content, .right .ticker-title, .right .ticker {
    float: right
}

.right .ticker-controls {
    padding-right: 6px
}

#oo_tab {
    display: none
}

@media (min-width: 48em) {
    #oo_tab {
        display: block;
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.7);
        background-color: #fff;
        border: 1px solid #ccc;
        cursor: pointer;
        position: fixed !important;
        bottom: 0 !important;
        left: -8px !important;
        height: 110px;
        width: 42px;
        z-index: 31;
        -webkit-transition: left 0.5s;
        -ms-transition: left 0.5s;
        transition: left 0.5s;
        background-position: top left;
        background-image: -webkit-linear-gradient(left, #fff, #eee);
        background-image: linear-gradient(left, #fff, #eee)
    }
    #oo_tab div, #oo_tab a {
        background-image: url(../../img/icon/oo_tab.gif@v=15.10.00);
        background-repeat: no-repeat;
        background-position: 8px 1px;
        background-color: transparent;
        position: absolute;
        display: block;
        height: 100%;
        left: 0;
        top: 0;
        width: 100%;
        border: 0
    }
    #oo_tab button {
        border: 0
    }
    #oo_tab a {
        background-position: -70px 50%
    }
    #oo_tab span {
        display: block;
        position: absolute;
        text-indent: -999em;
        overflow: hidden
    }
    #oo_tab:hover, #oo_tab:focus {
        left: -2px !important
    }
    .tab-whiteout {
        z-index: 10 !important
    }
    .oo_tab_ie_right {
        position: fixed !important;
        top: auto !important;
        bottom: 0 !important;
        background-position: top -140px !important;
        right: 1px !important;
        width: 38px !important
    }
    .oo_tab_ie_right:hover, .oo_tab_ie_right:focus {
        right: 6px !important;
        width: 40px !important
    }
}

#decipherInviteBox {
    background-color: #fff;
    border: 2px solid #606a77;
    left: 50%;
    margin-left: -8.75em;
    padding: 1em;
    position: absolute;
    text-align: center;
    top: 9.375em;
    width: 17.5em;
    z-index: 9999
}

.fancybox-lock {
    overflow: hidden
}

.fancybox-lock .fancybox-overlay {
    overflow-x: auto;
    overflow-y: scroll
}

.fancybox-overlay, .simplemodal-overlay, #whiteout {
    background-image: url("../../img/bg/overlay.png");
    background-repeat: repeat;
    background-attachment: scroll;
    background-position: 0 0;
    background-color: transparent;
    left: 0;
    top: 0;
    z-index: 810
}

#whiteout {
    height: 100%;
    width: 100%;
    opacity: 0.7;
    overflow: hidden;
    position: fixed;
    z-index: 51
}

#fancybox-loading {
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: -22px;
    margin-left: -22px;
    background-position: 0 -108px;
    opacity: 0.8;
    cursor: pointer;
    z-index: 860
}

#fancybox-loading div {
    width: 44px;
    height: 44px;
    background-image: url("../../img/icon/modal-loading.gif");
    background-position: center center;
    background-repeat: no-repeat
}

.fancybox-overlay {
    display: none;
    overflow: hidden;
    position: absolute
}

.simplemodal-overlay {
    height: 100%;
    width: 100%;
    position: fixed
}

.fancybox-overlay-fixed {
    bottom: 0;
    position: fixed;
    right: 0
}

.fancybox-wrap, .fancybox-skin, .fancybox-outer, .fancybox-inner, .fancybox-image, .fancybox-wrap iframe, .fancybox-wrap object, .fancybox-nav, .fancybox-nav span, .fancybox-tmp {
    padding: 0;
    margin: 0;
    border: 0;
    outline: none;
    vertical-align: top;
    max-width: 100%
}

@media (min-width: 48em) {
    .fancybox-wrap, .fancybox-skin, .fancybox-outer, .fancybox-inner, .fancybox-image, .fancybox-wrap iframe, .fancybox-wrap object, .fancybox-nav, .fancybox-nav span, .fancybox-tmp {
        max-width: none
    }
}

.fancybox-wrap {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 820
}

.fancybox-skin {
    position: relative;
    background: #fff;
    padding: 0
}

@media (min-width: 48em) {
    .fancybox-skin {
        height: 100% !important
    }
}

.fancybox-opened {
    z-index: 830
}

.fancybox-outer, .fancybox-inner {
    position: relative
}

@media (min-width: 48em) {
    .fancybox-outer, .fancybox-inner {
        height: 100% !important
    }
}

.fancybox-inner {
    overflow: hidden
}

.fancybox-type-iframe, .modal-overlay-container {
    width: auto !important
}

.fancybox-type-iframe, .fancybox-type-ajax {
    display: block;
    width: 100%;
    height: 100%
}

@media (min-width: 48em) {
    .fancybox-type-iframe, .fancybox-type-ajax {
        width: 595px !important;
        height: 500px !important
    }
    .fancybox-type-iframe.medallia, .fancybox-type-ajax.medallia {
        width: 750px !important;
        height: 550px !important
    }
}

@media (min-width: 60em) {
    .fancybox-type-iframe, .fancybox-type-ajax {
        width: 738px !important;
        height: 550px !important
    }
    .fancybox-type-iframe.medallia, .fancybox-type-ajax.medallia {
        width: 750px !important;
        height: 550px !important
    }
}

.fancybox-type-iframe .fancybox-inner, .fancybox-type-ajax .fancybox-inner {
    -webkit-overflow-scrolling: touch
}

@media (min-width: 48em) {
    .fancybox-type-iframe .fancybox-inner, .fancybox-type-ajax .fancybox-inner {
        width: auto !important;
        height: 100% !important
    }
}

.fancybox-close {
    position: absolute;
    top: 10px;
    right: 20px;
    cursor: pointer;
    z-index: 840;
    background-image: url("../../img/icon/clicktip-close.gif");
    background-repeat: no-repeat;
    background-position: 0 11px;
    background-color: #fff;
    float: right;
    padding: 5px 10px 15px 15px;
    min-height: 20px;
    font-size: 12px
}

.fancybox-title.fancybox-title-float-wrap {
    display: none
}

.fancybox-tmp {
    position: absolute;
    top: -99999px;
    left: -99999px;
    visibility: hidden;
    max-width: 99999px;
    max-height: 99999px;
    overflow: visible !important
}

.modal-start, .modal-end {
    position: absolute;
    top: -999em
}

@media (min-width: 48em) {
    .fancybox-iframe {
        height: 100% !important
    }
    .simplemodal-container {
        width: 430px !important;
        position: fixed;
        top: 2em;
        left: 50%;
        margin-left: -215px;
        z-index: 820
    }
}

.modal-content .main-content {
    display: none
}

.modal-content .container {
    width: auto
}

.modal-content .thirteen, .modal-content .fourteen, .modal-content .fifteen, .modal-content .sixteen {
    width: auto !important
}

@media (min-width: 48em) {
    .modal-content .wlp-bighorn-book {
        padding: 0
    }
}

.modal-overlay-container {
    position: relative;
    margin: 10px auto;
    padding: 0;
    width: auto
}

.modal-overlay-container:after {
    display: block;
    visibility: hidden;
    clear: both;
    height: 0;
    content: " "
}

.modal-overlay-container .one, .modal-overlay-container .two, .modal-overlay-container .three, .modal-overlay-container .four, .modal-overlay-container .five, .modal-overlay-container .six, .modal-overlay-container .seven, .modal-overlay-container .eight, .modal-overlay-container .nine, .modal-overlay-container .ten, .modal-overlay-container .eleven, .modal-overlay-container .twelve, .modal-overlay-container .one-third, .modal-overlay-container .two-thirds {
    width: auto;
    display: block;
    float: none
}

@media (min-width: 48em) {
    .modal-overlay-container {
        width: 36em
    }
    .modal-overlay-container .column, .modal-overlay-container .columns {
        display: inline;
        float: left;
        margin-right: 0.625em;
        margin-left: 0.625em
    }
    .modal-overlay-container .column.alpha, .modal-overlay-container .columns.alpha {
        margin-left: 0 !important
    }
    .modal-overlay-container .column.omega, .modal-overlay-container .columns.omega {
        margin-right: 0 !important
    }
    .modal-overlay-container .one {
        width: 1.75em
    }
    .modal-overlay-container .two {
        width: 4.75em
    }
    .modal-overlay-container .three {
        width: 7.75em
    }
    .modal-overlay-container .four {
        width: 10.75em
    }
    .modal-overlay-container .five {
        width: 13.75em
    }
    .modal-overlay-container .six {
        width: 16.75em
    }
    .modal-overlay-container .seven {
        width: 19.75em
    }
    .modal-overlay-container .eight {
        width: 22.75em
    }
    .modal-overlay-container .nine {
        width: 25.75em
    }
    .modal-overlay-container .ten {
        width: 28.75em
    }
    .modal-overlay-container .eleven {
        width: 31.75em
    }
    .modal-overlay-container .twelve {
        width: 34.75em
    }
    .modal-overlay-container .one-third {
        width: 14.625em
    }
    .modal-overlay-container .two-thirds {
        width: 30.75em
    }
    .modal-overlay-container .divide-12-3-3-3-3, .modal-overlay-container .divide-12-4-8, .modal-overlay-container .divide-12-6-6, .modal-overlay-container .divide-12-8-4 {
        background-image: url(../../img/grid/single.gif)
    }
    .modal-overlay-container .divide-12-3-3-3-3 {
        background-position: 17.375em 0
    }
    .modal-overlay-container .divide-12-4-4-4 {
        background-image: url(../../img/grid/12/4-4-4.tablet.gif)
    }
    .modal-overlay-container .divide-12-4-8 {
        background-position: 11.375em 0
    }
    .modal-overlay-container .divide-12-6-6 {
        background-position: 17.375em 0
    }
    .modal-overlay-container .divide-12-8-4 {
        background-position: 23.375em 0
    }
}

@media (min-width: 60em) {
    .modal-overlay-container {
        width: 45em
    }
    .modal-overlay-container .column, .modal-overlay-container .columns {
        margin-right: 0.625em !important;
        margin-left: 0.625em !important
    }
    .modal-overlay-container .column.alpha, .modal-overlay-container .columns.alpha {
        margin-left: 0 !important
    }
    .modal-overlay-container .column.omega, .modal-overlay-container .columns.omega {
        margin-right: 0 !important
    }
    .modal-overlay-container .one {
        width: 2.75em
    }
    .modal-overlay-container .two {
        width: 6.5em
    }
    .modal-overlay-container .three {
        width: 10.25em
    }
    .modal-overlay-container .four {
        width: 14em
    }
    .modal-overlay-container .five {
        width: 17.75em
    }
    .modal-overlay-container .six {
        width: 21.5em
    }
    .modal-overlay-container .seven {
        width: 25.25em
    }
    .modal-overlay-container .eight {
        width: 29em
    }
    .modal-overlay-container .nine {
        width: 32.75em
    }
    .modal-overlay-container .ten {
        width: 36.5em
    }
    .modal-overlay-container .eleven {
        width: 40.25em
    }
    .modal-overlay-container .twelve {
        width: 44em
    }
    .modal-overlay-container .one-third {
        width: 18.75em
    }
    .modal-overlay-container .two-thirds {
        width: 38.75em
    }
    .modal-overlay-container .divide-12-3-3-3-3 {
        background-image: url(../../img/grid/12/3-3-3-3.gif);
        background-position: 0 0
    }
    .modal-overlay-container .divide-12-4-4-4 {
        background-image: url(../../img/grid/12/4-4-4.gif)
    }
    .modal-overlay-container .divide-12-4-8 {
        background-position: 14.375em 0
    }
    .modal-overlay-container .divide-12-6-6 {
        background-position: 21.875em 0
    }
    .modal-overlay-container .divide-12-8-4 {
        background-position: 29.375em 0
    }
}

.modal-overlay-content {
    padding-top: 40px
}

@media (min-width: 48em) {
    .modal-overlay-content {
        padding-top: 0
    }
}

.modal-back-bar {
    background-color: #000;
    color: #fff;
    font-weight: bold;
    height: 40px;
    width: 100%;
    position: fixed;
    top: 0;
    opacity: 0.8;
    filter: alpha(opacity=80);
    z-index: 500
}

.modal-back-bar a {
    color: #fff;
    display: block;
    line-height: 40px;
    text-align: right;
    padding-right: 20px
}

@media (min-width: 48em) {
    .modal-back-bar {
        display: none
    }
}

.fancybox-inner .modal-back-bar {
    display: none
}

.fancybox-inner .modal-overlay-content {
    padding-top: 0
}

.simplemodal-data, .zip-prompt .fancybox-inner {
    width: auto !important;
    height: 100% !important
}

.no-script-content, .zip-prompt .fancybox-skin {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    background-color: #303e4e;
    color: #fff;
    padding: 30px;
    position: relative
}

.no-script-content h1, .zip-prompt .fancybox-skin h1, .no-script-content h2, .zip-prompt .fancybox-skin h2, .no-script-content h3, .zip-prompt .fancybox-skin h3, .no-script-content h4, .zip-prompt .fancybox-skin h4, .no-script-content h5, .zip-prompt .fancybox-skin h5, .no-script-content h6, .zip-prompt .fancybox-skin h6 {
    color: #fff
}

.no-script-content a, .zip-prompt .fancybox-skin a {
    color: #00b2f7
}

.no-script-content a:hover, .zip-prompt .fancybox-skin a:hover, .no-script-content a:focus, .zip-prompt .fancybox-skin a:focus {
    text-decoration: underline
}

.zip-prompt {
    border: 0
}

.zip-prompt .fancybox-close {
    padding: 5px 0 5px 15px;
    background-color: transparent;
    background-image: url("../../img/icon/clicktip-close-white.gif");
    color: #fff !important;
    text-decoration: underline;
    font-size: 0.875em
}

.zip-prompt h2 {
    color: #fff;
    margin-bottom: 15px
}

@media (min-width: 48em) {
    .zip-prompt {
        width: 430px !important;
        border-color: #aabfd5
    }
}

.zip-prompt .zipcode-continue {
    margin-top: 3px
}

#zipcode-overlay-content {
    display: none
}

#zipcode-overlay-form label, #zipcode-overlay-form input {
    vertical-align: middle
}

#zipcode-overlay-form label {
    display: block !important;
    opacity: 1 !important
}

#zipcode-overlay-form input[type="text"] {
    font-size: 1em;
    -webkit-appearance: none;
    float: left;
    margin: 0 10px 0 0
}

.credit-heading {
    border-width: 1px;
    border-style: solid;
    text-align: center;
    padding-bottom: 4px;
    font-size: 13px
}

.credit-heading h3 {
    background-repeat: repeat-x;
    font-size: 18px;
    margin-bottom: 4px;
    padding: 5px 0
}

.excellent .credit-heading {
    border-color: #9fba8d
}

.excellent .credit-heading h3 {
    color: #1c6d40;
    background-image: url("../../img/bg/excellent-credit-bg.jpg")
}

.average .credit-heading {
    border-color: #829eb2
}

.average .credit-heading h3 {
    color: #004977;
    background-image: url("../../img/bg/average-credit-bg.jpg")
}

.rebuilding .credit-heading {
    border-color: #f3d06d
}

.rebuilding .credit-heading h3 {
    color: #975b00;
    background-image: url("../../img/bg/rebuilding-credit-bg.jpg")
}

.credit-desciption ul {
    padding: 5px 0;
    min-height: 0;
    text-align: center;
    margin: 0;
    list-style-type: none;
    font-size: 12px
}

@media (min-width: 48em) {
    .credit-desciption ul {
        min-height: 235px
    }
}

@media (min-width: 60em) {
    .credit-desciption ul {
        text-align: left;
        padding: 10px 10px 5px;
        min-height: 235px;
        background-repeat: no-repeat;
        background-position: top center
    }
}

.credit-desciption ul li span {
    display: block;
    padding-top: 4px;
    text-align: center
}

.credit-desciption ul br {
    display: block
}

@media (min-width: 48em) {
    .credit-desciption ul br {
        display: inline
    }
}

.credit-desciption .credit-cta {
    display: block;
    padding-top: 2px;
    text-align: center;
    margin-bottom: 10px
}

@media (min-width: 48em) {
    .credit-desciption .credit-cta {
        margin-bottom: 0
    }
}

#orientation-overlay {
    background-color: rgba(0, 0, 0, 0.85);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 600;
    text-align: center;
    color: #fff;
    font-weight: bold;
    font-size: 1.3em;
    padding-top: 45%
}

#orientation-overlay button {
    border: 0;
    background: none;
    color: #fff;
    font-weight: bold;
    font-size: 1em;
    cursor: pointer
}

#orientation-overlay .icon-rotate {
    display: inline-block;
    width: 120px;
    height: 109px;
    background-image: url("../../img/icon/rotate-screen.png");
    background-repeat: no-repeat
}

#orientation-overlay .icon-close:before {
    content: "\f00d";
    font-family: "icomoon";
    font-weight: normal;
    font-style: normal;
    text-decoration: inherit;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.rrd-ada-disclosure {
    color: #262626;
    background-color: #fff;
    position: relative;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    line-height: 18px
}

.rrd-ada-disclosure h2, .rrd-ada-disclosure h3, .rrd-ada-disclosure h4, .rrd-ada-disclosure h5 {
    font-family: Arial, Helvetica, sans-serif !important
}

.rrd-ada-disclosure p {
    margin: 0 0 1em
}

.rrd-ada-disclosure ol, .rrd-ada-disclosure ul {
    padding: 0;
    margin: 0 0 1em 1.3em
}

@media (min-width: 48em) {
    .rrd-ada-disclosure ol, .rrd-ada-disclosure ul {
        margin: 0 0 1em 15px
    }
}

@media (min-width: 60em) {
    .rrd-ada-disclosure ol, .rrd-ada-disclosure ul {
        margin: 0 0 1em 30px
    }
}

.rrd-ada-disclosure h3 {
    font-size: 1.2em
}

.rrd-ada-disclosure h4 {
    font-size: 1.1em;
    margin-bottom: 5px
}

.rrd-ada-disclosure h5 {
    font-size: 1em;
    font-weight: bold;
    text-decoration: underline;
    margin-bottom: 5px
}

.rrd-ada-disclosure .offscreen {
    position: absolute;
    top: -999em
}

.rrd-ada-disclosure .rate {
    font-size: 22px !important;
    font-weight: bold !important;
    color: #262626
}

.rrd-ada-disclosure .underline {
    text-decoration: underline
}

.rrd-ada-disclosure .indent {
    margin-left: 20px;
    text-indent: -16px
}

.rrd-ada-disclosure table {
    width: 100%;
    border: 0;
    border-right: 1px solid #ddd;
    border-top: 1px solid #ddd;
    border-collapse: collapse
}

.rrd-ada-disclosure table th, .rrd-ada-disclosure table td {
    padding: 10px;
    margin: 0;
    text-align: left !important;
    vertical-align: top;
    border-left: 1px solid #ddd;
    border-bottom: 1px solid #ddd
}

.rrd-ada-disclosure table ol, .rrd-ada-disclosure table ul {
    margin: 0 0 1em 10px
}

@media (min-width: 48em) {
    .rrd-ada-disclosure table ol, .rrd-ada-disclosure table ul {
        margin: 0 0 1em 15px
    }
}

@media (min-width: 60em) {
    .rrd-ada-disclosure table ol, .rrd-ada-disclosure table ul {
        margin: 0 0 1em 30px
    }
}

.rrd-ada-disclosure table p {
    margin: 0 0 0.5em
}

.rrd-ada-disclosure table.schumer td.description {
    border-bottom: 1px solid #ddd;
    vertical-align: baseline;
    line-height: 28px;
    padding: 9px
}

.rrd-ada-disclosure table.schumer td.description b {
    word-wrap: break-word !important
}

.rrd-ada-disclosure table.schumer td.description b span {
    display: inline-block
}

.rrd-ada-disclosure table.schumer td.hasRate {
    padding-left: 25px;
    text-indent: -16px
}

.rrd-ada-disclosure table.schumer td li {
    text-indent: 0 !important
}

.rrd-ada-disclosure table.feessubtable {
    border: 0 !important
}

.rrd-ada-disclosure table caption, .rrd-ada-disclosure .schumertableheader {
    background-color: #000;
    color: #fff;
    font-weight: bold;
    text-align: left;
    padding: 5px 10px;
    display: block;
    display: table-caption
}

.rrd-ada-disclosure table th, .rrd-ada-disclosure .term {
    width: 30%;
    font-weight: bold;
    background-color: #f6f6f6
}

@media (min-width: 48em) {
    .rrd-ada-disclosure table th, .rrd-ada-disclosure .term {
        width: 22%
    }
}

.rrd-ada-disclosure .faqs {
    margin: 1em 0 0
}

.rrd-ada-disclosure .faq {
    margin: 0 !important;
    padding-left: 0
}

.rrd-ada-disclosure .faq .question {
    font-weight: bold
}

.rrd-ada-disclosure .section {
    display: block;
    background-color: #f6f6f6;
    border: 1px solid #ddd;
    text-align: center;
    padding: 5px;
    font-size: 1em
}

.rrd-ada-disclosure hr {
    background-color: #e5ebf0;
    border: medium none;
    clear: both;
    color: #e5ebf0;
    height: 1px;
    margin: 1.2em 0
}

.rrd-ada-disclosure th.termnoborder {
    width: 30%;
    border: 0;
    border-right: 1px solid #ddd;
    border-left: 1px solid #ddd;
    background-color: #f6f6f6;
    vertical-align: top;
    padding: 9px;
    font-weight: bold
}

@media (min-width: 48em) {
    .rrd-ada-disclosure th.termnoborder {
        width: 22%
    }
}

.rrd-ada-disclosure td.descnoborder {
    border: 0;
    vertical-align: top;
    padding: 9px;
    border-collapse: collapse
}

.rrd-ada-disclosure td.feessubtable {
    padding: 0 !important;
    border: 0;
    border-left: 1px;
    border-bottom: 0 !important
}

.rrd-ada-disclosure li {
    background-image: none !important
}

.cluetip-tooltip-rounded .zip label {
    position: absolute;
    line-height: 45px;
    padding-left: 10px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5)
}

.cluetip-tooltip-rounded .zip.on label {
    left: -999em;
    overflow: hidden
}

.cluetip-tooltip-rounded .zip input[type="text"] {
    width: 47%;
    padding: 14px 10px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5);
    vertical-align: middle;
    display: inline-block;
    margin-right: 10px
}

.cluetip-tooltip-rounded .zip .c1-button {
    vertical-align: middle;
    margin-bottom: 0
}

.cluetip-tooltip-rounded h2 {
    padding-bottom: 5px
}